import {
  IVTable,
  IVTableBody,
  IVTableHead,
  IVTr,
  IVTd,
} from "./groupDetailsTabTable";

export default function GroupDetailReviewTab() {
  return (
    <div className="tab-pane active current-tab" id="current">
      <IVTable>
        <IVTableHead>
          <IVTr>
            <th scope="col">Due</th>
            <th scope="col">Title</th>
            <th scope="col">Author</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
            <th scope="col" className="text-center">
              Critiques
            </th>
            <th scope="col" className="text-center">
              More
            </th>
            <th scope="col" className="text-center"></th>
          </IVTr>
        </IVTableHead>
        <IVTableBody>
          <IVTr>
            <IVTd>
              20/Aug 23:59 (EDT)
              <br />
              <a role="button" data-target="#myModal5" data-toggle="modal">
                Edit
              </a>
            </IVTd>

            <IVTd>
              <a
                className="cutoff"
                href="
                              
                                  /critique/all_for/5/Submission/
                              "
              >
                Title 10
              </a>
            </IVTd>

            <IVTd>
              <a href="http://localhost:3000/profile/view/1">Me</a>
            </IVTd>

            <IVTd>
              <a
                className="btn btn-default submission cutoff green"
                href="
                              
                                  /critique/all_for/5/Submission/
                              "
                role="button"
              >
                Read Critiques
              </a>
            </IVTd>

            <IVTd>
              <div className=" empty-icon "></div>
            </IVTd>

            <IVTd className="text-center">3</IVTd>

            <IVTd className="text-center">
              <a
                href="/group/rollback_submission/5/"
                data-target="#"
                data-toggle="modal"
              >
                Remove
              </a>
            </IVTd>

            <IVTd className="text-center">
              <a
                href="/group/move_submission_to_review_tab/5/"
                data-target="#"
                data-toggle="modal"
              >
                Move to Review
              </a>
            </IVTd>
          </IVTr>

          <IVTr>
            <IVTd>
              12/Aug 23:59 (EDT)
              <br />
              <a role="button" data-target="#myModal2" data-toggle="modal">
                Edit
              </a>
            </IVTd>

            <IVTd>
              <a
                className="cutoff"
                href="
                              
                                  /critique/all_for/2/Submission/
                              "
              >
                Second thingy
              </a>
            </IVTd>

            <IVTd>
              <a href="http://localhost:3000/profile/view/1">Me</a>
            </IVTd>

            <IVTd>
              <a
                className="btn-default submission cutoff"
                href="
                              
                                  /critique/all_for/2/Submission/
                              "
                role="button"
              >
                Submitted
              </a>
            </IVTd>

            <IVTd>
              <div className=" critique-icon "></div>
            </IVTd>

            <IVTd className="text-center">0</IVTd>

            <IVTd className="text-center">
              <a
                href="/group/rollback_submission/2/"
                data-target="#"
                data-toggle="modal"
              >
                Remove
              </a>
            </IVTd>

            <IVTd className="text-center">
              <a
                href="/group/move_submission_to_review_tab/2/"
                data-target="#"
                data-toggle="modal"
              >
                Move to Review
              </a>
            </IVTd>
          </IVTr>

          <IVTr>
            <IVTd>
              25/Jul 23:59 (EDT)
              <br />
              <a role="button" data-target="#myModal1" data-toggle="modal">
                Edit
              </a>
            </IVTd>

            <IVTd>
              <a
                className="cutoff"
                href="
                              
                                  /critique/all_for/1/Submission/
                              "
              >
                Silver Wings 10.6.23
              </a>
            </IVTd>

            <IVTd>
              <a href="http://localhost:3000/profile/view/1">Me</a>
            </IVTd>

            <IVTd>
              <a
                className="btn btn-default submission cutoff green"
                href="
                              
                                  /critique/all_for/1/Submission/
                              "
                role="button"
              >
                Read Critiques
              </a>
            </IVTd>

            <IVTd>
              <div className=" empty-icon "></div>
            </IVTd>

            <IVTd className="text-center">8</IVTd>

            <IVTd className="text-center">
              <a
                href="/group/rollback_submission/1/"
                data-target="#"
                data-toggle="modal"
              >
                Remove
              </a>
            </IVTd>

            <IVTd className="text-center">
              <a
                href="/group/move_submission_to_review_tab/1/"
                data-target="#"
                data-toggle="modal"
              >
                Move to Review
              </a>
            </IVTd>
          </IVTr>

          <IVTr>
            <IVTd className="purple-row"></IVTd>
            <IVTd className="purple-row"></IVTd>
            <IVTd className="purple-row"></IVTd>
            <IVTd className="purple-row"></IVTd>
            <IVTd className="purple-row"></IVTd>
            <IVTd className="purple-row"></IVTd>
            <IVTd className="purple-row"></IVTd>
            <IVTd className="purple-row"></IVTd>
          </IVTr>

          <IVTr>
            <IVTd>
              29/Aug 23:59 (EDT)
              <br />
              <a
                href="#myModal6"
                role="button"
                data-target="#myModal6"
                data-toggle="modal"
              >
                Edit
              </a>
            </IVTd>

            <IVTd>
              <a className="cutoff" href="/critique/edit/6/">
                lorem
              </a>
            </IVTd>

            <IVTd>
              <a href="http://localhost:3000/profile/view/2">
                rebecchialejandro
              </a>
            </IVTd>

            <IVTd>
              <a className="cutoff" href="/critique/edit/6/">
                <span className="submission btn blue">Give Critique</span>
              </a>
            </IVTd>

            <IVTd>
              <div className="empty-icon"></div>
            </IVTd>

            <IVTd className="text-center">1</IVTd>

            <IVTd className="text-center">
              <button
                type="button"
                className="btn btn-primary btn-lg"
                data-toggle="modal"
                data-target="#editQuestion6"
              >
                Edit
              </button>
              <div
                className="modal fade"
                id="editQuestion6"
                role="dialog"
                aria-labelledby="myModalLabel"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <h4 className="modal-title" id="myModalLabel">
                        Reminder: Re-Submit After Editing
                      </h4>
                    </div>
                    <div className="modal-body">
                      <p>
                        Opening your critique to edit returns it to an
                        in-process state. You must re-submit, even if you do not
                        make changes.
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <a
                        href="/critique/reopen/6/"
                        type="button"
                        className="btn btn-primary"
                      >
                        Edit critique
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </IVTd>
            <IVTd></IVTd>
          </IVTr>
        </IVTableBody>
      </IVTable>
    </div>
  );
}
