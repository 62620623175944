import React from 'react';
import {
    Col,
    Container,
    Row,
  } from "react-bootstrap";

import './Resources.scss';


const PictureBooksCritiqueGuide = () => {
    return (
        <Container className="container about-view">
            <Row>
                <Col md={{span: 9, offset: 1}}>
                    <Row>
                    <h1>Inked Voices Picture Books Critique Guide</h1>
                        <p>
                            Consider the following when noting a manuscript's strengths and what could be stronger or
                            further developed. These questions are intended to spark ideas and conversation; use what
                            feels relevant to your experience with the manuscript at hand.
                        </p>
                        <p>
                        <h3>Theme / Heart</h3>
                        <ul className="writing-list">
                            <li> Was it clear what this story was ultimately about? (e.g. self-confidence, acceptance,
                                grief, persistence, friendship)
                            </li>
                            <li> Did the story remain focused on a single story problem from beginning to end?
                            </li>
                        </ul>
                        </p>
                        <p>
                        <h3>Character development</h3>
                        <ul className="writing-list">
                            <li>Is the main character distinctive with a unique personality?
                            </li>
                            <li>Does the character change over the course of the book? If not, does that choice work?
                            </li>
                            <li>Does the character show agency and/or solve their own problem? If not, does that choice
                                work?
                            </li>
                        </ul>
                        </p>
                        <p>
                        <h3>Plot</h3>
                        <ul className="writing-list">
                            <li>What is the external plot (Action)? What is the internal plot (Emotion)? Are both plots
                                well developed over the course of the story?
                            </li>
                            <li>Do the opening lines hook the reader?
                            </li>
                            <li>Does the opener set up the character's normal world/status quo, and establish the story
                                problem?
                            </li>
                            <li>Is there a clear inciting incident, climax, and resolution?
                            </li>
                            <li>Is the ending satisfying?</li>
                        </ul>
                        </p>
                        <p>
                        <h3>Pacing</h3>
                        <ul className="writing-list">
                            <li>Do any areas feel too slow or too fast?
                            </li>
                            <li>Is action rising from the story's beginning through its climax? (e.g. by creating
                                anticipation or by escalating tension)
                            </li>
                        </ul>
                        </p>
                        <p>
                        <h3>Structure</h3>
                        <ul className="writing-list">
                            <li>Is the POV choice working (why or why not) and is it consistent?
                            </li>
                            <li>Does the balance of dialogue, narration, description and action work for the story?
                            </li>
                            <li>Is there something interesting to illustrate over multiple spreads (12-16)?</li>
                        </ul>
                        </p>
                        <p>
                        <h3>Voice and Language</h3>
                        <ul className="writing-list">
                            <li>Does the voice feel distinct and specific?
                            </li>
                            <li>Do the language choices build character, voice, and story mood? Could the language be
                                amped up? Is the language child-friendly?
                            </li>
                            <li>Is the writing sharp at the sentence level?</li>
                        </ul>
                        </p>
                        <p>
                        <h3>Concept / Marketability</h3>
                        <ul className="writing-list">
                            <li>Does the manuscript feel fresh compared to picture books on similar topics?
                            </li>
                            <li>Do comparable titles spring to mind?
                            </li>
                            <li>What sales hooks do you see for parents, librarians, and teachers?
                            </li>
                        </ul>
                        </p>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default PictureBooksCritiqueGuide;
