import { useContext, useEffect, useState } from "react";
import {
  IVTable,
  IVTableBody,
  IVTableHead,
  IVTr,
  IVTd,
  IVTableSeparator,
} from "./groupDetailsTabTable";
import sendRequest from "services/dataService";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useSelector } from "react-redux";
import { CurrentUserState } from "types";
import { displayShortDate, displayShortTime } from "pages/events/utils";

const SubmissionCritiqueStatus = ({ submission }: { submission: any }) => {
  const status = submission.status_for_user;
  if (status === "Read Critiques") {
    return (
      <a
        className="btn btn-default submission cutoff green"
        href={`/critique/all_for/${submission.id}/Submission/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Give Critique") {
    return (
      <a
        className="submission btn blue"
        href={`/critique/edit/${submission.id}/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Finish Critique") {
    return (
      <a
        className="submission btn purple"
        href={`/critique/edit/${submission.id}/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Submitted") {
    return (
      <a
        className="btn-default submission cutoff"
        href={`/critique/all_for/${submission.id}/Submission/`}
        role="button"
      >
        {status}
      </a>
    );
  }

  return <>{submission.status_for_user}</>;
};

type SubmissionInCritique = {
  id: number;
  title: string;
  due_date: string;
  finished_critiques_count: number;
  status_for_user: string;
  can_be_removed: boolean;
  author: {
    id: number;
    pen_name: string;
  };
};

const SubmissionInCritiqueTab = ({
  submission,
}: {
  submission: SubmissionInCritique;
}) => {
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  return (
    <IVTr>
      <IVTd>
        {displayShortDate(submission.due_date)}{" "}
        {displayShortTime(submission.due_date)}
        <br />
        <a role="button" data-target="#myModal5" data-toggle="modal">
          Edit
        </a>
      </IVTd>

      <IVTd>
        <a
          className="cutoff"
          href={`/critique/all_for/${submission.id}/Submission/`}
        >
          {submission.title}
        </a>
      </IVTd>

      <IVTd>
        <a href={`http://localhost:3000/profile/view/${submission.author.id}`}>
          {userState.data.id === submission.author.id
            ? "Me"
            : submission.author.pen_name}
        </a>
      </IVTd>

      <IVTd>
        <SubmissionCritiqueStatus submission={submission} />
      </IVTd>

      <IVTd>
        <div className=" empty-icon "></div>
      </IVTd>

      <IVTd className="text-center">{submission.finished_critiques_count}</IVTd>

      <IVTd className="text-center">
        {submission.can_be_removed && (
          <a
            href={`/group/rollback_submission/${submission.id}/`}
            data-target="#"
            data-toggle="modal"
          >
            Remove
          </a>
        )}
      </IVTd>

      <IVTd className="text-center">
        {userState.data.id === submission.author.id && (
          <a
            href={`/group/move_submission_to_review_tab/5/`}
            data-target="#"
            data-toggle="modal"
          >
            Move to Review
          </a>
        )}
      </IVTd>
    </IVTr>
  );
};

export default function GroupDetailCritiqueTab({
  groupId,
}: {
  groupId: number;
}) {
  const [submissions, setSubmissions] = useState<SubmissionInCritique[] | null>(
    null
  );
  const authenticationContext = useContext(AuthenticationContext);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  const fetchSubmissions = () => {
    const url = `api/v1.0/group/${groupId}/submissions-to-critique/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setSubmissions(response);
    });
  };

  useEffect(() => {
    fetchSubmissions();
  }, [groupId]);

  return (
    <div className="tab-pane active current-tab" id="current">
      <IVTable>
        <IVTableHead>
          <IVTr>
            <th scope="col">Due</th>
            <th scope="col">Title</th>
            <th scope="col">Author</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
            <th scope="col" className="text-center">
              Critiques
            </th>
            <th scope="col" className="text-center">
              More
            </th>
            <th scope="col" className="text-center"></th>
          </IVTr>
        </IVTableHead>
        <IVTableBody>
          {submissions
            ?.filter((submission) => submission.author.id === userState.data.id)
            ?.map((submission) => (
              <SubmissionInCritiqueTab
                key={submission.id}
                submission={submission}
              />
            ))}

          <IVTableSeparator cols={8} />

          {submissions
            ?.filter((submission) => submission.author.id != userState.data.id)
            ?.map((submission) => (
              <SubmissionInCritiqueTab
                key={submission.id}
                submission={submission}
              />
            ))}
        </IVTableBody>
      </IVTable>
    </div>
  );
}
