import { isResourceEvent } from "pages/events/utils";
import { Col } from "react-bootstrap";
import EventDetailImage from "../image/EventDetailImage";

const EventDetailFacilitatorBio = (props: any) => {
    const event = props.event;

    const convertHTML = (text: any) => {
        const htmlRegex = /<[^>]+>/g;
        if (!htmlRegex.test(text)) {
            return text.replace('\n', '<br>').replace('\r', '<br>')
        }
        return text 
    }

    if (isResourceEvent(event)) {
        return <>
            <Col sm={3}>
                <EventDetailImage event={event} />
            </Col>
            <Col sm={9}>
                {event?.facilitator_bio && event?.facilitator_name && <>
                    <h4 className="facilitator">About {event?.facilitator_name}</h4>
                    <p dangerouslySetInnerHTML={{ __html: convertHTML(event?.facilitator_bio) }} />

                </>}
            </Col>
        </>
    } else {
        return event?.facilitator_bio && event?.facilitator_name ? <>
            <h4 className="facilitator">About {event?.facilitator_name}</h4>
            <p dangerouslySetInnerHTML={{ __html: convertHTML(event?.facilitator_bio) }} />
        </> : <></>
    }
};

export default EventDetailFacilitatorBio;