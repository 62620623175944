import sendRequest from "services/dataService";


export const loadUserProfileData = async (token: string) => {
  const response = await sendRequest({
    url: "profile/api/edit",
    method: "GET",
    token: token,
    body: null,
  });
  return response;
};
