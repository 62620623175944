import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loadDashboard } from "redux/dashboardReducer";
import sendRequest from "services/dataService";
import { BaseNote } from "types";

type NoteDeleteModalProps = {
  note: BaseNote;
  show: Boolean;
  onHide: () => void;
}


const NoteDeleteModal = (props: NoteDeleteModalProps) => {
    const {note, show, onHide} = props;
    const authenticationContext = useContext(AuthenticationContext);
    const dispatch = useDispatch();

    const onSubmit = async (e: any) => {
      e.preventDefault();
      try {
        await sendRequest({
          url: `api/v1.0/note/${note.id}/`,
          method: "DELETE",
          token: authenticationContext.token || "",
          body: null,
        });
        onHide();
        dispatch(loadDashboard(authenticationContext.token || ""));
        toast.success("Note deleted successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } catch (error: any) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    };

    return (
      <Modal show={show} centered onHide={onHide} >
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete <b>{note.title}</b>?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="primary purple"  onClick={onSubmit}>Delete</Button>
          <Button variant="secondary" className="secondary grey" onClick={onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
}

export default NoteDeleteModal;