import React, { useEffect, useContext } from "react";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useSelector } from "react-redux";

import Loading from "components/loading/Loading";
import ProfileForm from "./components/EditProfileForm";
import { loadUserProfileData } from "services/userProfileService";
import { useUserProfile } from "global-state/userProfile";


const EditProfile = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const user = useSelector((state: any) => state.user);
  const [userProfile, setUserProfile] = useUserProfile();

  async function fetchUserProfileData() {
    if (authenticationContext.token){
      const response = await loadUserProfileData(authenticationContext.token);
      setUserProfile({
        started: true,
        completed: true,
        profile: response,
      });
    }
  }

  useEffect(() => {
    fetchUserProfileData();
  }, [authenticationContext]);

  return (userProfile.profile) ? <ProfileForm profileData={userProfile.profile} /> : <Loading></Loading>
};

export default EditProfile;
