import config from "config";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import sendRequest from "services/dataService";
import { BaseUser, CurrentUserState } from "types";
import "../edit/EditCritique.scss";
import "./AllCritiques.scss";
import { displayDateTime, getTimezone } from "pages/events/utils";
import {
  AnnotatedContent,
  SubmissionResponse,
  CritiqueResponse,
} from "../commons";
import DropdownMultiselect from "components/dropdown-multiselect/DropdownMultiselect";

const CritiqueCard = ({ critique }: { critique: CritiqueResponse }) => {
  return (
    <div className="critique-card">
      {critique.author?.image && (
        <img
          className="critique-card-image"
          src={critique.author.image}
          alt={critique.author.pen_name}
        />
      )}
      <h3>
        <Link to={`/profile/view/${critique.author.id}`}>
          {critique.author.pen_name}
        </Link>
      </h3>
      <span className="date">{displayDateTime(critique.finished_at)}</span>
      <p dangerouslySetInnerHTML={{ __html: critique.contents }} />
    </div>
  );
};

export default function AllCritiques() {
  const authenticationContext = useContext(AuthenticationContext);
  const { id } = useParams() as any;
  const [submission, setSubmission] = useState<SubmissionResponse | undefined>(
    undefined
  );
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [critiques, setCritiques] = useState<CritiqueResponse[] | undefined>(
    undefined
  );
  const [allUsers, setAllUsers] = useState<BaseUser[] | undefined>(undefined);
  const [selectedEditors, setSelectedEditors] = useState<
    BaseUser[] | undefined
  >(undefined);
  const selectedCritiques = critiques?.filter((c) =>
    selectedEditors?.includes(c.author)
  );

  async function loadSubmission() {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/`;
    const response: SubmissionResponse | undefined = await sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setSubmission(response);
  }

  async function loadCritiques() {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/critique/all/`;
    const response: CritiqueResponse[] = await sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setCritiques(response);
    const users = response.map((critique) => critique.author);
    setAllUsers(users);
    setSelectedEditors(users);
  }

  useEffect(() => {
    if (authenticationContext.token && id) {
      loadSubmission();
      loadCritiques();
    }
  }, [id, authenticationContext]);

  return (
    <>
      <Container style={{ marginBottom: 0 }}>
        {/* <Row>
          <Col xs={12} lg={{ span: 10, offset: 1 }}>
            <a
              href={`${config.BASE_API_URL}/group/${submission?.group.id}/test-group/`}
              id="returntogroup"
            >
              {" "}
              Return to {submission?.group.name}
            </a>
          </Col>
        </Row> */}

        <Row>
          <Col
            xs={12}
            className="justify-between items-start border-b pb-2 pt-4"
          >
            <div>
              <h4>{submission?.document.title}</h4>
              <h5 className="text-gray-500">
                {submission?.document.author.pen_name}
              </h5>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-500">
                Due {submission && displayDateTime(submission.due_date)} (
                {getTimezone()})
              </p>
              <DropdownMultiselect
                options={allUsers?.map((u) => {
                  return { key: u.id, label: u.pen_name };
                })}
                selected={selectedEditors?.map((u) => u.id) || []}
                onChange={(values) => {
                  setSelectedEditors(
                    allUsers?.filter((u) => values.includes(u.id))
                  );
                }}
              />
            </div>
          </Col>
        </Row>
        <AnnotatedContent
          submissionId={id}
          content={submission?.document.contents}
          userData={userState.data}
          notes={submission?.notes}
          all={true}
          users={selectedEditors}
          canDelete={false}
        />
        <Row>
          <Col
            xs={12}
            className="justify-between items-start border-t pb-2 pt-4"
          >
            <div>
              <h2>Overall Feedback</h2>
              {selectedCritiques?.map((critique) => (
                <CritiqueCard key={critique.id} critique={critique} />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
