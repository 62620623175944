import config from "config";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import sendRequest from "services/dataService";
import { CritiqueStatus, CurrentUserState } from "types";
import "./EditCritique.scss";
import { displayDateTime, getTimezone } from "pages/events/utils";
import ReactQuill, { Quill } from "react-quill";
import "quill-emoji/dist/quill-emoji.css";
import * as Emoji from "quill-emoji";
import { countWords } from "utils";
import { toast } from "react-toastify";
import { AnnotatedContent, SubmissionResponse, Critique } from "../commons";

Quill.register("modules/emoji", Emoji);

export default function EditCritique() {
  const authenticationContext = useContext(AuthenticationContext);
  const { id } = useParams() as any;
  const [submission, setSubmission] = useState<SubmissionResponse | undefined>(
    undefined
  );
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [critique, setCritique] = useState<Critique>({
    contents: "",
    submission: id,
    status: CritiqueStatus.DRAFT,
  });
  const [isSplitView, setIsSplitView] = useState(false);

  async function loadSubmission() {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/`;
    const response: SubmissionResponse | undefined = await sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setSubmission(response);
  }

  async function loadCritique() {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/critique/`;
    const response: Critique = await sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setCritique(response);
  }

  const submitCritique = async () => {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/critique/`;
    const response: Critique = await sendRequest({
      url,
      method: "POST",
      token: authenticationContext.token || "",
      body: JSON.stringify({
        ...critique,
        status: CritiqueStatus.FINISHED_APPROVED,
      }),
    });
    toast.success("Critique submitted successfully.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    document.location.replace(
      config.BASE_API_URL +
        (submission?.group.url.substring(1) || "") +
        "?critiqued=true"
    );
  };

  const saveCritique = async () => {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/critique/`;
    const response: Critique = await sendRequest({
      url,
      method: "POST",
      token: authenticationContext.token || "",
      body: JSON.stringify(critique),
    });
    toast.success("Critique saved successfully.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  useEffect(() => {
    if (authenticationContext.token && id) {
      loadSubmission();
      loadCritique();
    }
  }, [id, authenticationContext]);

  return (
    <>
      <Container style={{ marginBottom: isSplitView ? 186 : 0 }}>
        {/* <Row>
          <Col xs={12} lg={{ span: 10, offset: 1 }}>
            <a
              href={`${config.BASE_API_URL}/group/${submission?.group.id}/test-group/`}
              id="returntogroup"
            >
              {" "}
              Return to {submission?.group.name}
            </a>
          </Col>
        </Row> */}

        <Row>
          <Col
            xs={12}
            className="justify-between items-start border-b pb-2 mb-4 pt-4"
          >
            <div>
              <h4>{submission?.document.title}</h4>
              <h5 className="text-gray-500">
                {submission?.document.author.pen_name}
              </h5>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-500">
                Due {submission && displayDateTime(submission.due_date)} (
                {getTimezone()})
              </p>
              <Link
                to={`/critique/all_for/${id}/Submission/`}
                className="text-blue-600 text-sm"
                rel="ugc"
              >
                View all critiques
              </Link>
            </div>
          </Col>
        </Row>
        <AnnotatedContent
          submissionId={id}
          content={submission?.document.contents}
          userData={userState.data}
          notes={submission?.notes}
          users={[userState.data]}
        />
      </Container>
      <div
        style={{
          background: "#efefef",
          position: isSplitView ? "fixed" : "relative",
          bottom: 0,
        }}
      >
        <Container className="critique-container w-full pt-4 pb-6 px-0">
          <Row>
            <Col xs={12} md={8}>
              <h2>Overall Feedback</h2>
            </Col>
            <Col xs={12} md={3} className="text-right">
              <img
                src={`${config.BASE_API_URL}static/img/screen-${
                  isSplitView ? "join" : "split"
                }.png`}
                width={45}
                height={45}
                className="rounded cursor-pointer"
                onClick={() => setIsSplitView(!isSplitView)}
              />
            </Col>
            <Col xs={12} md={2}></Col>
          </Row>
          <Row className="pt-2">
            <Col xs={12} md={11} className="pr-0">
              <ReactQuill
                placeholder="Leave a review."
                theme="snow"
                value={critique.contents}
                onChange={(val) => setCritique({ ...critique, contents: val })}
                modules={{
                  toolbar: [["emoji"]],
                  "emoji-toolbar": true,
                  "emoji-shortname": true,
                }}
              />
            </Col>
            <Col></Col>
          </Row>
          {submission?.group.min_critique_words && (
            <Row>
              <Col xs={12} md={11}>
                <div className="text-right text-sm pt-2">
                  {countWords(critique.contents)} words (min.{" "}
                  {submission?.group.min_critique_words})
                </div>
              </Col>
            </Row>
          )}
          <Row className="pt-2">
            <Col xs={12} md={5}>
              <Button
                className="purple mr-2 ph-4 pv-2"
                onClick={() => submitCritique()}
                disabled={Boolean(
                  submission?.group.min_critique_words &&
                    countWords(critique.contents) <
                      submission?.group.min_critique_words
                )}
              >
                Submit critique
              </Button>
              <Button
                className="transparent ml-2"
                onClick={() => saveCritique()}
              >
                Save and finish later
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <p className="text-xs text-muted-foreground">
                All comments save automatically. Submitting makes your comments
                visible to the group. You may edit your critique once submitted.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
