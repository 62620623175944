import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loaduser } from "redux/userReducer";
import sendRequest from "services/dataService";
import { BaseGroup } from "types";

type LeaveGroupModalProps = {
  group: BaseGroup;
  show: Boolean;
  onHide: () => void;
};

const LeaveGroupModal = ({ group, show, onHide }: LeaveGroupModalProps) => {
  const authenticationContext = useContext(AuthenticationContext);
  const dispatch = useDispatch();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await sendRequest({
        url: `api/v1.0/group/${group.id}/membership/`,
        method: "DELETE",
        token: authenticationContext.token || "",
        body: null,
      });
      onHide();
      dispatch(loaduser(authenticationContext.token));
      toast.success("You have left the group successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Confirm Leaving Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to leave <b>{group.name}</b>?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="primary purple" onClick={onSubmit}>
          Leave group
        </Button>
        <Button variant="secondary" className="secondary grey" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeaveGroupModal;
