import React, { useContext, useEffect, useState } from "react";
import { Row, ListGroup, Tabs, Tab } from "react-bootstrap";

import CurrentTab from "../components/CurrentTab";
import ArchiveTab from "../components/ArchiveTab";
import "./Projects.scss";
import DocumentCreateModal from "../components/modals/DocumentCreate";
import ProjectModal from "../components/modals/ProjectCreateAndRename";
import {
  CurrentProjectsType,
  useCurrentProjects,
  useQuickSubmissions,
} from "global-state/projects";
import AuthenticationContext from "contexts/AuthenticationContext";
import { loadPaginatedData } from "utils";
import sendRequest from "services/dataService";
import { loadDashboard } from "redux/dashboardReducer";
import { useDispatch } from "react-redux";

type ProjectsComponentProps = {
  last_modified_project?: number | null;
  loading?: boolean;
};

const Projects = ({
  loading,
  last_modified_project,
}: ProjectsComponentProps) => {
  const authenticationContext = useContext(AuthenticationContext);
  const [showNewDocModal, setShowNewDocModal] = useState(false);
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [, setCurrentProjectsData] = useCurrentProjects();
  const [, setQuickSubmissionsData] = useQuickSubmissions();
  const dispatch = useDispatch();

  const loadCurrentProjectsData = async () => {
    const token = authenticationContext.token || "";
    const url: string = `api/v1.0/project/current-projects/`;
    loadPaginatedData(url, token, (response: any, first: Boolean) => {
      setCurrentProjectsData((prev: CurrentProjectsType) => ({
        started: true,
        completed: !Boolean(response.next),
        projects: [...(first ? [] : prev.projects), ...response.results],
      }));
    });
  };

  const loadQuickSubmissionsData = async () => {
    const token = authenticationContext.token || "";
    const url: string = `api/v1.0/project/quick-submissions/`;
    const response = await sendRequest({
      url,
      method: "GET",
      token: token,
      body: null,
    });

    setQuickSubmissionsData({
      started: true,
      completed: true,
      project: response,
    });
  };

  const loadProjectsInfo = () => {
    loadCurrentProjectsData();
    loadQuickSubmissionsData();
    dispatch(loadDashboard(authenticationContext.token || ""));
  };

  useEffect(() => {
    loadProjectsInfo();
  }, []);

  return (
    <>
      <h2 id="projects_title">Projects</h2>
      <Row className="pull-right btn-group">
        <ListGroup className="list-unstyled project-actions">
          <ListGroup.Item id="add-document-button" className="btn btn-primary">
            <a
              className="action-button"
              href="#"
              onClick={(e) => {
                setShowNewDocModal(true);
              }}
            >
              New Document
            </a>
          </ListGroup.Item>
          <DocumentCreateModal
            show={showNewDocModal}
            onHide={() => {
              setShowNewDocModal(false);
            }}
            onChange={loadProjectsInfo}
          />
          <ListGroup.Item className="btn">
            <a
              id="add-project-button"
              className="action-button"
              href="#"
              onClick={() => {
                setShowNewProjectModal(true);
              }}
            >
              New Project
            </a>
          </ListGroup.Item>
          <ProjectModal
            show={showNewProjectModal}
            onHide={() => {
              setShowNewProjectModal(false);
            }}
            onChange={loadProjectsInfo}
          />
        </ListGroup>
      </Row>
      <br></br>
      <br></br>
      <br></br>

      <Tabs
        className="nav nav-tabs"
        defaultActiveKey="current"
        id="projects-tab"
      >
        <Tab title="Current" tabClassName="tab-purple" eventKey="current">
          <CurrentTab
            lastModified={last_modified_project?.toString()}
            onChange={loadProjectsInfo}
          />
        </Tab>
        <Tab
          id="archive-tab"
          title="Archive"
          tabClassName="tab-purple"
          eventKey="archive"
        >
          <ArchiveTab
            lastModified={last_modified_project}
            onChange={loadProjectsInfo}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default Projects;
