import React from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import GroupComponent from "./GroupComponent/GroupComponent";
import "./Groups.scss";
import InvitationComponent from "./InvitationComponent/InvitationComponent";

type GroupsComponentProps = {
  invitations?: any[];
  loading: boolean;
  replayTour: () => void;
};

const Groups = ({ loading, invitations, replayTour }: GroupsComponentProps) => {
  const user = useSelector((state: any) => state.user);
  const memberships = user.data?.memberships;

  return (
    <>
      <h2 id="groups_title" className="clearfix">
        <div>
          Groups
          <a
            id="replay_tour"
            className="pull-right"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              replayTour();
            }}
          >
            <span
              className="ico-help"
              data-placement="left"
              title="Take the tour."
            ></span>
          </a>
        </div>
      </h2>
      <Row className="groups-list">
        {loading && (!memberships || memberships.length == 0) && (
          <>
            <GroupComponent membership={null} />
            <GroupComponent membership={null} />
            <GroupComponent membership={null} />
          </>
        )}
        {memberships?.map((membership: any) => (
          <GroupComponent membership={membership} />
        ))}
        {invitations?.map((invitation: any) => (
          <InvitationComponent invitation={invitation} />
        ))}
      </Row>
    </>
  );
};

export default Groups;
