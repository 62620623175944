import React from 'react';
import { Col, Container, Row } from "react-bootstrap";

import jane_f_logo from "../../assets/img/press/jane-f.png";
import alley_watch from "../../assets/img/press/alleyWatch.png";
import w_rumpus from "../../assets/img/press/w-rumpus.png";
import diversify_logo from "../../assets/img/press/diversify_logo.jpg";

import './Press.scss';


const Press = () => {
    return (
        <Container className="ink-press">
            <Row>
                <Col md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 2 }}>
                    <h1>Press</h1><br/><br/>
                </Col>
            </Row>

            <Row>
                <Col md={{ span: 9, offset: 1 }} lg={{ span: 8, offset: 2 }} className="press-view">
                    <Row>
                        <Col md={10}>
                            <h2>Guest blog post</h2><br/>
                            <img className="img-responsive press-img" src={jane_f_logo} alt="diversityjobs Logo"/>
                            <h3><a href="http://janefriedman.com/2015/06/10/find-the-right-critique-group/" target="_blank">How to Find the Right Critique Group or Partner for You</a></h3>
                            <p className="sm-text">BROOKE MCINTYRE - JUNE 10, 2015</p>
                            <p>
                                Finding a writing group or partner is a lot like dating. There's an element of searching
                                and an element of matching. You're looking for people you can share a piece of your
                                creative self with, for people you want to spend time on, for people who can help you
                                become a stronger writer—a tribe or community. <a href="http://janefriedman.com/2015/06/10/find-the-right-critique-group/" target="_blank" className="blue"> Read More</a>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col md={{ span: 9, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                    <h2>Interviews</h2><br/>
                </Col>
            </Row>

            <Row>
                <Col md={{ span: 9, offset: 1 }} lg={{ span: 8, offset: 2 }} className="press-view">
                    <Row>
                        <Col md={10}>
                            <br/>
                            <img className="img-responsive press-img" src={alley_watch} alt="Alley Watch Logo"/>
                            <h3>
                                <a href="http://www.alleywatch.com/2015/08/this-nyc-startup-will-make-it-easier-to-finish-that-book-you-started-in-your-twenties/"
                                   target="_blank">This NYC Startup Will Make It Easier to Finish That Book You Started
                                    In Your Twenties</a></h3>
                            <p className="sm-text">ALLEYWATCH - AUGUST 6, 2015</p>
                            <p>Writing can be a daunting task. Whether it be a full-fledged book or even a blog post,
                                getting quality feedback is essential if you want to produce quality work. But even if
                                you do have a group of friends willing to take a look at your writing, it is often
                                difficult to find engaged individuals...<a
                                        href="http://www.alleywatch.com/2015/08/this-nyc-startup-will-make-it-easier-to-finish-that-book-you-started-in-your-twenties/"
                                        target="_blank" className="blue"> Read More</a></p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <br/>
            <Row>
                <Col md={{ span: 9, offset: 1 }} lg={{ span: 8, offset: 2 }} className="press-view">
                    <Row>
                        <Col md={10}>
                            <img className="img-responsive press-img" src={w_rumpus} alt="Writer's Rumpus Logo"/>
                            <h3><a href="http://writersrumpus.com/2014/11/04/inked-voices-a-new-way-to-meet-critique/"
                                   target="_blank">Inked Voices: A new way to meet &amp; critique</a></h3>
                            <p className="sm-text">MARIANNE KNOWLES - NOVEMBER 4, 2014</p>
                            <p>Today, Writers' Rumpus is chatting with Brooke McIntyre, founder of Inked Voices, a
                                website and tool that lets writers run cloud-based critique groups. Brooke is a writer,
                                a reader, a business professional, and a mom of two little ones. I first met her online
                                back in the spring, when the beta test for Inked Voices was underway.<a
                                        href="http://writersrumpus.com/2014/11/04/inked-voices-a-new-way-to-meet-critique/"
                                        target="_blank" className="blue"> Read More</a></p>
                        </Col>
                    </Row>
                </Col>
            </Row>


            <Row>
                <Col md={{ span: 9, offset: 1 }} lg={{ span: 8, offset: 2 }} className="press-view">
                    <Row>
                        <Col md={10}>
                            <br/>
                            <img className="img-responsive" src={diversify_logo} alt="diversityjobs Logo"/>
                            <h3><a href="http://streetsmart.diversityjobs.com/tech-startup-founder/" target="_blank">Tech
                                Startup Founder: “You Just Have To Go For It”</a></h3>
                            <p className="sm-text">ERIN ROBERTS - MAY 30, 2014</p>
                            <p>These days, there's an app or a website for everything. Brooke McIntyre is using the web
                                to
                                bring together writers looking to help each other improve their work and unleash their
                                creativity. She may not have a tech background, but she's using a relatively new medium
                                to
                                update a century's old process.<a
                                        href="http://streetsmart.diversityjobs.com/tech-startup-founder/"
                                        target="_blank" className="blue"> Read More</a></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Press;
