


const config = {
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  BASE_FE_URL: process.env.REACT_APP_BASE_FE_URL,
  WS_BASE_URL: process.env.REACT_APP_WS_BASE_URL,
  PUBLIC_POSTHOG_KEY: process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  PUBLIC_POSTHOG_HOST: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  REACT_APP_PUBLIC_POSTHOG_KEY: process.env.REACT_APP_PUBLIC_POSTHOG_KEY
};

export default {
  ...config,
};
