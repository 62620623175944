import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";

import useFetch from "custom-hooks/useFetch";
import './Testimonials.scss';


const Testimonials = () => {
    const [state, fetchData] = useFetch();

    const [testimonials, setTestimonials] = useState([])

    useEffect(() => {
        fetchData({
            url: "testimonials/",
            method: "GET",
        }).then((res) => {
            setTestimonials(res.results);
        });
    }, [])

    return (
        <Container className="ink-testimonials">
            <Row>
                <span>
                    <h1>Here's what people think about us</h1>
                </span>

                <Col md={4}>
                    {testimonials.map((testimonial: any, index: number) => {
                        if ((index) % 3 === 0) {
                            return (
                                <div key={testimonial.id}>
                                    <img alt={`testimonial-${testimonial.id}`} src={testimonial.picture} />
                                    <p dangerouslySetInnerHTML={{ __html: testimonial?.testimonial }} />
                                    <p className="author">- {testimonial.name}<br/>{testimonial.description}</p>
                                </div>
                            );
                        }
                        return null;
                    })}
                </Col>

                <Col md={4}>
                    {testimonials.map((testimonial: any, index: number) => {
                        if ((index - 1) % 3 === 0) {
                            return (
                                <div key={testimonial.id}>
                                    <img alt={`testimonial-${testimonial.id}`} src={testimonial.picture} />
                                    <p dangerouslySetInnerHTML={{ __html: testimonial?.testimonial }} />
                                    <p className="author">- {testimonial.name}<br/>{testimonial.description}</p>
                                </div>
                            );
                        }
                        return null;
                    })}
                </Col>

                <Col md={4}>
                    {testimonials.map((testimonial: any, index: number) => {
                        if ((index - 2) % 3 === 0) {
                            return (
                                <div key={testimonial.id}>
                                    <img alt={`testimonial-${testimonial.id}`} src={testimonial.picture} />
                                    <p dangerouslySetInnerHTML={{ __html: testimonial?.testimonial }} />
                                    <p className="author">- {testimonial.name}<br/>{testimonial.description}</p>
                                </div>
                            );
                        }
                        return null;
                    })}
                </Col>
            </Row>
        </Container>
    );
}

export default Testimonials;
