
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setEventsFilters } from 'redux/eventsFiltersReducer';

const EventTypeFilters = (props: any) => {
    const { types } = props;
    const eventsFilters = useSelector((state: any) => state.eventsFilters);
    const dispatch = useDispatch();

    const toggleType = (name: string) => {
        dispatch(setEventsFilters(
            {
                type: { ...eventsFilters.type, [name]: !(eventsFilters.type && eventsFilters.type[name]) || false },
                category: { ...eventsFilters.category },
                free: eventsFilters.free,
                available: eventsFilters.available,
            }
        ));
    }

    return <>
        <h4>Type</h4>
        <p>
            <span className='button-list'>
                {types?.map((t: any) => (
                    <Button
                        className={"purple " + ((eventsFilters.type && eventsFilters.type[t.key]) ? "" : "inactive")}
                        onClick={(e) => toggleType(t.key)}>
                        {t.name}
                    </Button>))}
            </span>
        </p>
        {
            eventsFilters?.type && Object.entries(eventsFilters?.type).filter((b: any) => b[1]).length > 0 &&
            <div className='type-desc-container'>
                {types.map((t: any) => ((eventsFilters.type && eventsFilters.type[t.key]) &&
                    <p className='type-desc'>
                        <span className='name'>{t.name}:</span> <span dangerouslySetInnerHTML={{ __html: t.description }} />
                    </p>))}
            </div>
        }
    </>;
}

export default EventTypeFilters;