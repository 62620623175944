import React from 'react';
import { Col, Container } from "react-bootstrap";

import './TermsOfService.scss';


const TermsOfService = () => {
    return (
        <Container className="ink-terms-of-service">

            <Col sm={12} xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }}>
                <h1>Terms of Service and Privacy Policy</h1>
                <p>This is a legally binding contract between you and Inked Voices LLC. You consent to it by using the
                    Inked Voices website or mobile application. Please make sure you read it, because it is important.
                    We've tried to make it as clear as possible, but we welcome your feedback to <a
                            href="mailto:support@inkedvoices.com">support@inkedvoices.com</a>.
                </p>

                <h2>1. Definitions</h2>
                <p>We're going to use some shorthand to make this easier to read. When we say “site,” we mean
                    InkedVoices.com and the services offered through that website, which was developed by Inked Voices,
                    LLC, a New York Limited Liability Company (LLC). When we say “application,” we are referring to the
                    software application for mobile devices developed by Inked Voices LLC. When we say “we,” “us,” or
                    “our,” we mean Inked Voices LLC. When we say “your content,” we mean any text, sound, graphics or
                    other material which you post, upload, or otherwise place or share on the site or the application;
                    similarly, when we say “Inked Voices content,” we mean the copyrightable text, sound, graphics, and
                    other material owned by Inked Voices LLC. And when we say “terms,” we mean both these terms of
                    service and our Code of Conduct.</p>

                <h2>2. Accepting these terms and changes</h2>
                <p>Please read these terms before using the site or application. If you do not agree to these terms, you
                    may not register for the site or application. If we make material changes to these terms, we'll let
                    you know either through the site or application, or via email (at the email address you provide). If
                    you do not agree to those changes, you may send a request to cancel your account to <a
                            href="mailto:support@inkedvoices.com">support@inkedvoices.com</a>. If we do not hear from
                    you within ten days, the revised terms will apply to you. You are responsible for reviewing and
                    becoming familiar with any modifications to the terms.</p>

                <h2>3. Privacy policy</h2>
                <p>When you register with our site or our application, you will be asked to provide certain personal
                    information, including your name and email address. We will store your personal information, but
                    will not share it with any third parties, except as necessary to provide the services offered, to
                    monitor usage, and to improve the service. For example, we may store your personal information along
                    with your files and data on a third party server; or we may use a third party analytics tool to
                    learn how you are using Inked Voices and how we can improve that experience. We also use your
                    personal information to send you notifications about the service and to respond to customer support
                    requests. We will also use the information you provided to us on our site to help you find other
                    writers with shared interests. We will never share your personal information with advertisers or
                    send you promotions for unrelated services. You can access and change your personal information or
                    cancel your account by logging in to your account settings page. If you are using our application,
                    you may stop collection of information by uninstalling the application.</p>

                <p>We may be required to disclose your personal information in order to:</p>
                <ul>
                    <li>Comply with the law or legal process;</li>
                    <li>Protect or defend our rights or property, or the rights or property of others;</li>
                    <li>Enforce these terms; or</li>
                    <li>Respond to claims that the content of any material on our system violates the rights of
                        others.
                    </li>
                </ul>

                <p>If we must disclose your personal information in order to comply with the law or legal process, we
                    will inform you (at the email address you provide) as soon as practicable, provided that it is
                    lawful for us to do so.</p>

                <p>We use the Google AdWords remarketing service to advertise on third party websites (including Google)
                    to previous visitors to the Inked Voices site. This could mean that we advertise to previous
                    visitors who haven't completed a task on our site, for example becoming a member or using the
                    contact form to make an inquiry. Such advertising could be in the form of an advertisement on the
                    Google search results page, or a site in the Google Display Network. Third-party vendors, including
                    Google, use cookies to serve ads based on someone's past visits to the Inked Voices site. Of course,
                    any data collected will be used in accordance with our own privacy policy and Google's privacy
                    policy. You can set preferences for how Google advertises to you using the <a
                            href="https://support.google.com/adsense/troubleshooter/1631343?hl=en">Google Ad Preferences
                        page</a>, and if you want to, you can opt out of interest-based advertising entirely by cookie
                    settings or permanently using a browser plugin.</p>

                <p>We will also aggregate user information and perform statistical analyses of the collective behavior
                    of our members and visitors, to measure overall demographics, and to analyze how to improve our
                    service. We may share this information with third parties (such as Google Analytics), but such
                    aggregate information does not contain personal information other than IP addresses. In addition, we
                    may compile and disclose aggregate information about our users for promotional or other purposes.
                    For example, we might want to disclose that a certain percentage of our users are located within a
                    particular geographic area or work most frequently in the mornings.</p>

                <p>We use session and login cookies, which help us keep track of when a person is logged in and
                    determine the content or sites users link to, and which services people use. We will also capture
                    login timestamps, and usage statistics, as well as IP addresses, type of mobile device you use, your
                    mobile operating system, the type of mobile Internet browsers you use, your mobile device's unique
                    device ID, and information about the way you use the site or application. We'll capture some
                    information about the documents stored on the site (such as word count, number of versions, etc.),
                    but we will not view the content of your documents unless you give us permission to do so, or it
                    becomes necessary in order to comply with legal obligations.</p>

                <p>We will communicate with you via email, to the email address you provide to us. Some communications,
                    such as emails informing you of new features, may be unsubscribed to via the unsubscribe link in the
                    email. If you would like to cease all communications from us, please send an email to <a
                            href="mailto:support@InkedVoices.com">support@InkedVoices.com</a> and we will cancel your
                    account.</p>

                <p>We will collect information related to your payment method (valid credit card number, type,
                    expiration date or other financial information). We do not, however, store that financial
                    information; such information is stored by our payment processor (the "Payment Processor"), and use
                    and storage of that information is governed by the Payment Processor's applicable terms of service
                    and privacy policy (the Payment Processor may also have other terms, and you are responsible for
                    locating and familiarizing yourself with those terms, as applicable). We do receive certain
                    information from our Payment Processor: (1) a unique token that we connect with your Account to
                    enable you to make further purchases using the information stored by our Payment Processor and (2)
                    the last four digits of the credit card number associated with that token, so that we can prevent
                    fraudulent transactions and identity theft.</p>

                <p>Your Account Information is protected by a password chosen by you for your privacy and security. It
                    is your responsibility to protect your password. We seek to protect your information (including your
                    Account Information) to ensure that it is kept private. We provide reasonable safeguards to protect
                    your Account information; however, we can't guarantee the security of any information and you should
                    be aware that no security system can prevent all potential security breaches. Unauthorized entry or
                    use, hardware or software failure, and other factors may compromise the security of user information
                    at any time. </p>

                <h2>4. Permitted users</h2>
                <p>Inked Voices is designed for use by adults, preferably age sixteen or older. If we learn that we have
                    collected personal information from a child under the age of thirteen, we will delete that
                    information as quickly as possible. If you believe that we might have any information from a child
                    under the age of thirteen, please contact us at <a href="mailto:support@inkedvoices.com">support@inkedvoices.com</a>.
                    When you use the site or application, you represent and warrant that you have the legal capacity to
                    form a binding contract with us, and are doing so by your agreement to these terms.</p>

                <h2>5. Copyright and intellectual property</h2>
                <p>We own our stuff; you own yours.</p>

                <p>You retain ownership of all content you post, upload to, or otherwise share on the site or
                    application. In addition, by posting, uploading or otherwise sharing such content on this site or
                    the application, you warrant that you have all of the necessary rights to share such content.
                    However, by posting, uploading, or sharing your content, you grant us a non-exclusive,
                    sub-licensable, royalty-free, worldwide license to access, use, and display your content in
                    connection with the site or application and services we provide to you. This license permits us to
                    perform the services that you registered for; for example, it permits us to store your content on
                    redundant data servers, but it does not permit us to sell your content to advertisers or display
                    your content other than to those you have authorized to view it, nor does it transfer the copyright
                    to us. This license ends when you delete the material or your account.</p>

                <p>In addition, the site provides features that allow you to share your content with others. There are
                    many things that others may do with that content, including copying it, modifying it, and re-sharing
                    it. So, please consider carefully what you choose to share and with whom; we take no responsibility
                    for that activity.</p>

                <p>When you delete your account, any comments or content that you contributed to someone else's
                    documents will remain on the site, and will not be deleted.</p>

                <p>All Inked Voices content, the selection, compilation, arrangement and presentation of all materials,
                    and the overall design of the site and application are copyrighted by us, and are protected by US
                    and international laws. Use of our content without our express prior written permission is strictly
                    prohibited.</p>

                <p>InkedVoices.com, Inked Voices, and the Inked Voices logo are trademarks or registered trademarks of
                    Inked Voices LLC in the United States and other foreign countries. Our trademarks may not be used in
                    connection with any product or service without our express written permission.</p>

                <h2>6. Digital Millennium Copyright Act</h2>
                <p>Inked Voices deals with copyright infringement in accordance with the Digital Millennium Copyright
                    Act (DMCA).</p>

                <p>You may not post, upload, or otherwise place any content or information on the site or application
                    that belongs to a third party, unless you have the legal right to do so. If you believe that any
                    such information or content has been posted on the site or application, please send a notice of
                    copyright infringement containing the following information to the designated agent at the address
                    below:</p>

                <ul>
                    <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple
                        copyrighted works are covered by a single notification, a representative list of such works.
                    </li>
                    <li>Identification of the material that is claimed to be infringing or to be the subject of
                        infringing activity and that is to be removed or access to which is to be disabled, and
                        information reasonably sufficient to permit us to locate the material.
                    </li>
                    <li>Contact information for the notifying party, including name, address, telephone number, and
                        email address.
                    </li>
                    <li>A statement that the notifying party believes, in good faith, that the material's use is not
                        authorized by the copyright owner, its agent, or law.
                    </li>
                    <li>A statement made under penalty of perjury that the information in the notification is accurate
                        and that the notifying party is authorized to act on behalf of the owner of exclusive rights.
                    </li>
                    <li>A physical or electronic signature of a person authorized to act on behalf of the owner of
                        exclusive rights whose rights have been allegedly infringed.
                    </li>
                </ul>

                <p>Upon notification of claimed infringement, we will respond expeditiously to remove, or disable access
                    to, the material that is claimed to be infringing or to be the subject of infringing activity. We
                    will also notify the person who posted, uploaded, or otherwise placed the allegedly infringing
                    material on the site or application that we have removed or disabled access to such material.</p>

                <p>If you believe that material has been removed improperly, you must send a written counter
                    notification to the agent, and include:</p>
                <ul>
                    <li>Identification of the material that has been removed or to which access has been disabled and
                        the location at which the material appeared before it was removed or access to it was disabled.
                    </li>
                    <li>A statement under penalty of perjury that you have a good faith belief that the material was
                        removed or disabled as a result of mistake or misidentification.
                    </li>
                    <li>Your name, address, and telephone number, and a statement that you consent to the jurisdiction
                        of Federal District Court for the judicial district in which the address is located, or if your
                        address is outside of the United States, for any judicial district in which we may be found, and
                        that you will accept service of process from the person who provided the original notification
                        or an agent of such person.
                    </li>
                    <li>Your physical or electronic signature.</li>
                </ul>

                <p>Upon receipt of a counter notification complying with these requirements, we will promptly provide
                    the original reporter with a copy of the counter notification, and inform that person that we may
                    replace the removed material or cease disabling access to it if we do not receive notice within ten
                    business days that the original reporter is seeking a court order to prevent further infringement of
                    the material at issue.</p>

                <address>
                    Designated Agent<br></br>
                    Brooke McIntyre<br></br>
                    8401 Mayland Drive, Suite S<br></br>
                    Richmond, VA 23294<br></br>
                    <a href="mailto:dmca@inkedvoices.com">dmca@inkedvoices.com</a>
                </address>

                <h2>7. Inked Voices Rules and Code of Conduct</h2>
                <p>Inked Voices is designed to help people collaborate together; as such, we have some ground rules that
                    you must adhere to when using the site. In addition, we have a Code of Conduct that is part of these
                    Terms. The Code of Conduct is found at the end of these Terms. The Code of Conduct provisions are
                    incorporated by reference into the Terms of Service and have the same legal effect as if they were
                    set forth in the Terms of Service.</p>

                <p><strong>The following are prohibited uses of Inked Voices. You agree that you will not do any of the
                    following:</strong></p>

                <ul>
                    <li>Threaten other users with violence.</li>
                    <li>Use hateful, abusive, harassing, libelous, or obscene language towards other users.</li>
                    <li>Post any material that infringes or violates any third party's copyright, trademark, trade
                        secret, privacy, or other proprietary or property right;
                    </li>
                    <li>Post any material which promotes illegal activity, could constitute a criminal offense, give
                        rise to civil liability, or otherwise violate any applicable law or regulation;
                    </li>
                    <li>Use the site to send spam, chain letters, junk mail, or any other type of unsolicited mass
                        email;
                    </li>
                    <li>Use the site to distribute viruses or other harmful, disruptive, or destructive files;</li>
                    <li>Use or attempt to use another person's account;</li>
                    <li>Disrupt or interfere with the security of, or otherwise abuse, the site, or any servers or
                        networks connected to the site;
                    </li>
                    <li>Attempt to obtain unauthorized access to the site;</li>
                    <li>Impersonate another person;</li>
                    <li>Share with any minor any content or materials inappropriate for children, or allow any minor
                        access to such materials;
                    </li>
                    <li>Systematically harvest data from the site, or programmatically register accounts on the site.
                    </li>
                </ul>

                <p>You further agree that you are responsible for your actions in relation to the site, and for any
                    communications transmitted under your account; that you will comply with all laws relating to the
                    transmission of technical data or software exported from the United States; and that you will comply
                    with all applicable local, state, national and international laws and regulations, including without
                    limitation those related to privacy, data collection, and email creation and delivery.</p>

                <p>If it appears you have violated any portion of these rules or the Code of Conduct, we may, in our
                    sole discretion, remove any offending material or immediately limit or terminate your account.</p>

                <h2>8. Canceling your account</h2>
                <p>You can cancel your account at any time by sending an email to <a href="support@inkedvoices.com">support@inkedvoices.com</a>.
                    If you like, you may export your documents prior to canceling your account. Upon canceling your
                    account, your files will be marked for deletion in sixty days, and will be deleted upon expiration
                    of that sixty-day period.</p>

                <h2>9. Membership Fees</h2>
                <p>This site requires the payment of fees for your membership in Inked Voices, and for continued use of
                    the site. All fees are stated on the Membership Plans page of this site, and are stated in United
                    States dollars. You are required to pay all applicable fees and any related taxes or additional
                    charges.</p>

                <p>The fees you pay provide you with a limited license for you to use Inked Voices. You do not obtain
                    any right, title, or ownership interest of any kind in Inked Voices. You may not relicense, resell,
                    transfer, or exchange your limited license, except as expressly allowed by this site.</p>
                <p>Inked Voices may, in its sole discretion, modify the functionality of, or eliminate, services
                    provided by the site or the terms and conditions under which services are provided.</p>

                <p>The fees you pay are final and non-refundable, except at Inked Voices' sole discretion and in
                    accordance with the rules stated at the time of purchase. Termination of your Account or your rights
                    under this Agreement may result in forfeiture of fees that you have paid; for example, if your
                    Account is suspended, you forfeit your right to use any credits you may have purchased.</p>

                <p>Inked Voices may change its fees at any time. To the extent applicable, Inked Voices will provide you
                    reasonable notice of any such changes by posting the new fees on the site and/or by sending you an
                    email notification. If you do not wish to pay the new fees, you may choose to terminate your use of
                    Inked Voices prior to the change going into effect.</p>

                <h2>10. Disclaimer of warranties</h2>
                <p>We will strive to prevent interruptions to the service and be good stewards of your data. However,
                    the site and our services are provided on an “as is” and “as available” basis. We disclaim all
                    warranties of any kind, whether express or implied, including without limitation any warranty of
                    merchantability, fitness for a particular purpose, or non-infringement. We do not make any warranty
                    that the site or our services will meet your requirements, or that the services will be
                    uninterrupted, timely, secure, or error free, or that defects, if any, will be corrected. You
                    understand that you download from or otherwise obtain content or services through the site at your
                    own discretion and risk.</p>

                <h2>11. Limitations of liability</h2>
                <p>Under no circumstances — including, without limitation, negligence — shall we be liable for any
                    direct, indirect, incidental, special or consequential damages, resulting from (1) the use or the
                    inability to use the site; (2) the cost of procurement of substitute goods and services; (3)
                    unauthorized access to or alteration of your transmissions or data; (4) loss of profits, use, data
                    or other intangibles, even if we have been advised of the possibility of such damages. The foregoing
                    shall not apply to the extent prohibited by applicable law.</p>

                <h2>12. Indemnification</h2>
                <p>You agree to indemnify, defend, and hold harmless us, our officers, directors, employees, members,
                    partners, agents, and suppliers, and their respective affiliates, officers, directors, employees,
                    members, shareholders, partners, and agents, from any and all claims and expenses, including
                    attorneys' fees, arising out of your use of the site, including but not limited to your violation of
                    this agreement. We may, at our sole discretion, assume the exclusive defense and control of any
                    matter subject to indemnification by you. The assumption of such defense or control by us, however,
                    shall not excuse any of your indemnity obligations.</p>

                <h2>13. Right to terminate</h2>
                <p>We may at any time decide to alter, amend, modify, or terminate the site, any functionality or
                    portion of it, all in our sole discretion, and you understand that there is no guarantee that the
                    site or any portion or functionality of it will continue to operate or be available for any
                    particular period of time.</p>

                <h2>14. General</h2>
                <p>These terms shall be governed by and construed in accordance with the laws of the state of New York,
                    without giving effect to its conflict of law provisions. You agree that you will bring any claim or
                    cause of action arising out of your use of the site in the courts located within Kings County, New
                    York, and you also agree to submit to the personal and exclusive jurisdiction of those courts. You
                    agree that any claim or cause of action arising out of your use of the site or these terms must be
                    filed within one year after such claim or cause of action arose or it shall be forever barred,
                    notwithstanding any statute of limitations or other law to the contrary. If any provision contained
                    in these terms is determined unenforceable, then such provision will be severed and replaced with a
                    new, enforceable, provision that reflects the intent of the original provision as closely as
                    possible, and the remaining provisions of these terms will remain in full force and effect. No
                    waiver of any provision of these terms shall be effective except pursuant to a written instrument
                    signed by us expressly waiving compliance, and any such waiver shall be effective only in the
                    specific instance and for the specific purpose stated in such writing. You agree that no joint
                    venture, partnership, employment, or agency relationship exists between you and us as a result of
                    these terms and/or your use of the site. You may not assign any right, interest, or benefit provided
                    under these terms or through the site without our express prior written consent. These terms set
                    forth the entire agreement between you and us, and supersede any and all prior communications,
                    agreements and proposals, whether electronic, oral or written, between you and us, with respect to
                    the site and our services. A printed version of these terms and of any notice given in electronic
                    form shall be admissible in judicial or administrative proceedings based upon or relating to these
                    terms and/or your use of the site to the same extent and subject to the same conditions as other
                    business documents and records originally generated and maintained in printed form.</p>
            </Col>
        </Container>
    );
}

export default TermsOfService;
