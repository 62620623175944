import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api-actions";

export const lastConnectionsSlice = createSlice({
    name: "lastConnections",
    initialState: {
        last_connections: {},
        loading: false,
    },
    reducers: {
        lastConnectionsRequested: (state, action) => {
            state.loading = Object.keys(state.lastConnections).length === 0;
        },

        lastConnectionsReceived: (state, action) => {
            state.last_connections = action.payload.last_connections;
            state.loading = false;
        },

        lastConnectionsRequestFailed: (state, action) => {
            state.loading = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const lastConnectionsReducer = lastConnectionsSlice.reducer;

export const loadLastConnections = (token, postId) => (dispatch) => {
    const { lastConnectionsRequested, lastConnectionsReceived, lastConnectionsRequestFailed } =
        lastConnectionsSlice.actions;
    const url = `forum/last-connections/` + postId + '/';
    return dispatch(
        apiCallBegan({
            url,
            token,
            onStart: lastConnectionsRequested.type,
            onSuccess: lastConnectionsReceived.type,
            onError: lastConnectionsRequestFailed.type,
        })
    );
};
