import axios from "axios";
import * as actions from "./api-actions";
import Config from "config";

const api =
  ({ dispatch }) =>
    (next) =>
      async (action) => {
        if (action.type !== actions.apiCallBegan.type) return next(action);

        const { url, method, data, onStart, onSuccess, onError, token } =
          action.payload;

        const headers = {
          accept: "application/json",
          "Content-type": "application/x-www-form-urlencoded",
        };
        if (token) {
          headers["Authorization"] = "Token " + token;
        }

        if (onStart) dispatch({ type: onStart });

        next(action);

        try {
          const response = await axios.request({
            baseURL: Config.BASE_API_URL,
            url,
            method,
            data,
            headers,
          });
          // General
          dispatch(actions.apiCallSucess(response.data));
          // Specific
          if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
        } catch (error) {
          // General
          dispatch(actions.apiCallFailed(error.message));
          // Specific
          if (onError) dispatch({ type: onError, payload: error.message });
        }
      };

export default api;
