import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import "./GroupDetail.scss";
import config from "config";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Group } from "types";
import AuthenticationContext from "contexts/AuthenticationContext";
import sendRequest from "services/dataService";
import GroupDetailTabs from "components/groups/details/groupDetailTabs";

export default function GroupDetailPage() {
  const [selectedTab, setSelectedTab] = useState("critique");
  const { id } = useParams() as any;
  const [group, setGroup] = useState<Group>();
  const authenticationContext = useContext(AuthenticationContext);

  const fetchGroup = (id: number) => {
    const url = `api/v1.0/group/${id}/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setGroup(response);
    });
  };

  useEffect(() => {
    setSelectedTab("critique");
    fetchGroup(id);
  }, [id]);

  const baseUrl = config.BASE_API_URL || "";
  const imgUrl = group?.avatar.startsWith("http")
    ? group?.avatar
    : baseUrl + group?.avatar;

  return (
    <div className="container mx-auto p-4">
      <Row>
        {/* Left column */}
        <Col sm={3}>
          <Card className="sidebar">
            <Card.Body>
              <img src={imgUrl} className="group-picture" />
              <Button variant="primary" className="purple">
                Message Group
              </Button>
              <div className="section">
                <h3 className="subt">Goals</h3>
                <p className="text-sm text-gray-600 mb-4">asdfasdfasdf6</p>
              </div>
              <div className="section">
                <h3 className="subt">
                  <a href="#">Tech Tutorial</a>
                </h3>
              </div>
              <div className="section">
                <h3 className="subt">Description</h3>
                <p className="text-sm text-gray-600 mb-4">asdfasdfasdfasdf</p>
              </div>
              <div className="section group-members-list">
                <h3 className="subt">Members</h3>
                <Row>
                  {group?.members?.map((member) => (
                    <Col sm={6}>
                      <div>
                        <img src={member.avatar} className="w-full" />
                      </div>
                      <p className="text-sm">{member.pen_name}</p>
                    </Col>
                  ))}
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Right column */}
        <Col sm={9}>
          <h1 className="text-3xl font-bold mb-4">{group?.name}</h1>
          <div className="flex justify-between items-center mb-4">
            <div>
              <GroupDetailTabs groupId={id} />
            </div>
          </div>

          <Card className="mt-4">
            <Card.Header>
              <Card.Title className="flex justify-between items-center">
                Group Discussion
                <Button variant="outline" size="sm">
                  Options
                </Button>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <textarea
                placeholder="Ask a question, answer one or make a comment."
                className="mb-4"
              />
              <Button>Post</Button>
              <div className="mt-4 border-t pt-4">
                <div className="flex justify-between items-start">
                  <div>
                    <span className="font-bold">alejandro</span>: this is a
                    comment
                    <Button variant="link" className="p-0 h-auto">
                      Reply
                    </Button>
                  </div>
                  <div className="text-sm text-gray-500">23/Sep 24</div>
                </div>
                <Button variant="link" className="p-0 h-auto">
                  Edit
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
