import { createSlice } from "@reduxjs/toolkit";

export const replyToSlice = createSlice({
    name: "replyTo",
    initialState: {
        showReplyForm: {},
    },
    reducers: {
        setReplyTo: (state, action) => {
            state.showReplyForm[action.payload.id] = action.payload.value;
        },
    },
});

// Action creators are generated for each case reducer function
export const replyToReducer = replyToSlice.reducer;

export const setReplyTo = (id, value) => (dispatch) => {
    const { setReplyTo } = replyToSlice.actions;
    return dispatch({ type: setReplyTo.type, payload: { id, value } });
};
