import React from 'react';
import {
    Col,
    Container,
    Row,
  } from "react-bootstrap";

import './Resources.scss';


const CritiqueGuidelinesFiction = () => {
    return (
        <Container className="container about-view">
            <Row>
                <Col md={{span: 9, offset: 1}}>
                    <Row>
                        <h1>Inked Voices Critique Guide - Fiction</h1>
                        <p>
                            Consider the following when noting a manuscript's strengths and what could be stronger or further developed. These questions are intended to spark ideas and conversation; use what feels relevant to your experience with the manuscript at hand.
                        </p>
                        <h2>Story Core/ Heart</h2>
                        <ul>
                            <li>What was your experience as a reader? How does your experience compare to the pitch or other contextual materials the writer shared?</li>
                            <li>What was this story ultimately about? (e.g. what themes emerged for you)</li>
                        </ul>
                        <h2>Character development</h2>
                        <ul>
                            <li>Does the main character have a clear problem, goal, or desire?</li>
                            <li>What are the stakes for the main character? Are the stakes high enough to hook the reader?</li>
                            <li>Does the main character feel real? I.e. do they have a distinct personality and interests, and is characterization consistent? </li>
                            <li>Is the main character a person whom readers 1) can relate to and 2) want to follow?</li>
                            <li>Does the character change over the course of the book? How? If not, does that choice work?</li>
                            <li>Do secondary characters feel real?</li>
                            <li>Do characters act in a way consistent with their age, profession, culture, etc.? Are characters stereotypical?</li>
                        </ul>
                        <h2>Plot</h2>
                        <ul>
                            <li>What is the external plot (Action)? What is the internal plot (Emotion)? Are both plots well developed over the course of the story?</li>
                            <li>Do the opening scenes hook the reader?</li>
                            <li>Does the opener set up the character's normal world/status quo, and establish the story trajectory?</li>
                            <li>Does each chapter move the external plot forward?</li>
                            <li>Does each chapter move the internal (emotional) plot forward?</li>
                            <li>What are this story's inciting incident, climax, and resolution?</li>
                            <li>Do subplots complement the main story and carry it forward?</li>
                            <li>If reading to the end of the book, is the ending satisfying?</li>
                        </ul>
                        <h2>Pacing</h2>
                        <ul>
                            <li>Do any areas feel too slow or too fast? What parts move at a pace that's just right?</li>
                            <li>Are you compelled to keep reading? If so, the writer may be successfully creating anticipation by raising questions you want answers to, or by escalating tension.</li>
                        </ul>
                        <h2>Structure</h2>
                        <ul>
                            <li>Is the POV choice working (why or why not) and is it consistent?</li>
                            <li>Does the balance of dialogue, narration, description and action work for the story?</li>
                            <li>Looking at the story as a whole, are the chapters sequenced in a way that escalates tension or builds anticipation?</li>
                            <li>Looking at chapters, are all scenes moving the story forward?</li>
                        </ul>
                        <h2>Setting and Worldbuilding</h2>
                        <ul>
                            <li>Where is the story set? When? As a reader, do you feel grounded in space and time?</li>
                            <li>Are the characters, descriptions, and dialogue consistent with their world?</li>
                            <li>Do descriptions use the five senses?</li>
                            <li>Are descriptions given through the lens of the the point of view character?</li>
                            <li>Is the amount of description working?</li>
                        </ul>
                        <h2>Voice and Language</h2>
                        <ul>
                            <li>Does the voice feel distinct and specific?</li>
                            <li>Do language choices build character, voice, and story mood? Could the language be amped up?</li>
                            <li>Does each character's dialogue reflect their own unique voice?</li>
                            <li>Is the writing sharp at the sentence level?</li>
                            <li>Are there any style or grammar patterns that disrupt the story for the reader?</li>
                        </ul>
                        <h2>Concept / Marketability</h2>
                        <ul>
                            <li>Does the manuscript feel fresh compared to other books in its category?</li>
                            <li>What kind of reader might enjoy this story? What comparable titles spring to mind?</li>
                        </ul>
                        <br/><br/><br/>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default CritiqueGuidelinesFiction;
