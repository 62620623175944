import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import AuthenticationContext from "contexts/AuthenticationContext";
import useFetch from "custom-hooks/useFetch";

import Loading from "components/loading/Loading";
import ProfileView from "./components/ProfileView";


const Profile = () => {
  const { id } = useParams() as any;
  const [state, fetchData] = useFetch();
  const [profileData, setProfileData] = useState<any>(null);
  const [userMemberships, setUserMemberships] = useState<any>(null);
  const authenticationContext = useContext(AuthenticationContext);

  useEffect(() => {
    fetchData({
      url: `profile/api/${id}/`,
      method: "GET",
      token: authenticationContext.token,
    }).then((res) => {
        setProfileData(res);
    }).catch((error) => {
      console.error("Error fetching profile data:", error);
    });
  }, [authenticationContext.token]);

  useEffect(() => {
    fetchData({
      url: `group/api/user-memberships/${id}/`,
      method: "GET",
      token: authenticationContext.token,
    }).then((res) => {
      setUserMemberships(res);
    }).catch((error) => {
      console.error("Error fetching membership data:", error);
    });
  }, [authenticationContext.token]);

  return (
    (profileData && userMemberships)
    ? <ProfileView profileData={profileData} userMemberships={userMemberships} />
    : <Loading />
  );
}

export default Profile;