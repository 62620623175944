import React from 'react';
import {
    Col,
    Container,
    Row,
  } from "react-bootstrap";

import './Resources.scss';


const CritiqueGuidelines = () => {
    return (
        <Container className="container about-view">
            <Row>
                <Col md={{span: 9, offset: 1}}>
                    <Row>
                        <h1>The Critique Process - For Critiquers</h1>
                        <p>
                            One of the most important professional relationships a writer has is with their critique
                            partners. Quality feedback can help a writer take their story to the next level. But sharing
                            one's creative work is a vulnerable act, requiring both courage and trust. As critique
                            partners, it is our responsibility to offer kind, thoughtful, constructive suggestions
                            that will help the writer see what's working and what may need improvement. Our goal is to
                            help the writer find a way to build on their strengths, tighten what needs tightening, and
                            tell THEIR OWN best story.
                        </p>
                        <p>
                            Before reading and critiquing a work, it's helpful to have context. Consider asking partners
                            to include a submission note with their work that includes the following:
                        </p>
                        <ul>
                            <li>Category and genre of the work (E.g. adult upmarket fiction, Young Adult SFF,
                                middle grade adventure)
                            </li>
                            <li>A short summary or pitch</li>
                            <li>What stage is the story in? First draft, second, fifth, ready to query? Is there
                                something they have especially worked on in this version?
                            </li>
                        </ul>
                        <p>
                            The aim is to determine what an author is trying to achieve - what story are they trying to
                            tell, and to what audience? It's also helpful to know what work the author has already put
                            into a piece and what specifically they would like help with.
                        </p>
                        <h2>Read first as a reader</h2>
                        <p>
                            The primary goal of the first read is to experience it. Ideally, you'll take an initial
                            read, beginning to end, without writing comments. But for many of us, turning off the
                            analytical mind can be difficult. Consider reading without commenting as much as possible,
                            using shorthand - question marks, exclamation marks, smiley faces - to mark places to
                            revisit. Flesh out comments on the next read.
                        </p>
                        <p>After reading through, reflect on your reactions to the work. This is called a reader's
                            response.
                        </p>
                        <ul>
                            <li>To you, what was the story about? What did you take away?</li>
                            <li>How did you feel as you read the pages? How did you feel overall, by chapter, by scene?
                                Consider marking places in the document where you felt something as a reader.
                            </li>
                            <li>What areas read too fast, too slow, or just right? Simply sharing our reader experience
                                with pacing often helps a writer decide what changes to make.
                            </li>
                            <li>Were there places you lost interest?</li>
                            <li>Did the pages deliver what you expected after reading the pitch or short summary?</li>
                            <li>Were any places confusing? If so, ask the writer questions or describe what was
                                unclear.
                            </li>
                            <li>What are you curious about after reading the piece? Leaving the reader with questions
                                they want answers to is a good thing - the reader has been hooked!
                            </li>
                            <li>Did you want to keep reading?</li>
                        </ul>
                        <h2>Read as a writer</h2>
                        <p>This is where you get to turn on your analytical writer's mind and look at the story through
                            a craft lens. However, a critique does not need to cover everything. Re-familiarize yourself
                            with the writer's intentions and any specific questions or requests. Otherwise, focus on the
                            craft areas that most need attention (or praise).</p>
                        <p>A critique helps a writer assess whether their story is working. So, assess story-level
                            elements first - plot, arc, setting, dialogue, etc. Why wordsmith sentences from a scene
                            that doesn't move the plot forward (and will be cut)? Work from the big picture, then zoom
                            in as the story sharpens.</p>
                        <p>Guides or templates offer a starting point for writing a critique, even for the most
                            experienced. Their questions can spark aha's and help you prioritize. Below are guides we've
                            made. Or, make your own.</p>
                        <ul>
                            <li><a href="/resources/picture-books-critique-guide/">Picture Books</a></li>
                            <li><a href="/resources/critique-guidelines-fiction/">Fiction Critique Guide</a></li>
                        </ul>
                        <h3>Draft your critique</h3>
                        <p>When writing a critique, remember your reader. Every writer hopes that this draft will be
                            done or close. Most realize that there's going to be more work to do. Give the writer your
                            honest reader's response and assessment, but do so in a way that honors the work they have
                            put in and, hopefully, gets them excited about their next revision.</p>
                        <h3>Overall Feedback</h3>
                        <p>Below are a few possible structures to organize your overall feedback:</p>
                        <ol type="1">
                            <li>What worked- what may need attention - questions
                                <ul>
                                    <li>Open with what you loved. Discuss what's working, which parts stand out, and
                                        what you see as the writer's strengths.
                                    </li>
                                    <li>Answer any specific questions the writer posed in their submission note.</li>
                                    <li>Ask any questions that came up for you as a reader.</li>
                                    <li>Offer suggestions/things to consider for development.</li>
                                    <li>Closing (e.g. you might thank the writer for the opportunity to read their pages
                                        and let them know you're available for any questions about your notes).
                                    </li>
                                </ul>
                            </li>
                            <li>Sequential (Beginning - Middle - End)
                                <p>Organize feedback by beginning, middle, end, sharing for each section your reader's
                                    response, story observations, and thoughts on craft. This structure works best when
                                    reading a whole story or large sections (divide the material into thirds or
                                    fourths). It works well when story arc, plot, and character development are the
                                    focus of your feedback.</p>
                            </li>
                            <li>Craft-focused
                                <p>Organize your critique by elements of craft - focusing on the elements you think are
                                    most important to consider for the story (whether they showcase a writer's strength
                                    or opportunities for development). For example, you might write address the
                                    following, each with its own paragraph:</p>
                                <ul>
                                    <li>Plot</li>
                                    <li>Character</li>
                                    <li>Voice</li>
                                    <li>Pace</li>
                                </ul>
                            </li>
                        </ol>
                        <h3>Inline Notes</h3>
                        <p>Inline notes - comments noted in the margins of the work - have the advantage of specifying
                            comments to a specific part of the text. However, inline comments do not need to be about
                            the line.</p>
                        <p>Here are some ways to use inline notes to mark big picture craft:</p>
                        <ul>
                            <li>Mark a passage - e.g. highlight a piece of the work that's moving quickly, moving
                                slowly, or especially making you feel as a reader; mark a section with notable dialogue.
                                Share your reader reaction and any questions.
                            </li>
                            <li>Mark examples of big picture areas mentioned in the overall comments - e.g. if you
                                mentioned the strong voice, highlight a few places where it especially stood out - or
                                places that felt inconsistent.
                            </li>
                            <li>Mark transition points - share places where the story or your response to it changed.
                                E.g. mark where the story moved from beginning to middle; highlight where you started to
                                really become invested in a character.
                            </li>
                        </ul>
                        <h4>Spelling, Grammar, and Style Comments</h4>
                        <p>Keep in mind that writers come to storytelling with varying levels of familiarity with the
                            mechanics of writing. For work with repeated errors -- e.g. misspellings, sentence
                            fragments, passive writing, word overuse-- gently point out patterns the writer can look for
                            and self-edit, highlighting a few examples in your overall notes. It's okay to mark an
                            occasional error inline. But avoid marking each and every instance --it's not a productive
                            use of your time and it will often overwhelm the writer.</p>
                        <p>The same advice applies to stylistic patterns. Feel free to gently share your opinion on such
                            patterns, but keep in mind that style choices are a component of a writer's individual
                            voice.</p>
                        <p>A rule of thumb: Unless a draft has reached the polishing stage, grammar and style notes
                            should not be the focus of a critique.</p>
                        <h3>Tone of Critiques</h3>
                        <p>The Latin roots of the word 'constructive' are 'con-' and 'stuere,' meaning to build
                            together. In providing constructive criticism, you are helping a writer build on what
                            they've created and thus improve as a writer. Think of the writer you're critiquing as a
                            colleague.</p>
                        <p>Critiques should be respectful of the writer and of the work. As readers of published
                            writing, we are consumers. As readers of drafts, we are friends, coaches, and partners. Our
                            job is to help the writer translate what is in their head to the page.</p>
                        <h4>Tips for constructive critiques</h4>
                        <ul>
                            <li>Frame your response as your own, using “I” statements or “for me.”</li>
                            <li>Comment on the work, not the writer.</li>
                            <li>Make observations. Observations about the work are usually neutral. Observational notes
                                can also help when trying to grasp a story for yourself. Example: list plot points you
                                see, list the emotional changes you see, share character traits that jump out at you.
                            </li>
                            <li>Share your takeaways from the work (e.g. this is a story about best friends drifting
                                apart; ultimately, this story examined Y)
                            </li>
                            <li>Elaborate with adjectives and examples when commenting on craft.</li>
                            <li>Ask questions! Ask questions to clarify intentions. Ask questions because you are
                                curious.
                            </li>
                            <li>Talk to the person as a peer. Avoid “don't”, “you should”, and “try not to”. Instead,
                                consider these: “Have you considered”, “have you tried”, “what if”, “maybe”, “perhaps”,
                                “might”.
                            </li>
                            <li>Your critique may offer suggestions and ideas for changes, but it does not have to.</li>
                        </ul>
                        <h3>Thick-skinned writers</h3>
                        <p>Remember, it is already hard to receive constructive feedback. Feedback might reveal the need
                            for big, time-consuming changes. This is the hard stuff - knowing changes are needed and
                            committing to the next revision anyway. Practicing this process does make a tougher, more
                            resilient writer.</p>
                        <p>When you work with a writer who says they have a thick skin, take that to mean that the
                            writer feels comfortable with the feedback process and would like a candid assessment. Give
                            that - constructively. Candid feedback does not need to be harsh, and it should not put a
                            writer in their place.</p>
                        <p>Let the writing part be tough, not the reading of your notes.</p>
                        <h2>Final Note</h2>
                        <p>The goal of any critique is to help a writer bring forth the best version of the story they
                            want to tell - not the one you might write. Be curious, be kind, be specific and have
                            fun.</p>
                        <h2>Examples</h2>
                        <h4>Be Specific with Your Notes</h4>
                        <p><b>No:</b> I like your writing style!<br/>
                        </p><p>
                            <b>Better</b>: You have a strong voice.<br/>
                        </p><p>
                        <b>Yes</b>: Your voice is gentle and reminds me of X author.</p><br/>
                        <p><b>No:</b> I'm confused when I read this story.<br/>
                        </p><p>
                            <b>A little better:</b> The plot is unclear.<br/>
                        </p><p>
                            <b>Yes:</b> I'm finding myself a bit confused about the sequence of events in the plot. First, we
                            see the main character doing X, then the story seems to skip ahead to Y, then….etc. Could
                            you clarify the timeline?
                        </p><br/>
                        <p><b>No:</b> I loved your story. Congratulations!<br/>
                            <b>Yes:</b> I loved your story. I was hooked from the opening line by your sassy main character,
                            and as I learned all of the challenges she had overcome in her past (the critiquer could add
                            examples), I really admired her, and wanted to read more. (the rest of the critique might
                            expand on other craft elements) The story looks ready to submit. What are your plans for it?
                            A publisher that comes to mind is Z.
                        </p>
                        <h4>Constructive feedback</h4>
                        <p><b>No:</b> I didn't like the main character, the pace was too slow, and I didn't like the plot.<br/>
                            <i> If you were the writer receiving this, what overall conclusion would you draw about your
                                work?</i><br/>
                        </p><p>
                            <b>Better:</b> After reading these pages, I'd love to hear more about your main character Beverly.
                            In the opener, when she stole from Sally and Sally didn't realize it, I found myself rooting
                            for Sally and hoping Beverly would get caught. I think it might help to give us a sense of
                            why Beverly did this and how she feels about it. I'm also curious about the other six
                            characters that were introduced. It felt like the pace slowed down as each character was
                            described and I wondered if all of these characters needed to be put in the first two
                            chapters. What role will each of these characters play? Could you tell me more about what
                            happens later in the story and what your goal was by opening here?<br/>
                            <i> As the recipient, what overall conclusion would you draw about your work?</i>
                        </p><br/><br/><br/>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default CritiqueGuidelines;
