import React from 'react';
import {
    Col,
    Container,
    Row,
  } from "react-bootstrap";

import './Resources.scss';


const OnePageSynopsis = () => {
    return (
        <Container className="container ink-resources__about-view">
            <Row>
                <Col md={{span: 9, offset: 1}}>
                    <Row>
                        <h1>Writing a One-page Synopsis</h1>
                        <p><i>
                            "Capture the heart of your story, not its every nuance. Look for spots to add voice and flavor, giving the reader a feel for your narrative style within the book.
                            Remember overly detailed synopses only lead to questions.
                            Your goal is to keep your readers on the edge of their seats, not scratching their heads in puzzlement."
                        </i></p>
                        <p className='offset-9'>-Ammi-Joan Paquette</p>

                        <p>A one-page synopsis tells your story in ultra-short form. You'll share your main character's journey and the events of their story, from beginning to end.</p>
                        <p>Because the synopsis is short, stick to the plot events key to your character's journey. Remember that the synopsis is not a plot summary. Your character's internal journey should drive the plot events in your story, and this should come through in the synopsis.</p>

                        <h2>Remember:</h2>
                        <ul>
                            <li>Don't include backstory or subplots unless they are CRUCIAL to the MC's central journey.</li>
                            <li>Tell the ending! The whole purpose of a synopsis is to show that you can tell a story from beginning to end. Agents/editors want to know your story's resolution.</li>
                            <li>For a one-page synopsis, name only three characters - usually the protagonist, the antagonist and the side-kick/love interest. Other characters should be referred to by their individual role - e.g. the teacher, the father, the old man, the priest.</li>
                        </ul>

                        <h2>Format</h2>
                        <ul>
                            <li>A one-page synopsis, single-spaced, will be about 500 words.</li>
                            <li>Synopses are typically single spaced, with either a tab indentation or a single space to mark a new paragraph.</li>
                            <li>Use a typical font (e.g. Times new Roman). Synopses are written in third person present tense, no matter what tense your novel is written in.</li>
                        </ul>

                        <h2>How to use this Template</h2>
                        <p>This template is based on the plot beats found in the Hero's Journey. Use it to ensure you are covering the essential points of your story's external plot and internal arc.</p>
                        <p>For each beat below, write a short summary of what happens in your story. Then, join them together. This is your rough synopsis. If any additional details or events are needed to clarify the story arc, add them. Finally, cut unnecessary words/phrases, rework sentence structure as needed, and add transitions (e.g. meanwhile, later, soon, etc) so your synopsis flows.</p>

                        <h4>Synopsis Template</h4>
                        <p>
                            Below, we have provided ten essential plot points/story beats and have used The Wizard of Oz as an example of a one page synopsis break down.
                            The template is adapted from <a href="http://www.publishingcrawl.com/2012/04/17/how-to-write-a-1-page-synopsis/">Susan Dennard's guide at PubCrawl</a>
                            and a study of Save the Cat Writes a Novel. The case study example has been adapted and modified from a synopsis study
                            <a href="https://www.slideshare.net/chandan.garhwa">originally posted on Slideshare by Chandan Kumar</a>.
                        </p>

                        <h2>1. Opening image and protagonist intro.</h2>
                        <p>This sets the stage for the story to come: the where, the who, the mood, and the tone. Give one or two describing words or phrases to show us your MC and tell us what he/she wants.</p>
                        <p><i>--When a huge tornado hits Kansas, it uproots a house and carries farm girl Dorothy Gale and her dog, Toto, away.  A dreamy orphan who has always longed for escape and adventure, Dorothy suddenly finds herself in a magical land called Oz.</i></p>

                        <h2>2. Catalyst/Inciting incident</h2>
                        <p>An event/decision/change which causes the MC to take initial action, ultimately, driving the story in a new direction.</p>
                        <p><i>--When she steps outside, Dorothy realises that the tornado has dropped the house on a wicked witch.  Dorothy is lauded as a hero and given magical ruby slippers.</i></p>

                        <h2>3. Plot point 1</h2>
                        <p>This is the action taken (related to the catalyst) that changes the story's direction. Once your MC crosses this line, there is no going back. </p>
                        <p><i>--But, realising she is now trapped in Oz, all Dorothy wants is to find her way home to her aunt and uncle. So she sets out with Toto along a yellow brick road, to find a wizard who is her only hope of getting home. </i></p>

                        <h2>4. Conflicts & character encounters</h2>
                        <p>On this new journey, your protagonist meets new people, experiences new things, but also, the antagonist/villain rears their ugly head!</p>
                        <p><i>--Soon she is befriended by a tin man, a scarecrow and a cowardly lion who respectively lack a heart, a brain and courage. The three agree to accompany Dorothy in hopes that the wizard might also grant them what they each desire. On the journey, the friends face many obstacles - including the Wicked Witch of the West who is determined to avenge her dead sister.</i></p>

                        <h2>5. Midpoint - The stakes are raised!</h2>
                        <p>Everything has been going pretty well, but then…something occurs which causes the MC to make a 180 degree turn in either direction, emotion or something else. The stakes are raised! Once again, when she crosses this line there's no going back.</p>
                        <p><i>--When they reach the Emerald City, the four friends present their requests to the wizard, who appears as a terrifying, disembodied head of smoke.</i></p>

                        <h2>6. Winning seems imminent, but… the bad guys are closing in.</h2>
                        <p>The MC  assumes she has succeeded, but then the antagonist suddenly defeats her and...</p>
                        <p><i>Dorothy stands up to him, but  he will only grant their requests if Dorothy brings him the broomstick of the wicked witch of the West.</i></p>

                        <h2>7. Dark night of the soul…</h2>
                        <p>The MC believes she has failed, and must battle through the blackness of despair to find the strength for a final battle.</p>
                        <p><i>--In order to get the broomstick, the friends will have to kill the witch, but that seems impossible. Dorothy fears she will never make it home to Kansas. When her three friends see her despair, they rise to the occasion and persuade her that they will get the broomstick or die trying!</i></p>

                        <h2>8. Climax</h2>
                        <p>This is the moment we've all been waiting for! The MC rises up and faces her problem head on. What happens here, between your MC and the antagonist?</p>
                        <p><i>--On the way to the witch's castle, the witch traps Dorothy and threatens to drown Toto, so in desperation Dorothy agrees to hand over the slippers, but the witch can't remove them without killing Dorothy. Toto escapes and leads Dorothy's companions to the castle. After overpowering some of the witches guards (and thereby proving that they do in fact have brains, heart and courage), they free Dorothy. During the battle, the witch sets the scarecrows arm ablaze. Dorothy throws water on her friend and accidentally splashes the witch, causing her to melt.</i></p>

                        <h2>9. Resolution</h2>
                        <p>Does everything work out? Does your MC live happily ever after? This is where you tie up all your  loose ends.</p>
                        <p><i>--The four friends return to the Emerald City in triumph, but the wizard still won't give them their wishes - he can't because he's not actually a wizard, but just a man. He explains that Dorothy's friends already possess everything they had been seeking all along and agrees to take Dorothy home in a hot air balloon. Suddenly at the last minute, Toto jumps out of the basket and Dorothy goes after him. The hot air balloon and the wizard fly away without them. Dorothy is devastated and the power of her desire, plus the power of her slippers sends her home to Kansas.</i></p>

                        <h2>10. Final image</h2>
                        <p>The final image is proof that change has occurred. Your chance to show us what your hero looks like after their epic transformative journey is complete.</p>
                        <p><i>--Dorothy wakes up in her bed in Kansas surrounded by family and friends and tells them of her journey and that she never wants to leave again, because - there's no place like home.</i></p>

                        <h4>Now we put it all together…</h4>
                        <p>When a huge tornado hits Kansas, it uproots a house and carries farm girl, Dorothy Gale, and her dog, Toto, away. A dreamy orphan who has always longed for escape and adventure - Dorothy suddenly finds herself in a magical land called Oz. She is lauded as a hero and given magical ruby slippers. When she steps outside, Dorothy realises that the tornado has dropped her house on a wicked witch.</p>
                        <p>Realising she is now trapped in Oz, all Dorothy wants is to find her way home to her aunt and uncle. She sets out with Toto along a yellow brick road, to find a wizard who is her only hope of getting home.</p>
                        <p>Soon, she is befriended by a tin man, a scarecrow and a cowardly lion who respectively lack a heart, a brain and courage. The three agree to accompany Dorothy in hopes that the wizard might also grant them what they each desire.</p>
                        <p>On the journey, the friends face many obstacles - including the Wicked Witch of the West who is determined to avenge her dead sister.</p>
                        <p>When they reach the Emerald City, the four friends present their requests to the wizard who appears as a disembodied head of smoke. Dorothy stands up to him, but he will only grant their requests if Dorothy brings him the broomstick of the Wicked Witch of the West. In order to get the broomstick, the friends will have to kill the witch, but that seems impossible. Dorothy fears that she will never make it home to Kansas. When her three friends see her despair, they rise to the occasion and persuade her that they will get the broomstick or die trying!</p>
                        <p>On the way to the witches castle, the witch traps Dorothy and threatens to drown Toto, so in desperation Dorothy agrees to hand over the slippers, but the witch can't remove them without killing her first. Toto escapes and leads Dorothy's companions to the castle. After overpowering  the witches guards (and thereby proving that they do in fact have brains, heart and courage), they free Dorothy. During the battle, the witch sets the scarecrows arm ablaze. Dorothy throws water on her friend and accidentally splashes the witch, causing her to melt!</p>
                        <p>The four friends return to the Emerald City in triumph, but the wizard still won't give them their wishes - he can't, because he's not actually a wizard, but just a man! He explains that Dorothy's friends already possess everything they had been seeking all along and agrees to take Dorothy home in a hot air balloon. Suddenly, at the last minute, Toto jumps out of the basket and Dorothy goes after him. The hot air balloon and the wizard fly away without them. Dorothy is devastated and the power of her desire, plus the power of her slippers sends her home to Kansas.</p>
                        <p>Dorothy wakes up in her bed in Kansas surrounded by family and friends and tells them of her journey and that she never wants to leave again, because there's no place like home. </p>

                        <h4>Works Cited</h4>
                        <p>
                            Dennard, Susan. "How to Write a 1-Page Synopsis." <i>PubCrawl</i>,
                            <a href="http://www.publishingcrawl.com/2012/04/17/how-to-write-a-1-page-synopsis/"> www.publishingcrawl.com/2012/04/17/how-to-write-a-1-page-synopsis</a>.
                            Accessed 1 July 2020.
                        </p>
                        <p>
                            Kumar, Chandan. "How to write a synopsis - Wizard of Oz." <i>Slideshare</i>, 
                            <a href="https://www.slideshare.net/ChandanKumar70/how-to-write-a-synopsis-14084506"> www.slideshare.net/ChandanKumar70/how-to-write-a-synopsis-14084506</a>.
                            Accessed 1 July 2020.
                        </p>
                        <p>
                            Brant, Marilyn. "The Hitchhiker's Guide to the Galaxy novel beat sheet." <i>Savethecat.com</i> 
                            <a href="https://savethecat.com/beat-sheets/the-hitchhikers-guide-to-the-galaxy-novel-beat-sheet"> savethecat.com/beat-sheets/the-hitchhikers-guide-to-the-galaxy-novel-beat-sheet</a>.
                            Accessed 1 July 2020.
                        </p>
                        <p>Brody, Jessica. Save the Cat Writes a Novel. New York. Ten Speed Press. 2018.</p>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default OnePageSynopsis;
