import React, { useContext } from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import AuthenticationContext from "contexts/AuthenticationContext";

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
}

const ProtectedRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const context = useContext(AuthenticationContext);
  const search = useLocation().search;
  let token = new URLSearchParams(search).get("token");

  // If token is not in the url look in the local storage (we want to hide the token from the url)
  if (!token) {
    token = localStorage.getItem("token");
  }

  // If we have a valid token we extend it for 1 second
  if (token != null) {
    localStorage.setItem("token", token);
  }

  if (token) {
    context.token = token;
  }

  const isAuthenticated = !!token;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} {...rest} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
