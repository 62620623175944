import React, { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";

import './Faq.scss';


const Faq = () => {
    const [openIndex, setOpenIndex] = useState(-1);

    const handleClick = (index: any) => {
        setOpenIndex(openIndex === index ? -1 : index);
    };

    return (
        <Container className="ink-faq">
            <Col lg={11} xs={12} md={{ span: 10, offset: 1 }}>
                <h1>Frequently Asked Questions</h1>

                <h2>Privacy</h2>
                <h3 onClick={() => handleClick(1)}>- What parts of Inked Voices are public and what parts are private?</h3>
                {openIndex === 1 &&
                    <Row className="answer">
                        <p>Here's what's public: the homepage, terms &amp; conditions, code of conduct, and links off of the
                            site homepage. Anything after you login is not public. </p>
                        <ol>
                            <li>Your dashboard is completely private to you and you alone</li>
                            <li>Your groups page is completely private to members of your group</li>
                            <li>Your individual public profile is private to logged in Inked Voices members</li>
                            <li>Your group public profile is private to logged in Inked Voices members</li>
                        </ol>
                    </Row>
                }

                <h3 onClick={() => handleClick(2)}>- Do I need to use my name on the site?</h3>
                {openIndex === 2 && 
                    <Row className="answer">
                        <p>Each member of the site has a pen name. A pen name is the name you intend to publish under. For
                            most, it's your first and last name. </p>
                        <p>Some folks use their initials as a first name instead and some people change their last name.
                            These can be ways for you to comfortably interact within a group if you have concerns about
                            using your actual name.</p>
                        <p>Whatever you decide, you will need to use a plausible first and last name on the site.
                            CoolWriter64 or Mickey Mouse won't work. The point of Inked Voices is not to be anonymous.
                            Instead, the idea is to develop working relationships with other writers. And for that, we need
                            to be able to call each other by names.</p>
                    </Row>
                }

                <h2>Protection of works</h2>
                <h3 onClick={() => handleClick(3)}>- What does Inked Voices do to protect the work I've shared?</h3>
                {openIndex === 3 && 
                    <Row className="answer">
                        <h6>1. We foster small groups with known members</h6>
                        <p>Like in person groups, Inked Voices groups are generally small and the members are known. You
                            each have individual profiles and you work together over time and on a consistent basis. We
                            address one another by name. </p>
                        <p>While there is still the possibility of “stealing” someone's work, the risk is similar to that
                            you take any time you share your work. Unfortunately, you don't get the benefits of other
                            people's feedback without sharing with them first.</p>

                        <h6>2. Group privacy and individual control over submitted works</h6>
                        <p>All groups are invitation-based and each has their own private, shared group page for critiques
                            and discussion. You control what work you submit to a group and when. For example, you may
                            upload documents to your dashboard and never share them with anyone. Or you may upload them
                            today and then decide to submit in 3 weeks. Only at that point will others be able to see your
                            work.</p>
                        <p>In addition, your critiqued submission will automatically leave the group's page at the end of
                            its critique and review cycles (it will still be available to you on your dashboard). And you
                            can choose to remove your work from the group at any time. </p>

                        <h6>3. Connections are encrypted with HTTPS</h6>
                        <p>Inked Voices is using HTTPS to protect the site from people who could eavesdrop on less secure
                            connections. Here's an <a href="http://mashable.com/2011/05/31/https-web-security/">article</a>
                            about HTTPS if you are interested.</p>
                    </Row>
                }

                <h3 onClick={() => handleClick(4)}>- Copyright</h3>
                {openIndex === 4 && 
                    <Row className="answer">
                        <p>Copyright is a legal right given to the owner of a work to distribute, commercialize or use that
                            work for a set period of time. </p>


                        <h6>How do you get a copyright?</h6>
                        <p>Once you have committed an idea to paper, you have the automatic protection of copyright. As soon
                            as the work is fixed in writing or recording, copyright protection applies. <b>So that means
                                that your work on Inked Voices is copyrighted. You've fixed it in writing and then submitted
                                it to your group.</b></p>
                        <p>Copyright law has an interesting history that you can read more about <a
                                href="http://en.wikipedia.org/wiki/Copyright">here</a>. But here is a quick summary. In
                            1989, the United States adopted an international agreement called the Berne Convention. This
                            agreement is what gives us automatic copyright protection—without having to register for a
                            copyright or use a copyright symbol ©. 166 countries plus the Holy See are party to the Berne
                            Convention. </p>
                        <p>Today, registering for a copyright is optional. Registration allows owners additional damages in
                            the event of a lawsuit. Using the copyright symbol © is also optional. But it can help readers
                            understand that material is copyrighted and prevent unintended misuse. </p>

                        <h6>Who is the owner of the work? How long does copyright last?</h6>
                        <p>The creator of the work is typically the copyright owner. In that case, the copyright lasts for
                            the creator's lifetime plus 70 years after his or her death.</p>
                        <p>Some works are created as a “work made for hire”. These are works that are created under the
                            scope of your employment. Here, the employer is the holder of the copyright. The copyright for
                            this kind of work is the shorter of 95 years from publication or 120 years from creation.</p>

                        <h6>Learn more about copyrights</h6>
                        <p>Copyright.gov offers resources on copyrights. Especially helpful was this document explaining the
                            basics of copyright. <br/>
                            <a href="http://www.copyright.gov/circs/circ01.pdf">http://www.copyright.gov/circs/circ01.pdf</a>
                        </p>
                        <p>Wikipedia offers a simple history and information about copyright. <br/>
                            <a href="http://en.wikipedia.org/wiki/Copyright">http://en.wikipedia.org/wiki/Copyright</a><br/>
                            Check out the Berne Convention to learn more about the agreement. <br/>
                            <a href="http://en.wikipedia.org/wiki/Berne_Convention">http://en.wikipedia.org/wiki/Berne_Convention</a>
                        </p>
                    </Row>
                }

                <h2>Finding, creating and inviting people to Groups</h2>
                <h3 onClick={() => handleClick(5)}>- How do I find a group?</h3>
                {openIndex === 5 && 
                    <Row className="answer">
                        <p>There are a few ways to find a group:</p>

                        <h6>1. Get started with a group you belong to outside of Inked Voices</h6>

                        <p>To get started with your current online or in-person group, first everyone needs to join the
                            site. Then, you should decide who will take on the role of group facilitator. That person should
                            create a new group and then invite your fellow members to join.</p>

                        <h6>2. Find an existing Inked Voices group</h6>
                        <p>Once logged in, go to Groups on the navigation. Input your search term. You can search by genre,
                            organization/association or any other keyword that's important to you. </p>
                        <p>Example searches</p>
                        <ul>
                            <li>Organization: NaNoWriMo, SCBWI, SFWA</li>
                            <li>Genre: New Adult, YA, fantasy, non-fiction, blog</li>
                            <li>Education / experience: MFA, Gotham Writers' Workshop</li>
                        </ul>

                        <p>Or click “show all groups” to see all of the groups. This can be valuable if you are interested
                            in a mix of genres. </p>
                        <p>Your search results will include groups that match your search. You can click on the groups to
                            view their profiles. These profiles include information that will likely be important to you:
                            group settings on sexual content, violent content, critique ratio, deadline windows. Active
                            groups will likely have a submission schedule that you can see to get an idea of how often
                            people submit work. You can also see the public profiles of each member. You will not be able to
                            see any group submissions.</p>
                        <p>If you are interested in joining, send a note to the group facilitator. You're also welcome to
                            ask the group facilitator a question. The group facilitator can invite you to the group. You
                            will receive an email invitation and an invitation on your dashboard. Accepting the invitation
                            will join you to the group. You'll now see the group on your Dashboard.</p>

                        <h6>3. Look for other Inked Voices members with similar interests and see if they'd like to create a
                            group with you</h6>

                        <p>Follow the same steps as you would when looking for existing groups, except be sure to check the
                            box “include individuals in search results.” You will be able to see matching individuals. You
                            can look at their profiles and contact them if you think it's a match. From there, you can
                            decide who will be the group facilitator. That person should set up the group and invite the
                            other members.</p>


                        <h6>4. Set up a new group and let people find you (or you can search for them and invite them)</h6>
                        <p>If you're interested in taking on the role of group facilitator for a group, consider starting a
                            new group. When you create a new group, be sure to check the box saying your group is accepting
                            new members during set up. </p>

                        <p>When you create a new group, people can find it when they search. You can also create the group
                            and then reach out to individuals to see if they would like to join.</p>
                    </Row>
                }

                <h3 onClick={() => handleClick(6)}>- Creating a new group</h3>
                {openIndex === 6 && 
                    <Row className="answer">
                        <h6>Should I start a group? </h6>
                        <p>Are you interested in creating a group focused around a particular genre, interest or mix of
                            genres/ interests? Are you interested in and willing to take on the mantle of being a group
                            facilitator? If so, yes, please go for it!</p>

                        <h6>What's required of a group facilitator?</h6>
                        <p>The group facilitator has the following tasks: creating the group and editing the group profile
                            as needed and inviting new members.</p>
                        <p>That is a simplistic answer, however. A strong group facilitator is one who helps the group set
                            joint expectations of what the group will do. </p>
                        <p>While the person is not a dictator, he or she should be willing to start discussions like: </p>

                        <ul>
                            <li>What are our goals as a group?</li>
                            <li>How often do we want to submit?</li>
                            <li>Do we want to take on more writers, and, if so, is there a particular type of person who
                                would round out our experience?
                            </li>
                            <li>What level of sexual content/ violence are we willing to critique?</li>
                            <li>Should we adjust our critique settings? E.g. Would we do better with more or less time for
                                critique? Should we lengthen the review period so we can refer back to previous chapters?
                            </li>
                        </ul>

                        <p>In addition, the group facilitator will ideally be willing to nudge the group if folks start to
                            miss on the submission schedule. Any member of the group should also feel free to step up and
                            suggest group expectations.</p>
                        <p>Just like with in-person groups, groups with experienced leaders, or those who make up for
                            experience with their energy and enthusiasm, tend to do well. </p>

                        <h6>How to create a new group</h6>
                        <p>Go to Groups on the navigation bar and selecting Create a Group. </p>
                        <p>The screen includes questions on group description, goals, genres, settings for sexual and
                            violent content. There are default group settings for length of time to critique work and number
                            of credits needed to submit. You can change these as your group desires. </p>
                        <p>Once you Save your group, it will appear in your Dashboard. You'll also see it under Groups in
                            your navigation. Now you can click on the group to go to it and see its page. You can edit your
                            group settings by going to the blue arrow in the left-hand column of your group's page and
                            selecting Edit Group.</p>
                    </Row>
                }

                <h3 onClick={() => handleClick(7)}>- Is there a recommended group size?</h3>
                {openIndex === 7 && 
                    <Row className="answer">
                        <p>When you are considering the right group size, consider the following:</p>
                        <ul>
                            <li>How long are the manuscript submissions to the group? For example, picture books are short,
                                novel chapter(s) are long.
                            </li>
                            <li>How much time do you have available to give critique?</li>
                            <li>How much material can you realistically and consistently produce for submission?</li>
                            <li>Do the group members want to critique all submissions or a portion?</li>
                            <li>How many critiques do you hope to receive on your submissions?</li>
                        </ul>
                        <p>Groups as small as pairs can work as long as there is a high level of commitment. I would
                            recommend starting with a group of 4-7. Once the group feels things out it can always add more
                            members. You do want to have a core of folks to actually get the group off the ground.</p>
                    </Row>
                }

                <h3 onClick={() => handleClick(8)}>- Inviting people to a group </h3>
                {openIndex === 8 && 
                    <Row className="answer">
                        <p>There are two ways to invite people to your group.</p>
                        <ol>
                            <li>By email address: Go to your Group page, now accessible from your Dashboard or the Groups
                                navigation. In the left column under your group's image, you will see a blue arrow. Go to
                                Invite Writers. Put in your group members' emails, separated by a comma. They will get an
                                email invite and they will also see the invite on their own dashboards. Once they accept,
                                they are part of the group.
                            </li>
                            <li>By profile invite: Go to the profile of the person you'd like to invite. Click the Invite to
                                Group button and select the group you'd like to invite the person to. The person will
                                receive an email invitation as well as an invitation on their dashboard. When the person
                                accepts using either method, he or she will be added to the group and will be able to see
                                the group on their Dashboard.
                            </li>
                        </ol>
                    </Row>
                }

                <h2>Giving and getting critique</h2>
                <h3 onClick={() => handleClick(9)}>- How do I submit a work to my group?</h3>
                {openIndex === 9 && 
                    <Row className="answer">
                        <p>First you will create a project, then add a document or documents to it. To submit a document for
                            critique, mouse over the document in your Projects panel. You'll see a menu of available
                            options, including “Submit” (unless you've submitted the document to all possible groups). When
                            you click submit, you will select which of your groups to submit the work to (if you belong to
                            more than one). </p>
                    </Row>
                }

                <h3 onClick={() => handleClick(10)}>- How does the credits system work?</h3>
                {openIndex === 10 && 
                    <Row className="answer">
                        <p>It takes X credits to submit your work to a group. X is a number set by your group. For every
                            manuscript you critique, you will earn a credit. </p>
                        <h6>Do you have a recommended “X”?</h6>
                        <p>The number of credits you should depends on the number of people in your group, what percentage
                            of people you expect to critique a work (everybody? half? most?) and how long you've known your
                            group members. </p>
                        <p>Ultimately, the credits system is there to prevent the situation of any one person only
                            submitting work and not critiquing. In practice, this is unusual.</p>
                        <p>The default critique ratio (number of credits needed to submit) is 3. If you have a group of 7 or
                            more and you are just starting out, you may consider bumping it up a little. If you have a group
                            of 4, you may consider shifting it down so that your group isn't reliant on 100% participation
                            all the time. And if you don't care about the credits, you can even set it to 0.</p>
                        <p>The group facilitator can change the critique ratio—so it's okay to go with something and adjust
                            as needed!</p>
                    </Row>
                }

                <h3 onClick={() => handleClick(11)}>- Where can I see the critiques I receive?</h3>
                {openIndex === 11 && 
                    <Row className="answer">
                        <p>Two answers to this one. You can always see critiques for your submissions on your Dashboard in
                            your Projects panel. They will be nested under the document and have a “Critique” label. </p>
                        <p>When your document is submitted to your group, you will also see it on your group's page in the
                            critique panel. It will be viewable on the Critique tab while it is up for critique and on the
                            Review tab after the critique period closes.</p>
                    </Row>
                }

                <h3 onClick={() => handleClick(12)}>- Where do I give critique?</h3>
                {openIndex === 12 &&
                    <Row className="answer">
                        <p>Go to the critique panel on your group's page. Click the document to give a critique.</p>
                    </Row>
                }

                <h3 onClick={() => handleClick(13)}>- What do the statuses mean on the critique panel?</h3>
                {openIndex === 13 &&
                    <Row className="answer">
                        <p>The statuses on your group's critique panel are specific to you. You'll see one of the
                            following:</p>
                        <ul>
                            <li>Submitted: for works you've submitted</li>
                            <li>Read critique: for works you've submitted that have received critiques</li>
                            <li>Give critique: for group members' works you have not begun to critique</li>
                            <li>Finish critique: for works you have started to critique, but you have not submitted the
                                critique
                            </li>
                            <li>Done: for works you've critiqued and submitted to the group</li>
                        </ul>
                    </Row>
                }

                <h3 onClick={() => handleClick(14)}>- How do I give critique?</h3>
                {openIndex === 14 &&
                    <Row className="answer">
                        <p>You have two places to give critique: within the document through inline documents, and at the
                            end of the document in a general comments area.</p>
                        <p>Make inline comments in a document by selecting text. This will bring up a comment bubble for you
                            to enter comments in. You can go back and edit your comments or delete them. Inline comments
                            save automatically, so if you accidentally leave the page, your work isn't lost.</p>
                        <p>To finish your critique later, click the Save and Finish later button. To submit it, click
                            Submit. Be aware that once you submit, you won't be able to make adjustments to your
                            critique. </p>
                    </Row>
                }

                <h3 onClick={() => handleClick(15)}>- How do I see my group member's comments while I'm critiquing?</h3>
                {openIndex === 15 && 
                    <Row className="answer">
                        <p>In the upper right of the critique interface, you will see a View All link. Click it to open the
                            document in a new window with all current critiques included.</p>
                        <p>Why did we make this decision? Many people like to critique a clean copy to retain their
                            objectivity. But some like to build on the comments of others, or want to avoid making the same
                            comments as others. It's as close as we could come to the best of both worlds.</p>
                    </Row>
                }

                <h3 onClick={() => handleClick(16)}>- What is the critique window?</h3>
                {openIndex === 16 && 
                    <Row className="answer">
                        <p>The critique period is the amount of time a submission is available to the group for critique.
                            The group facilitator can adjust this setting by going to Edit Group.</p>
                    </Row>
                }

                <h3 onClick={() => handleClick(17)}>- What is the review window?</h3>
                {openIndex === 17 && 
                    <Row className="answer">
                        <p>The review window is the amount of time a submission is available to the group for review. Review
                            is the time when the author can ask for clarifications on comments from the group or
                            suggestions. Anyone in the group can start a Q&amp;A discussion or respond to one. The group
                            facilitator can adjust this setting by going to Edit Group.</p>
                        <p>Note: the review period is not a time for defending your work. We all get negative feedback or
                            suggestions for improvement. Sometimes it's harder to hear than others. Remember that it's your
                            choice as an author whether you want to actually take the feedback.</p>
                    </Row>
                }

                <h2>Changing group memberships</h2>
                <h3 onClick={() => handleClick(18)}>- Leaving a group</h3>
                {openIndex === 18 && 
                    <Row className="answer">
                        <p>If you'd like to leave your group, be sure to first email the group (if you've been with them for
                            some time) or, always, the group facilitator. Let them know what's going on so the group doesn't
                            think you poofed into thin air. Maybe it's not a good fit, maybe you wanted to submit more (or
                            less) frequently. Whatever the reason, be sure to communicate it.</p>
                        <p>How to leave…Go to your group's page. You will see a blue arrow in the left column. Open the menu
                            and select Leave Group. This will end your membership in the group. Your submissions and
                            critiques from the group will remain visible to you on your Dashboard. All of your previous
                            discussion comments and critique comments given will remain visible to your former group
                            members.</p>
                    </Row>
                }

                <h3 onClick={() => handleClick(19)}>- Transferring group leadership</h3>
                {openIndex === 19 && 
                    <Row className="answer">
                        <p>To change the group's facilitator, contact us and we will help you make the change. We will just
                            need an email from you saying you'd like the change and from the new facilitator giving the
                            OK.</p>
                        <p>Similar to Hogwarts castle, help with the site is always available to you if you ask. Just
                            contact me.</p>
                    </Row>
                }
            </Col>
        </Container>
    );
}

export default Faq;
