import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setFilterValues } from "redux/filterValuesReducer";
import AuthenticationContext from "contexts/AuthenticationContext";
import sendRequest from "services/dataService";
import React, { useContext, useEffect, useState } from "react";
import "./FiltersList.scss";
import { buildFilterKey } from "pages/events/utils";

const FilterSet = (props: any) => {
  const authenticationContext = useContext(AuthenticationContext);
  const { id, title, path } = props;
  const values = useSelector((state: any) => state.filterValues);
  const [filters, setFilters] = useState<any>(null);
  const dispatch = useDispatch();

  const fetchData = async () => {
    let url = path;
    const response = await sendRequest({
      url: url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setFilters(response.results);
    response.results.map((filter: any) => {
      const key = buildFilterKey(id, filter.name);
      if (values[key] == null) {
        setFilterValues({ key: key, value: true });
      }
      return null;
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleItem = (name: string) => {
    const key = buildFilterKey(id, name);
    const currentValue = values && values[key];
    dispatch(
      setFilterValues({
        key: key,
        value: !currentValue,
      })
    );
  };

  return (
    <div className="filters-list">
      <h4>{title}</h4>
      <p>
        <span className="button-list">
          {filters?.map((f: any) => (
            <Button
              className={
                "purple " +
                (!(values && values[buildFilterKey(id, f.name)]) && "inactive")
              }
              onClick={(e) => toggleItem(f.name)}
            >
              {f.display_name}
            </Button>
          ))}
        </span>
      </p>
    </div>
  );
};

export default FilterSet;
