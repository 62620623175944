import React from 'react';
import {
    Col,
    Container,
    Row,
  } from "react-bootstrap";

import './Resources.scss';


const HowTos = () => {
    return (
        <Container className="container about-view">
            <Row>
                <Col md={{span: 9, offset: 1}}>
                    <Row>
                        <h1 className='text-center'>Inked Voices How To's For Workshops</h1>

                        <a href="#adjustNotifications" style={howToStyle}>Adjust Notifications</a>
                        <a href="#submitYourWork" style={howToStyle}>Submit Your Work</a>
                        <a href="#makeInline" style={howToStyle}>Make Inline and Overall Comments</a>
                        <a href="#RDFeedback" style={howToStyle}>Review/Download Feedback</a>
                        <a href="#Troubleshooting" style={howToStyle}>Troubleshooting </a>

                        {/* How to Adjust Notifications */}
                        <h2 id="adjustNotifications">How to Adjust Notifications</h2>
                        <p>
                            To change your notifications or receive them in a daily digest, go to the settings icon to the right of the group name.
                            Select the <b>Notifications</b> option, make changes and <b>Save</b>. Your changes apply to this workspace only.
                        </p>
                        <p><i>Tip: Notifications are heavier at a workshop's start due to intros and submissions; this usually subsides.</i></p>

                        {/* How to submit */}
                        <h2 id="submitYourWork">How to submit</h2>
                        <p><a href="https://vimeo.com/562822043/633501ef01" target="_blank" rel="noopener noreferrer">Video Version!</a></p>

                        <p>Method 1: “Quick Submission”. This method saves your document in a folder on your Dashboard titled “Quick Submissions.”</p>
                        <ul>
                            <li>Go to the group workspace. Click <b>Quick Submission</b> in the upper right corner.</li>
                            <li>Check the box to add submission notes. Use submission notes to share your pitch or story summary, or to ask any questions to the group.</li>
                            <p><i>Author-illustrator Tip:  if you are submitting a project dummy, tell us in your submission note to "See 'Files' in the group workspace for my dummy"  or similar. After you submit, post your dummy to the Files tab in the group workspace.</i></p>
                            <li><b>Confirm Selection.</b> Your work posts to the group workspace under the Critique tab. Your work is always at the top, and says “Me” as the author. It will not offer you the “Give Critique” option because you are not expected to critique your own work.</li>
                        </ul>

                        <p>
                            Method 2: from your Dashboard. This method saves your document in a folder on your Dashboard  which you create and name.
                            Use this method if you like  to keep your critiques organized by project.
                        </p>
                        <p>1. Add the document to your Dashboard.</p>
                        <ul>
                            <li>Go to your <a href='https://www.inkedvoices.com/dashboard/' target="_blank" rel="noopener noreferrer">Dashboard </a></li>
                            <li>Go to Projects and create a new project folder.</li>
                            <li>Add a document to the project by uploading a .docx or copy/pasting. Do not use a .pdf.</li>
                            <i>Tip: once uploaded, you can click the doc to see what it looks like and check formatting. Use the Edit function if needed.</i>
                        </ul>

                        <p>2. Submit your document.</p>
                        <ul>
                            <li>Click <b>Options</b> to the right of the document name. Select <b>Submit</b>. </li>
                            <li>Select your workshop group.</li>
                            <li>Check the box to add submission notes. Use submission notes to share your pitch or story summary, or to ask any questions to the group.</li>
                            <p><i>Author-illustrator Tip:  if you are submitting a project dummy, tell us in your submission note to "See 'Files' in the group workspace for my dummy"  or similar. After you submit, post your dummy to the Files tab in the group workspace.</i></p>
                            <li><b>Confirm Selection.</b> Your work posts to the group workspace under the Critique tab. Your work is always at the top, and says “Me” as the author. It will not offer you the “Give Critique” option because you are not expected to critique your own work.</li>
                        </ul>

                        {/* How to Make Inline and Overall Critique Notes */}
                        <h2 id="makeInline">How to Make Inline and Overall Critique Notes</h2>
                        <p>
                            You may make inline and overall comments on workshop submissions.
                            No one can see your feedback until you officially click the <b>Submit</b> button,
                            so you are welcome to complete a critique over multiple sessions or write it and check it again before submitting.
                            Your feedback auto-saves as you work, and we also have a <b>Save and Finish Later</b> manual-save.
                        </p>

                        <p>
                            Once you begin adding comments, other participants will see an <b>eyeglasses symbol</b> next to the submission.
                            This tells them a critique is in process and isn't yet submitted.
                        </p>

                        <p>
                            Once a critique is submitted, an <b>Edit</b> link appears to the right of the submission name.
                            Clicking <b>Edit</b> re-opens your critique - essentially “un-submitting” it.
                            Be sure to <b>Submit</b> again, even if you decide not to make changes.
                        </p>

                        <h3>How to Make Inline Comments</h3>
                        <p>Computer</p>
                        <p>
                            Take your mouse or trackpad and select a piece of text (highlight it).
                            You will see a small annotation bubble come up. Make your comment and save it.
                            Your comment will display in the margin to the right of the text.
                            Click on the comment bubble to edit it or delete it (use the edit and delete icons in the upper right corner of the comment bubble).
                        </p>

                        <p>Phone/Tablet</p>
                        <p>Select text by touching the screen. Tap the <b>Annotate</b> button in the lower right corner. Write your comments and <b>Save</b>.</p>


                        {/* Review/Download feedback */}
                        <h2 id="RDFeedback">Review/Download feedback</h2>
                        <p>From your Workshop Group</p>
                        <p>
                            Read your critiques by clicking the document name. If the critique period has passed, look for the document under the Review tab.
                            To download, click Download Critique in the upper right corner. Filter to view/download each person's notes separately, together, or in any combination.
                        </p>

                        <p>From your Dashboard</p>
                        <p>
                            Your critiqued work is also stored on the Dashboard under the Project name or Quick Submissions, depending on how you submitted.
                            <i>The critique will be here even after your workshop ends.</i>
                        </p>
                        <p>Click the <b>Critique</b> label to review feedback online. Use <b>Download Critique</b> to the right of the submission name and group to save a printable PDF.</p>


                        {/* Troubleshooting Submissions */}
                        <h2 id="Troubleshooting">Troubleshooting Submissions</h2>
                        <h3>I am trying to submit and my workshop group is not an option</h3>
                        <p>
                            The workshop group may not be an option for either of two reasons: 1) the submission is over the word count maximum; or 2) you do not have enough “credits” to submit.
                            Because our workshops do not require submission credits, the usual answer is that the submission is over the word limit. 
                        </p>
                        <p>How to resolve a word count issue:</p>
                        <ul>
                            <li>
                                First, find the document.
                                <ul>
                                    <li>
                                        Did you submit the work via your Dashboard?
                                        Go to your <a href='https://www.inkedvoices.com/dashboard/' target="_blank" rel="noopener noreferrer">Dashboard </a>
                                        and find the document under Projects. It will be under the project file you created.
                                    </li>
                                    <li>
                                        Did you submit the work via your workshop's workspace using the Quick Submission button?
                                        Go to your <a href='https://www.inkedvoices.com/dashboard/' target="_blank" rel="noopener noreferrer">Dashboard </a>
                                        and find the document under Projects. Expand the file name “Quick Submissions” and you will see your document.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Next to the document name, you will see the word count. Then, go to <b>Options</b> to the right of the document name and select <b>Edit</b>.
                                Remove words until you are under the limit and <b>Save</b>. Re-submit by going to <b>Options</b> and selecting <b>Submit</b>.
                            </li>
                            <li>
                                Alternatively, you may upload and submit a shorter document - essentially starting over.
                                If you do, be sure to use a different title. (Or delete your original document and then upload with the same title).
                            </li>
                        </ul>

                        <h3>I submitted, but I see errors or formatting issues. Can I correct this?</h3>
                        <p>
                            Yes, if you spot errors on submission day, you can make changes.
                            Keep in mind that if critiques have been started (there will be an eyeglasses symbol next to your work),
                            you should ask permission before doing this, because removing your work will remove their critique.
                            This is not usually an issue on a workshop's start date when people are submitting.
                        </p>

                        <p>How to resolve the issue:</p>
                        <ul>
                            <li>
                                Remove the document from your workshop by going to the group, and locating your submission.
                                It will be in the Critique area at the top of the page. Use the <b>Remove</b> link to the right of your document.
                                This reverses the “submit” action; your work is still on Inked Voices.
                            </li>
                            <li>
                                Then, locate the document within Inked Voices.
                                <ul>
                                    <li>
                                        Did you submit the work via your Dashboard?
                                        Go to your <a href='https://www.inkedvoices.com/dashboard/' target="_blank" rel="noopener noreferrer">Dashboard </a>
                                        and find the document under Projects. It will be under the project file you created.
                                    </li>
                                    <li>
                                        Did you submit the work via your workshop's workspace using the Quick Submission button?
                                        Go to your <a href='https://www.inkedvoices.com/dashboard/' target="_blank" rel="noopener noreferrer">Dashboard </a>
                                        and find the document under Projects. Expand the file name “Quick Submissions” and you will see your document.
                                    </li>
                                    <li>
                                        Go to <b>Options</b> to the right of the document name and select <b>Edit</b>.
                                        Make your changes and <b>Save</b>. Re-submit by going to <b>Options</b> and selecting <b>Submit</b>.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Alternatively, you may upload and submit a new document - essentially starting over.
                                If you do, be sure to use a different title. (Or delete your original document and then upload with the same title).
                            </li>
                        </ul>

                        <i>
                            Note: This option is available for correcting errors. Once your submission is up and critiques begin to come in,
                            do not remove and replace it with a revised version. This is very confusing to a group.
                            In addition, most revisions a writer will be able to accomplish in a short time will be at the line level;
                            because workshops focus on story craft, inline changes are unlikely to significantly impact reader feedback.
                        </i>

                        <h3>I forgot to add a submission note, can I add one now?</h3>
                        <p>
                            Yes, if critiques have not been started (documents with a critique in process have an eyeglasses symbol beside them).
                        </p>
                        <p>
                            If critiques have been started, then add your submission note to the discussion board. The workshop lead can pin it to the top of the board so everyone can see it.
                        </p>
                        <p>How to resolve the issue:</p>
                        <ul>
                            <li>
                                Remove the document from your workshop by going to the group, and locating your submission.
                                It will be in the Critique area at the top of the page. Use the <b>Remove</b> link to the right of your document.
                                This reverses the “submit” action; your work is still on Inked Voices.
                            </li>
                            <li>
                                Then, locate the document within Inked Voices.
                                <ul>
                                    <li>
                                        Did you submit the work via your Dashboard?
                                        Go to your <a href='https://www.inkedvoices.com/dashboard/' target="_blank" rel="noopener noreferrer">Dashboard </a>
                                        and find the document under Projects. It will be under the project file you created. 
                                    </li>
                                    <li>
                                        Did you submit the work via your workshop's workspace using the Quick Submission button?
                                        Go to your <a href='https://www.inkedvoices.com/dashboard/' target="_blank" rel="noopener noreferrer">Dashboard </a>
                                        and find the document under Projects. Expand the file name “Quick Submissions” and you will see your document.
                                    </li>
                                    <li>
                                        Go to <b>Options</b> to the right of the document name and select <b>Submit</b>. Check the box to add your submission note, then <b>Submit</b>.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

const howToStyle = {
    "margin-bottom": '1%',
 } as React.CSSProperties;

export default HowTos;
