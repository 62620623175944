import Pill from "components/pill/Pill";
import {
  displayEventDates,
  displayFullEventDatetimes,
  eventTagName,
  isResourceEvent,
} from "pages/events/utils";
import { Col, Row } from "react-bootstrap";
import ReactPlaceholder from "react-placeholder/lib";
import EventDetailImage from "../image/EventDetailImage";

const EventDetailHeader = (props: any) => {
  const event = props.event;
  const tz = new Date()
    .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "short" })
    .substring(4);
  const dt = event && displayEventDates(event);
  const fullDate = event && displayFullEventDatetimes(event);
  const h1_title = (
    <h1>
      <ReactPlaceholder type="text" rows={3} ready={event}>
        {event?.title}
      </ReactPlaceholder>
    </h1>
  );
  if (isResourceEvent(event)) {
    return (
      <Col sm={12}>
        <Pill>{eventTagName(event)}</Pill>
        {h1_title}
        <h3 className="date">
          <ReactPlaceholder type="text" rows={1} ready={event}>
            {`LIVE: ${fullDate} ${tz}`}
          </ReactPlaceholder>
        </h3>
        {event?.recurrence_text && <p>{event?.recurrence_text}</p>}
      </Col>
    );
  } else {
    return (
      <Row>
        <Col sm={3}>
          <EventDetailImage event={event} />
        </Col>
        <Col sm={9}>
          {h1_title}
          <h3 className="date">
            <ReactPlaceholder type="text" rows={1} ready={event}>
              {dt}
            </ReactPlaceholder>
          </h3>
        </Col>
      </Row>
    );
  }
};

export default EventDetailHeader;
