import { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import useFetch from "custom-hooks/useFetch";
import AuthenticationContext from "contexts/AuthenticationContext";
import {
  BaseMessage,
  BasePaginatedResponse,
  CurrentUserState,
  PaginationInfo,
} from "types";
import { useSelector } from "react-redux";
import BaseMessages, { MessagesPageOptions } from "../BaseMessages";

interface MessagePaginatedResponse extends BasePaginatedResponse {
  results: BaseMessage[];
}

export default function Inbox() {
  const history = useHistory();
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [fetchMessagesState, fetchMessages] = useFetch();
  const pageSize = 10;
  let queryParams = new URLSearchParams(useLocation().search);
  const page = queryParams.get("page");
  const search = queryParams.get("search");

  const [searchText, setSearchText] = useState<string | null>(search);

  const [messages, setMessages] = useState<BaseMessage[] | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
    current: page ? parseInt(page) : 1,
  });
  const authenticationContext = useContext(AuthenticationContext);

  const onPageChange = (page: number) => {
    setMessages(null);
    const params = new URLSearchParams(
      searchText
        ? {
            page: page.toString(),
            search: searchText,
          }
        : {
            page: page.toString(),
          }
    );
    history.replace(`/messages/inbox/?${params.toString()}`);
    setPaginationInfo({
      total: paginationInfo?.total,
      current: page,
    });
  };

  const onSearch = (search: string) => {
    setMessages(null);
    setSearchText(search);
    setPaginationInfo({
      current: 1,
    });
  };

  const reloadMessages = () => {
    if (!paginationInfo?.current) {
      return;
    }
    const params = new URLSearchParams(
      searchText
        ? {
            page: paginationInfo.current.toString(),
            search: searchText,
          }
        : {
            page: paginationInfo.current.toString(),
          }
    );
    fetchMessages({
      url: "api/v1.0/messages/inbox/?" + params.toString(),
      token: authenticationContext.token,
    }).then((res: MessagePaginatedResponse) => {
      setMessages(res.results);
      setPaginationInfo({
        current: paginationInfo?.current,
        total: Math.ceil(res.count / pageSize),
      });
    });
  };

  useEffect(() => {
    reloadMessages();
  }, [paginationInfo?.current, searchText]);

  return (
    <BaseMessages
      title="Inbox"
      page={MessagesPageOptions.Inbox}
      messages={messages}
      paginationInfo={paginationInfo}
      unreadCount={userState.data.unread_messages || 0}
      onPageChange={onPageChange}
      onSearch={onSearch}
      onDelete={() => {
        reloadMessages();
      }}
    />
  );
}
