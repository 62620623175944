import React, { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";

import './CodeOfConduct.scss';


const CodeOfConduct = () => {
    const [openIndex, setOpenIndex] = useState(-1);

    const handleClick = (index: any) => {
        setOpenIndex(openIndex === index ? -1 : index);
    };

    return (
        <Container className="ink-code-of-conduct">
            <Col xs={12} lg={{ span: 10, offset: 1 }}>
                <h1>Code of Conduct</h1>
                <p>
                    We're here to help each other do more writing and do it better. To do that, we have to be able to
                    trust one another. We have to feel comfortable sharing our writing, know that our group members will
                    give us honest feedback and agree that we will keep our work within the group.
                </p>

                <ol className="hide-numbers">
                    <li>
                        <h2>1. It's your voice.</h2>
                        <p>Be true to yourself and your voice as a writer. It's your choice whether to accept the
                            feedback you receive.</p>
                    </li>

                    <li>
                        <h2>2. Listen.</h2>
                        <p>One of the coolest things about Inked Voices is the ability to get feedback from writers with
                            different perspectives and experiences. We all have different voices. Let's see what we can
                            learn from one another.</p>
                    </li>

                    <li>
                        <h2>3. Pause.</h2>
                        <p>Seeing comments on the writing we've worked so hard on can be surprising, frustrating or
                            confusing. If you feel your cheeks flushing or body beginning to boil, take a break. Wait a
                            few hours, or even days. Then look again and ask questions if you need clarification. There
                            could be a jewel there (or maybe this time it's a rock).</p>
                    </li>

                    <li>
                        <h2>4. Be professional.</h2>
                        <p>If you're part of this community, you are likely a published author or aspire to be one. We
                            will treat each other as colleagues. In particular, focus your critique on the work, not the
                            person.</p>
                    </li>

                    <li>
                        <h2>5. Be Kind.</h2>
                        <p>This doesn't mean holding back critique. We want it, right? But remember that written words
                            can sound harsh without the facial cues and vocal tones that can soften in-person critique.
                            We must be conscientiously kind.</p>
                    </li>

                    <li>
                        <h2>6. Give actionable feedback.</h2>
                        <p>Make your feedback specific. When possible, instead of saying that something doesn't work,
                            think about what would make the piece stronger or clearer and suggest that. Perhaps the
                            author would benefit from writing the piece from a different point of view or cutting a
                            section. Explain the issue you see and make a suggestion to improve it.</p>
                    </li>
                </ol>


                <h1 className="small_h1">No No's</h1>
                <p>These are important and hopefully obvious. Let's keep it collegial, positive and professional. With
                    that said. . .</p>
                <ol className="hide-numbers">
                    <li>
                        <h2>1. No sharing.</h2>
                        <p>Authors submit their work for the group's eyes alone. Unless the author explicitly gives you
                            permission, the work stays within the group.</p>
                    </li>

                    <li>
                        <h2>2. No bullying or harassment.</h2>
                        <p>Do not send hostile, rude or obscene messages to an individual or group. Do not try to
                            communicate with someone who has asked you to stop.</p>
                    </li>
                    <li>
                        <h2>3. No un-flagged sexual or adult-oriented content.</h2>
                        <p>Inked Voices is home to many kinds of writing groups, some of which may not be open to
                            adult-oriented content. Adult content may only be submitted to groups that accept it, as
                            specified in their group profile.</p>
                    </li>
                    <li>
                        <h2>4. No hateful contributions.</h2>
                        <p>Don't be a hater. But if you are, don't post it here. Don't post materials that encourage
                            violence or hatred on the basis of race, ethnic origin, religion, disability, gender, age,
                            veteran status, or sexual orientation. We have the right to remove hateful or inappropriate
                            content.</p>
                    </li>
                    <li>
                        <h2>5. No promotion or glorification of self-harm.</h2>
                        <p>Don't post material that encourages others to injure themselves, embrace eating disorders, or
                            commit suicide rather than seeking counseling or supportive conversation.</p>
                    </li>
                    <li>
                        <h2>6. No egregious self-marketing.</h2>
                        <p>Nothing wrong with announcing your latest publication, but the primary focus of the groups
                            should be critique and writing improvement.</p>
                    </li>
                </ol>

                <p>
                    If there is an issue with a group member breaking the code, please first take it up with him or her
                    as an individual. If the issue does not resolve, talk to your group leader. If things still don't
                    resolve or if the issue is serious enough to elevate immediately, please contact us at <a
                            href="mailto:support@inkedvoices.com">support@inkedvoices.com</a> and we will help take
                    appropriate action.
                </p>
            </Col>
        </Container>
    );
}

export default CodeOfConduct;
