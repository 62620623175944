import config from "config";
import sendRequest from "services/dataService";

export const loadPaginatedData = async (
  url: string,
  token: string,
  callback: CallableFunction,
  first: Boolean = true
) => {
  const response = await sendRequest({
    url,
    method: "GET",
    token: token,
    body: null,
  });
  callback(response, first);
  if (response.next) {
    // in prod next comes as "http"
    const next = response.next as string;
    const uri = next.startsWith(config.BASE_API_URL || "")
      ? next
      : next.replace("http://", "https://");
    loadPaginatedData(uri, token, callback, false);
  }
};

/**
 * Truncates a string to a specified length and adds an ellipsis if the original string is longer.
 * This function does not preserve HTML tags and might cut the string in the middle of a tag.
 * @param html The HTML string to truncate.
 * @param maxLength The maximum length of the truncated string.
 * @returns The truncated HTML string.
 */
export function truncateCharsHtml(
  html: string | undefined,
  maxLength: number
): string {
  if (!html) return "";

  if (html.length <= maxLength) return html;

  let truncated = html.substr(0, maxLength);

  // Optional: Attempt to avoid cutting in the middle of a word
  // Find the last space within the truncated string
  const lastSpace = truncated.lastIndexOf(" ");
  if (lastSpace > 0) {
    truncated = truncated.substr(0, lastSpace);
  }

  return truncated + "...";
}

export const countWords = (text: string) => {
  const wordList = text.match(/[^\s–—·]+/g) || [];
  return wordList ? wordList.length : 0;
};
