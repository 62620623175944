import React, { useEffect } from "react";

const Logout = () => {
    localStorage.removeItem("token");
    useEffect(() => {
        window.location.href = "/login/";
    }, []);

    return <div className="ink-login"></div>;
};

export default Logout;
