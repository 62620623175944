import React from 'react';
import {
    Col,
    Container,
    Row,
  } from "react-bootstrap";

import './Resources.scss';


const KidlitLiteraryAgentsList = () => {
    return (
        <Container className="container about-view">
            <Row>
                <Col md={{span: 9, offset: 1}}>
                    <Row>
                        <h1>Literary Agents for Children's Writers</h1>
                        <p>
                            Consider this list a resource (not recommendations) for literary agents who represent
                            picture books through young adult. We highly recommend you research agents before
                            submitting a query letter and/or manuscript. Is the agent open for submissions at this
                            time? Is the agent a good fit for you and your manuscript? Good Luck!
                        </p>
                        <p>
                            This data has been gathered with considerable effort and we ask that you keep it to your
                            personal use. Big thanks to Manju Howard who took the work that many of have done in the
                            past, especially Collette Smith, and both simplified and expanded the list. Do you see
                            something that needs updating? Email
                            <a href="mailto:support@inkedvoices.com">support@inkedvoices.com</a> and we'll get it
                            taken care of ASAP.
                        </p>
                    </Row>
                </Col>
                <Col md={{span: 10, offset: 1}}>
                    <Row>
                        <iframe
                            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTq7Da7VXiP5R5GYkF1ludfH-LdvUjpwYpulLbRhSvAHfvOoBntWqXZ9xPwqVkLekmvwWGORYGewlAy/pubhtml?gid=1943410231&amp;single=true&amp;widget=true&amp;headers=false"
                            className="google-sheet"
                        ></iframe>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default KidlitLiteraryAgentsList;
