import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { setEventsFilters } from 'redux/eventsFiltersReducer';

const EventsCategoryFilters = (props: any) => {
    const { categories } = props;
    const eventsFilters = useSelector((state: any) => state.eventsFilters);
    const dispatch = useDispatch();

    const toggleCategory = (name: string) => {
        dispatch(setEventsFilters(
            {
                type: { ...eventsFilters.type },
                category: { ...eventsFilters.category, [name]: !(eventsFilters.category && eventsFilters.category[name]) || false },
                free: eventsFilters.free,
                available: eventsFilters.available,
            }
        ));
    }
    return <>
        <h4>Category</h4>
        <p>
            <span className='button-list'>
                {categories.map((c: any) => (
                    <Button
                        className={"purple " + ((eventsFilters.category && eventsFilters.category[c.key]) ? "" : "inactive")}
                        onClick={(e) => toggleCategory(c.key)}>
                        {c.name}
                    </Button>))}
            </span>
        </p>
    </>
}

export default EventsCategoryFilters;