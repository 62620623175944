import AuthenticationContext from "contexts/AuthenticationContext";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import './EventDetail.scss';
import ReactPlaceholder from 'react-placeholder';

import { useParams } from "react-router-dom";
import sendRequest from "services/dataService";
import EventDetailButton from "./button/EventDetailButton";
import EventDetailHowToRegisterBox from "./howToRegisterBox/EventDetailHowToRegisterBox";
import Testimonial from "components/testimonial/Testimonial";
import DetailPage from "components/detailPage/DetailPage";
import EventDetailHeader from "./header/EventDetailHeader";
import EventDetailFacilitatorBio from "./facilitatorBio/EventDetailFacilitatorBio";
import { getRawEventType, isResourceEvent } from "../utils";

const EventDetail = () => {
    const { id, event_type_friendly_name } = useParams() as any;
    const event_type = getRawEventType(event_type_friendly_name);
    const authenticationContext = useContext(AuthenticationContext);
    const [event, setEvent] = useState<any>(null);

    const fetchData = async () => {
        let url = `events/${id}/?type=${event_type}`;
        const response = await sendRequest({
            url: url,
            method: "GET",
            token: authenticationContext.token || "",
            body: null,
        });
        setEvent(response);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return <DetailPage className="event-detail">
        <Row>
            <Col sm={{ span: 8, offset: 1 }}>
                <EventDetailHeader event={event} />
            </Col>
            <Col sm={3} className="right-column">
                <span className="register-button-wrapper">
                    <EventDetailButton event={event} />
                </span>
                <div className="d-sm-block d-md-none">
                    <EventDetailHowToRegisterBox event={event} />
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm={{ span: 8, offset: 1 }} className="left-column">
                <Row className="main-content">
                    {!event && (
                        <h3>
                            <ReactPlaceholder type='text' rows={5} ready={event}>
                                <div />
                            </ReactPlaceholder>
                        </h3>
                    )}
                    {event?.event_summary && (<h3 dangerouslySetInnerHTML={{ __html: event?.event_summary }} />)}
                    {
                        !isResourceEvent(event) &&
                        <h4 className="grey">Group Details</h4>
                    }
                    <p>
                        <ReactPlaceholder type='text' rows={10} ready={event}>
                            <p dangerouslySetInnerHTML={{ __html: event?.description }} />
                        </ReactPlaceholder>
                    </p>
                    {event?.workload_and_expectations && <>
                        <h4 className="grey">Workload and Participant Expectations</h4>
                        <p dangerouslySetInnerHTML={{ __html: event?.workload_and_expectations }} />
                    </>}
                    {event?.schedule && <>
                        <h4 className="grey">Schedule</h4>
                        <p dangerouslySetInnerHTML={{ __html: event?.schedule }} />
                    </>}
                    {event?.target && <>
                        <h4 className="grey">Notes</h4>
                        {event?.target &&
                            <p className="note">
                                <span className="note-subtitle">Experience level: </span>
                                <span dangerouslySetInnerHTML={{ __html: event?.target }} />
                            </p>
                        }
                        {event?.focus &&
                            <p className="note">
                                <span className="note-subtitle">Focus: </span>
                                <span dangerouslySetInnerHTML={{ __html: event?.focus }} />
                            </p>
                        }

                        {event?.group_size &&
                            <p className="note">
                                <span className="note-subtitle">Group size: </span>
                                <span> {event?.group_size} writers </span>
                            </p>
                        }
                        {event?.submissions &&
                            <p className="note">
                                <span className="note-subtitle">Submissions: </span>
                                <span dangerouslySetInnerHTML={{ __html: event?.submissions }} />
                            </p>
                        }
                    </>}
                    {event?.who_is_it_for && <>
                        <h4 className="grey">Who this class is for</h4>
                        <p dangerouslySetInnerHTML={{ __html: event?.who_is_it_for }} />
                    </>}
                    {event?.what_to_expect && <>
                        <h4 className="grey">What to expect from this event</h4>
                        <p dangerouslySetInnerHTML={{ __html: event?.what_to_expect }} />
                    </>}
                    <EventDetailFacilitatorBio event={event} />
                    {event?.critique_style && <>
                        <h4 className="grey">Critique Style</h4>
                        <p dangerouslySetInnerHTML={{ __html: event?.critique_style }} />
                    </>}
                </Row>
            </Col>
            <Col sm={3} className="right-column">
                <div className="d-none d-md-block">
                    <EventDetailHowToRegisterBox event={event} />
                </div>
                {event?.testimonials?.map((testimonial: any) => <Testimonial testimonial={testimonial} />)}

            </Col>
        </Row >
    </DetailPage >;
}

export default EventDetail;