import { createGlobalState } from 'react-global-state-hooks';
import { FullUserProfile } from 'types';


type ProjectListResultsType = {
    started: boolean,
    completed: boolean,
}

export interface UserProfileState extends ProjectListResultsType {
    profile: FullUserProfile | null;
}

export const useUserProfile = createGlobalState<UserProfileState>({
    started: false,
    completed: false,
    profile: null
});
