import ReactPlaceholder from "react-placeholder/lib";

const EventDetailImage = (props: any) => {
    const event = props.event;
    return <ReactPlaceholder type='rect' className="event-img" ready={event}>
        <img className="event-img" src={
            event?.image_url ||
            event?.picture ||
            "https://www.inkedvoices.com/static/img/bg-group-profile-picture.png"
        } />
    </ReactPlaceholder>
};

export default EventDetailImage;