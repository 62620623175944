import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api-actions";

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        data: null,
        loading: false,
        error: false,
    },
    reducers: {
        dashboardRequested: (state, action) => {
            state.loading = true;
            state.error = false;
        },

        dashboardReceived: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = false;
        },

        dashboardRequestFailed: (state, action) => {
            state.loading = false;
            state.error = true;
        },
    },
});

// Action creators are generated for each case reducer function
export const dashboardReducer = dashboardSlice.reducer;

export const loadDashboard = (token) => (dispatch) => {
    const { dashboardRequested, dashboardReceived, dashboardRequestFailed } = dashboardSlice.actions;
    const url = "dashboard-api/";
    return dispatch(
        apiCallBegan({
            url,
            token: token,
            onStart: dashboardRequested.type,
            onSuccess: dashboardReceived.type,
            onError: dashboardRequestFailed.type,
        })
    );
};
