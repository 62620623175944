import React from 'react';
import {
    Col,
    Container,
    Row,
  } from "react-bootstrap";

import './Workshops.scss';


const ProCritiques = () => {
    return (
        <Container className="container ink-workshops">
            <Row>
                <Col xs={{span: 12}}>
                    <h1 className="text-center">Manuscript and Plot Summary Critiques</h1>
                    <p className="p-subtitle text-center">
                        Get feedback on your story from a professional.
                    </p>
                </Col>
            </Row>
            <Row>
                <Row>
                    <Col xs={{span: 12}}>
                        <h2>Manuscript Critiques</h2>
                        <p>
                            Get high-level feedback on your plot, characters, dialogue, pacing, and hook.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{span: 12}} sm={{span: 10}} md={{span: 9}}>
                        <Row>
                            <Col xs={{span: 12}}>
                                <h4>What to Submit</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 12}}>
                                <p>Your pages, from the beginning and up to the maximum word count (recommended),
                                    OR continuous pages from another section of your manuscript.
                                    Please begin with the start of a chapter or scene.
                                </p>
                                <p>
                                    And, the following to give your reader context:
                                </p>
                                <ul>
                                    <li>
                                        A one to two paragraph summary of your work.
                                        If you're submitting pages from the middle of your manuscript,
                                        include any key information to orient your reader.
                                        <i>This summary will not be critiqued.</i>
                                    </li>
                                    <li>
                                        Any other information about the stage of the work or
                                        what you are looking for in the read.
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={{span: 12}} sm={{span: 10}} md={{span: 9}}>
                        <Row>
                            <Col xs={{span: 12}}>
                                <h4>What to Expect</h4>
                                <p>
                                    You will receive notes on your work that include the following:
                                </p>
                                <ul>
                                    <li>Story elements done well</li>
                                    <li>Story elements needing development or improvement</li>
                                    <li>Suggestions for improvement</li>
                                    <li>Inline comments focused on craft elements (rather than style and grammar)</li>
                                </ul>
                                <p className="p-small">
                                    *Note: a critique is not the same as a developmental edit,
                                    which is a more in-depth, collaborative review of your manuscript,
                                    typically looking at an entire manuscript. Nor is a critique a copyedit,
                                    where an editor will correct spelling and grammar.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{span: 12}}>
                        <h4>Pricing</h4>
                    </Col>
                </Row>
                <Row className="row p-margin">
                    <Col xs={{span: 12}} sm={{span: 2}} md={{span: 2}}></Col>
                    <Col xs={{span: 12}} sm={{span: 8}} md={{span: 6}}>
                        <Row>
                            <Col md={{span: 6}}><b>Words</b></Col>
                            <Col md={{span: 6}}><b>Pricing</b></Col>
                        </Row>
                        <Row>
                            <Col md={{span: 6}}>3750</Col>
                            <Col md={{span: 6}}>$94</Col>
                        </Row>
                        <Row>
                            <Col md={{span: 6}}>5000</Col>
                            <Col md={{span: 6}}>$125</Col>
                        </Row>
                        <Row>    
                            <Col md={{span: 6}}>6250</Col>
                            <Col md={{span: 6}}>$150</Col>
                        </Row>
                        <Row>
                            <Col md={{span: 6}}>12500</Col>
                            <Col md={{span: 6}}>$288</Col>
                        </Row>
                        <Row>
                            <Col md={{span: 6}}>15000</Col>
                            <Col md={{span: 6}}>$345</Col>
                        </Row>
                        <Row>
                            <Col md={{span: 6}}>20000</Col>
                            <Col md={{span: 6}}>$440</Col>
                        </Row>
                        <Row>
                            <Col md={{span: 6}}>25000</Col>
                            <Col md={{span: 6}}>$550</Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col className="text-center" xs={{span: 12}}>
                        <a className="btn purple" href="mailto:brooke@inkedvoices.com">Request critique</a><br></br>
                        <em>from any of our Pros</em>
                    </Col>
                </Row>
            </Row>

            <Row>
                <Row>
                    <Col xs={{span: 12}}>
                        <h2>Plot Summary Critiques</h2>
                        <p>
                            Get feedback on your outline or synopsis to strengthen your plot and make sure you have a
                            story arc that will appeal to readers.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{span: 12}} sm={{span: 10}} md={{span: 9}}>
                        <Row>
                            <Col xs={{span: 12}}>
                                <h4>What to Submit</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 12}}>
                                <p>
                                    A 2-3 page synopsis of your work, either in paragraph or outline form.
                                </p>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={{span: 12}} sm={{span: 10}} md={{span: 9}}>
                        <Row>
                            <Col xs={{span: 12}}>
                                <h4>What to Expect</h4>
                                <p>
                                    Written feedback on plot development and story arc,
                                    as well as a follow-up phone call with the professional.
                                </p>
                            </Col>
                        </Row>
                    </Col>

                </Row>

                <Row>
                    <Col xs={{span: 12}}>
                        <h4>Pricing</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 12}} sm={{span: 10}} md={{span: 9}}>
                        <p>
                            <strong>Option 1:</strong> One round of feedback, with a 20-minute follow up call. $175
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 12}} sm={{span: 10}} md={{span: 9}}>
                        <p>
                            <strong>Option 2:</strong> Option 1, plus an additional round of feedback after
                            you've made edits. $225
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 12}}>
                        <h4>How it works</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 12}} sm={{span: 10}} md={{span: 9}}>
                        <ol>
                            <li>
                                Choose a professional (from our list) to review your work.
                            </li>
                            <li>
                                Tell us when you need your critique back. Critiques usually take 2 weeks,
                                but it can be longer depending upon the professional's availability.
                                Please let us know if you are flexible on timing.
                            </li>
                            <li>
                                We will confirm if your choice is available. If so, we will send you an invoice.
                            </li>
                            <li>
                                Once paid, you will share your work with the pro.
                            </li>
                            <li>
                                He or she will return your work with comments.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 12}} sm={{span: 10}} md={{span: 6}}>
                        <h4>Jen Malone</h4>
                        <p>
                            Jen Malone is the author of over a dozen middle grade and young adult titles including The
                            Arrival of Someday (HarperCollins), The Sleepover (Simon & Schuster), and Follow Your
                            Art (Dreamworks Animation/Penguin Random House). She is also the editor of the MG
                            novel Best. Night. Ever. (S&S), a collaborative story written by seven authors, and loves
                            working with aspiring and published authors as a freelance editor. Her "soft spot" is
                            kidlit, but she also has experience editing romance, women's fiction and adult fantasy...
                            and if your story has a feel-good vibe, a laugh or two or a snarky protagonist, she calls
                            dibs! You can learn more about Jen and her books at
                            <a href="www.jenmalonewrites.com">www.jenmalonewrites.com/</a>
                        </p>
                        <p>
                            <b>Categories:</b> MG and YA of all genres, Romance, Women's fiction, Fantasy
                        </p>
                        <p>
                            <i>“I'm all about “constructive" versus “critical" when it comes to feedback. I want to
                                offer you tools to help you improve as a writer, not send you running to the nearest box
                                of Kleenex! I will be honest and in-depth, but I also work hard to frame my comments in
                                a respectful way and to celebrate the aspects you're already getting right.”</i>
                        </p>
                    </Col>
                    <Col xs={{span: 12}} sm={{span: 10}} md={{span: 6}}>
                        <h4>Ellen Bryson</h4>
                        <p>
                            Ellen Bryson authored The Transformation of Bartholomew Fortuno, a novel set in P.T.
                            Barnum's American Museum in New York of 1865, about human appetites and longings, and what
                            it means to be profoundly unique. Transformation won the San Diego Book Award for Historic
                            Fiction, 2010; was shortlisted for Great Britain's Historic Writers Association's Début
                            Novel in 2012; was selected Indie Pick of the Month, Notable, in July 2010; and was
                            Book-of-the-Month Club Alternative, 2010. Ellen earned her BA in English from Columbia
                            University and her MA in creative writing from Johns Hopkins University in Washington, DC.
                            Since 2012, she has taught fiction classes and run workshops in the United States and in
                            France where she currently lives. Her preferred editing projects are those that make a
                            reader both feel and learn, but also those that are quirky or a 'little off.' Using her
                            editorial skills, she has helped bring a number of books to publication.
                        </p>
                        <p>
                            <b>Categories:</b> Adult fiction, historic fiction, literary fiction, young adult, suspense,
                            horror, gothic (no science fiction or speculative).
                        </p>
                        <p>
                            <i>“I critique as if I am a new reader. I notate places where I am lost in either time or
                                space, where I have missed meaning or need more information, and where I yearn for a
                                deeper look at something. I believe a story needs to bring the reader into the dream and
                                that dreams are made of images and sequential discovery, so, I pay particular attention
                                to sensory and visual details, and to the order in which things appear on the page. I
                                also love to point out the places where a writer shines.”</i>
                        </p>
                    </Col>
                    <Col xs={{span: 12}} sm={{span: 10}} md={{span: 6}}>
                        <h4>Russell Rowland</h4>
                        <p>
                            Russell Rowland has published five novels, two of which were finalists for the High Plains
                            Book Award. His most recent novel, COLD COUNTRY, has been optioned for film. Rowland is also
                            the author of Fifty-Six Counties: A Montana Journey, about his travels to every county in
                            Montana. Rowland has an MA in Creative Writing from Boston University, and has worked with
                            many published authors and beginning writers in both fiction and non-fiction.
                        </p>
                        <p>
                            <b>Categories:</b> Fiction, non-fiction, everything but fantasy or horror.
                        </p>
                        <p>
                            <i>“When I'm working with other writers, I focus on every aspect of their craft, including
                                basic issues of grammar, but my main goal is to bring out the voice of the writer—to
                                understand their intent and help them bring whatever they're working on to its highest
                                quality.”
                            </i>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 12}} className="text-center">
                        <a className="btn purple" href="mailto:brooke@inkedvoices.com">Request critique</a><br></br>
                        <em>from Jen Malone, Ellen Bryson or Russell Rowland</em>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
}

export default ProCritiques;
