import React from 'react';
import { Col, Container, Row } from "react-bootstrap";

import Config from "../../config";
import './Pricing.scss';

const BASE_API_URL = Config.BASE_API_URL;


const Pricing = () => {
    return (
      <Container className="ink-pricing-view">
        <Row className="features">
          <Col md={12}>
            <div className="pricing">
              <h1 className="text-center">Writing Groups, Community, and Professional Development for the Practicing Writer.</h1>
              <h2>Wherever You Live and Whatever Your Schedule</h2>
              <div className="select-plan">
                <a href="#" className="plan selected">
                  <span className="ico-selected"></span>
                  <h4>QUARTERLY</h4>
                  <p>
                  <div className="price">$36</div>
                  / billed every 3 months</p>
                  <p className="amount">$12.00 per month </p>
                </a>
                <a href="#" className="plan selected">
                  <span className="ico-selected"></span>
                  <h4>ANNUAL</h4>
                  <p>
                  <div className="price">$99</div>
                  / billed annually</p>
                  <p className="amount">$8.25 per month (save 31.25%)</p>
                </a>
              </div>
              <a href={BASE_API_URL + "account/register/"} className="btn btn-large yellow">Start Your 14-day Free Trial</a>
            </div>

            <Row>
              <Col xs={12}>
                <h2>Included In Your Membership</h2>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <ul>
                  <li>Create, join, and participate in intimate writing groups with like-minded writers.
                  </li>
                  <li>Collaborate in private group workspaces designed for workshopping.</li>
                  <li>One-on-one help matching with a group.</li>
                  <li>Opportunities to meet critique partners and/or get fresh eyes on your work at small group events focused by genre.</li>
                  <li>Support and accountability during NaNoWriMo and Camp NaNoWriMo through site “cabins” and write-ins.</li>
                  <li>Interaction with writers outside your group at site readings and member-driven discussions.</li>
                </ul>
              </Col>
              <Col xs={12} sm={6}>
                <ul>
                  <li>
                    Learn from industry professionals speakers at our live webinars. 2018 speakers
                    include Tex Thompson, Jane Friedman, Sangeeta Mehta, Fiona Kenshole and more. After
                    the free trial period.
                  </li>
                  <li>
                    Unlimited, anytime access to 
                    <a href={Config.BASE_API_URL + "lecture-series/"} target="_blank" rel="noopener noreferrer">our
                    library of recorded webinars</a> – over 25 hours of content. After the free trial
                    period.
                  </li>
                  <li>Publicity for your launches, publications and wins in our monthly newsletter.</li>
                  <li>Discounts from other businesses on tools and resources for your writing.</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h2>Extras, Discounted For Members</h2>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <ul>
                  <li>
                    Join our highly-regarded agent/editor small group 
                    <a href={Config.BASE_API_URL + "group/pro_groups/"} target="_blank" rel="noopener noreferrer">critique events</a> 
                    for written feedback, plus the one-on-one time for your questions in the group debrief call. Focused by genre and capped at 8 or 10 writers.
                  </li>
                  <li>
                    Get a masterclass style experience in our 
                    <a href={Config.BASE_API_URL + "group/pro_groups/"} target="_blank" rel="noopener noreferrer">writing workshops</a> 
                    with professionals. Each capped at 5 writers.
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h2>Other Discounts </h2>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <ul>
                  <li>
                    <b>Referrals:</b> Earn 10% off for yourself and the person you refer for one year. Discounts are cumulative for the referrer.
                  </li>
                  <li>
                    <b>Pre-existing Groups:</b> Groups joining the site together get a 15% off discount
                      for their members for the first year. <a href="mailto:support@inkedvoices.com">Contact us</a> 
                      and we’ll send you a coupon code. If you’ve already signed up, we can manually add this for you after the fact.
                  </li>
                  <li>
                    <b>Students:</b> Full-time students save 30%. Sign up with your school email address
                      and <a href="mailto:support@inkedvoices.com">contact us</a> with the name of your
                      school, program and graduation year. We will confirm back and put your discount in
                      for you.
                  </li>
                </ul>
              </Col>
            </Row>

            <h1 style={{textAlign: 'center', width: '100%'}}>Frequently Asked Questions</h1>
            <Row>
                <Col xs={12} sm={6}>
                    <h3>I already have a group. Can I host it on Inked Voices?</h3>
                    Absolutely. Using our workspaces reduces the administrative headaches of running a group.
                    And with exchanges, discussions and shared files in a single place, your group is organized
                    and positioned for collaboration. Your group can be completely private, or you could later
                    choose to accept invitation-requests. Writers join Inked Voices as individuals, enjoying all
                    benefits of membership. We can invoice groups who prefer to pay for their members together.
                    Groups receive 15% off their first year.
                    <h3>What kinds of groups are on Inked Voices?</h3>
                    Inked Voices is the proud home to critique groups, accountability groups, resource-sharing
                    groups, and a writing exercises group. Many of our critique groups are focused by genre,
                    e.g. historical fiction, mystery/thriller, or middle grade. We also have mixed genre groups.
                    Accountability groups are a place for writing check-ins and process discussions, and are
                    great for writers at any stage who want the motivation and community a small group can
                    afford.
                    <h3>How do I know if there is a group for me?</h3>
                    Some of our groups are listed 
                    <a href={Config.BASE_API_URL + "group/writing_groups/"} target="_blank" rel="noopener noreferrer">here</a>.
                    When you log in as a member, you will find a longer list under Groups/Search. This list
                    includes all of the groups that are open to inquiries (groups may hide themselves from
                    Search). You will be able to read each group’s profile, as well as member profiles. Because
                    the options can be overwhelming for some writers, we will also reach out to you when you
                    join to ask what you are looking for, and then we will give you suggestions. We also make
                    introductions between individual writers if we spot a potential connection.
                    <h3>What level are the writers at on your site?</h3>
                    We have a variety of levels here, from newly serious to writing veterans. An alignment in
                    writing level matters most when looking for a critique group or partner, and so it is best
                    to look at the groups themselves. Our lectures and workshops are targeted to writers who
                    have taken classes, attended conferences, and otherwise studied their craft.

                </Col>
                <Col xs={12} sm={6}>
                    <h3>How should I select a writing group?</h3>
                    Getting clear on why you are looking for a group is a great first step. Are you looking for
                    feedback, support, community, accountability, or all of these? For critique groups, think
                    about the type of work you want to share and to critique – you want to genuinely enjoy
                    reading your group’s pieces. It takes time to prepare submissions to meet group deadlines,
                    and time to give the quality feedback that most writers prefer to receive. Some writers
                    manage the time commitment by joining a smaller group. Others join mid-size or larger groups
                    with less frequent deadlines, or that run drop-in style. Finding a writing group is a lot
                    like dating. You will make your best guess, and then go from there. It is OK if it takes
                    several tries.
                    <h3>What if the group I join is not a good fit?</h3>
                    Please post a note to the group, or at least message the group leader, to tactfully and
                    respectfully let them know you are bowing out. Then click the “leave group” option. If you
                    love the work in the group, but there is an issue with communication, activity level, etc.,
                    you are still welcome to leave, but we would encourage you to try addressing the issue
                    first. If you feel stuck on how, reach out and we are happy to help.
                    <h3>Who can access my work?</h3>
                    You control who accesses your work. When you submit to a group, only that group has access.
                    Work displays in the group workspace until its critique and review deadlines pass, and then
                    leaves the group automatically. The critiqued version remains available for you via your
                    Dashboard, which is private to you alone. You may remove your work at any point in this
                    process.
                </Col>
            </Row>
            <div style={{textAlign: 'center', width: '100%'}}>
              <br></br>
              <h3>
                Other questions?<br></br>
                More answers<br></br>
                Or, <a href="mailto:support@inkedvoices.com">email us</a>
              </h3>
            </div>
          </Col>
        </Row>
      </Container>
    );
}

export default Pricing;
