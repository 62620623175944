import React, { useContext, useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import AuthenticationContext from "contexts/AuthenticationContext";

import sendRequest from "services/dataService";
import config from "config";
import { BaseProject } from "types";

const backend_url = config.BASE_API_URL?.slice(0, -1);

type ActivateModalProps = {
  project: BaseProject;
  show: Boolean;
  onHide: () => void;
  onChange: () => void;
};

const ActivateModal = (props: ActivateModalProps) => {
  const { project, show, onHide, onChange } = props;
  const authenticationContext = useContext(AuthenticationContext);

  const onSuccess = () => {
    onHide();
    onChange();
    toast.success(`Project re-activated successfully.`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const result = await sendRequest({
        url: `api/v1.0/project/${project.id}/`,
        method: "PUT",
        token: authenticationContext.token || "",
        body: JSON.stringify({ active: true }),
      });
      onHide();
      onSuccess();
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title>CONFIRM ACTIVATION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to re-activate <b>{project.title}</b> Project?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="primary purple" onClick={onSubmit}>
          Activate
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActivateModal;
