import React from 'react';
import { Col, Container, Row } from "react-bootstrap";

import Config from "../../config";
import './EducatorsAndWritingPrograms.scss';

const BASE_API_URL = Config.BASE_API_URL;


const EducatorsAndWritingPrograms = () => {
    return (
        <Container className="ink-educators-and-writing-programs">
            <h1 className="text-center educators-heading">Educators and writing programs</h1>
            <Row className="row row-margin">
                <Col md={{ span: 8, offset: 2 }}>
                    <h2 className="text-center">Use Inked Voices for workshopping and discussion. Our group workspaces
                        complement teaching, support peer learning and build community.</h2>
                </Col>
            </Row>
            <Row className="row row-margin">
                <Col md={{ span: 10, offset: 1 }}>
                    <h3>Teachers and Professors</h3>
                    <p className="main-text">Use Inked Voices peer workshopping groups for your English or Creative Writing
                        classes. Students can workshop as a class, in small peer groups, or both. </p>
                </Col>
            </Row>
            <Row className="row row-margin">
                <Col md={{ span: 8, offset: 1 }}>
                    <h3>Writing Programs</h3>
                    <p className="main-text">
                        Inked Voices groups can enhance your program experience.
                    </p>
                    <ul className="main-text">
                        <li>Support mentor-mentee relationships with partner groups that keep discussions and feedback
                            organized and in one common space.
                        </li>
                        <li>Strengthen community among current students with a shared space to complement in-person
                            workshops.
                        </li>
                        <li>Build community among alumni with a shared space for workshopping, discussion, and
                            resource-sharing after graduation.
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className="row">
                <Col md={{ span: 10, offset: 1 }}>
                    <p className="main-text">
                        The Inked Voices platform has the flexibility to support different options. Contact us to learn
                        more about features and pricing, and to request a demo.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="text-center">
                    <a className="btn purple" href={BASE_API_URL + "contact/"} data-toggle="modal" data-target="#">Contact Us</a>
                </Col>
            </Row>
        </Container>
    );
}

export default EducatorsAndWritingPrograms;
