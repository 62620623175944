import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api-actions";

export const postsSlice = createSlice({
  name: "posts",
  initialState: {
    posts: [],
    currentPost: null,
    loading: false,
  },
  reducers: {
    postsRequested: (state, action) => {
      state.loading = state.posts.length === 0;
    },

    postsReceived: (state, action) => {
      state.posts = action.payload.results;
      state.loading = false;
    },

    postsRequestFailed: (state, action) => {
      state.loading = false;
    },

    setCurrent: (state, action) => {
      state.currentPost = action.payload.post;
    },
  },
});

// Action creators are generated for each case reducer function
export const postsReducer = postsSlice.reducer;

export const loadposts = (token) => (dispatch) => {
  const { postsRequested, postsReceived, postsRequestFailed } =
    postsSlice.actions;
  const url = `forum/posts/`;
  return dispatch(
    apiCallBegan({
      url,
      token,
      onStart: postsRequested.type,
      onSuccess: postsReceived.type,
      onError: postsRequestFailed.type,
    })
  );
};

export const updateCurrentPost = (post) => (dispatch) => {
  const { setCurrent } = postsSlice.actions;
  return dispatch(setCurrent({ post }));
};
