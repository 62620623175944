import config from "config";
import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import AuthenticationContext from "contexts/AuthenticationContext";
import GroupAdminOptions from "../GroupAdminOptions/GroupAdminOptions";
import sendRequest from "services/dataService";
import { Membership } from "types";
import ReactPlaceholder from "react-placeholder/lib";

type GroupComponentProps = {
  membership: Membership | null;
};

const GroupComponent = (props: GroupComponentProps) => {
  const authenticationContext = useContext(AuthenticationContext);
  const membership = props.membership;
  const [notifications, setNotifications] = useState(
    membership?.notifications?.length ? membership.notifications : []
  );
  const dismissNotification = async (notification: any) => {
    try {
      sendRequest({
        url: `group/api/notifications/${notification.id}/`,
        method: "DELETE",
        token: authenticationContext.token || "",
        body: null,
      });

      setNotifications(
        notifications.filter((n: any) => n.id != notification.id)
      );
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <div className="group">
      <div className="group-container">
        <div>
          <ReactPlaceholder type="text" rows={6} ready={membership != null}>
            {membership && (
              <>
                <div>
                  <h2>
                    <a
                      href={
                        config.BASE_API_URL + membership.group.url?.substring(1)
                      }
                    >
                      {membership.group.name}
                    </a>
                    &nbsp;
                    <GroupAdminOptions group={membership.group} />
                  </h2>
                </div>
                <Row>
                  <Col sm={4} md={4}>
                    <img
                      className="avatar"
                      src={
                        membership.group.avatar
                          ? config.BASE_API_URL +
                            membership.group.avatar?.substring(1)
                          : "https://www.inkedvoices.com/static/img/bg-group-profile-picture.png"
                      }
                    />
                    <div className="credits">
                      <p>{membership.balance} Credits</p>
                    </div>
                  </Col>
                  <Col sm={8} md={8}>
                    {notifications?.length > 0 ? (
                      <>
                        <h5>Notifications: </h5>
                        <ul>
                          {membership.notifications.map((notification: any) => (
                            <li className="sm-text">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: notification.message,
                                }}
                              />
                              <span
                                className="icon-note-cancel"
                                id="not_cancel_5"
                                onClick={(evt) => {
                                  dismissNotification(notification);
                                }}
                              ></span>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <p className="sm-text">No notifications pending</p>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </ReactPlaceholder>
        </div>
      </div>
    </div>
  );
};

export default GroupComponent;
