
import './Testimonial.scss';

const Testimonial = (props: any) => {
    const testimonial = props.testimonial;
    return <div className="testimonial">
        <div>
            <span className="quote">&ldquo;</span>
            {testimonial?.message}&rdquo;
        </div>
        <div className="name">- {testimonial?.author}</div>
    </div>
}

export default Testimonial;