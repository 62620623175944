import axios from "axios";
import config from "config"
import { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from 'react-toastify';
import AuthenticationContext from "contexts/AuthenticationContext";
import GroupAdminOptions from "../GroupAdminOptions/GroupAdminOptions";
import sendRequest from "services/dataService";
import { Link } from "react-router-dom";


const InvitationComponent = (props: any) => {
    const invitation = props.invitation;

    return <div className="invite">
    <div className="invite-container">
        <p className="intro">You have been invited to join</p>
        <h2 className="title">{invitation.group_name}</h2>
        <a className="link" href={invitation.group_url}>View Profile</a>
        <Row className="btn-container">
            <Col sm={6} md={6}>
                <a href={invitation.accept_url}>
                    <Button className="btn-block purple">Accept</Button>
                </a>
            </Col>
            <Col sm={6} md={6}>
                <a href={invitation.decline_url}>
                    <Button className="btn-block grey">Decline</Button>
                </a>
            </Col>
        </Row>
    </div>
</div>;
}

export default InvitationComponent;