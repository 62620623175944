import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loaduser } from "redux/userReducer";
import "bootstrap/dist/css/bootstrap.css";
import logo from "assets/inkedVoicesLogo.png";
import Navbar from "react-bootstrap/Navbar";
import AuthenticationContext from "contexts/AuthenticationContext";
import { loadposts } from "redux/postsReducer";
import { Container } from "react-bootstrap";
import { loadLastConnections } from "redux/lastConnectionsReducer";

import "./Header.scss";

import HeaderUserAuthenticated from "./HeaderUserAuthenticated";
import HeaderAnonymousUser from "./HeaderAnonymousUser";
import { loadevents } from "redux/eventsReducer";
import { Link } from "react-router-dom";
import { CurrentUserState } from "types";

const Header = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const dispatch = useDispatch();
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const posts = useSelector((state: any) => state.posts);
  const currentPost = posts.currentPost;
  useEffect(() => {
    dispatch(loaduser(authenticationContext.token));
    dispatch(loadevents(authenticationContext.token));
    // Update posts list
    dispatch(loadposts(authenticationContext.token));
    if (authenticationContext.token) {
      setInterval(function () {
        dispatch(loadposts(authenticationContext.token));
      }, 60000);
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentPost) {
      dispatch(
        loadLastConnections(authenticationContext.token, currentPost.id)
      );
    }
  }, [posts]);
  return (
    <Container>
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="logo" width={140} />
        </Navbar.Brand>
        <span className="spacer" />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        {userState.isAuthenticated && !userState.loading ? (
          <HeaderUserAuthenticated userState={userState} />
        ) : (
          <HeaderAnonymousUser />
        )}
      </Navbar>
      <div className="line" />
    </Container>
  );
};

export default Header;
