import AuthenticationContext from "contexts/AuthenticationContext";
import { createRef, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { initAnnotator } from "./annotator-utills";
import { BaseUser } from "types";
import $ from "jquery";

export type SubmissionResponse = {
  document: {
    id: number;
    author: {
      id: number;
      pen_name: string;
    };
    title: string;
    contents: string;
  };
  group: {
    id: number;
    name: string;
    min_critique_words: number;
    url: string;
  };
  notes: string;
  due_date: string;
};

export type Critique = {
  id?: number;
  contents: string;
  submission: number;
  status: number;
};

export interface CritiqueResponse extends Critique {
  author: {
    id: number;
    pen_name: string;
    image: string;
  };
  created_at: string;
  finished_at: string;
}

export function AnnotatedContent({
  submissionId,
  content,
  notes,
  userData,
  all = false,
  users,
  canDelete = true,
}: {
  submissionId: number;
  content?: string;
  notes?: string;
  userData?: any;
  all?: boolean;
  users?: any[];
  canDelete?: boolean;
}) {
  const [annotatorStarted, setAnnotatorStarted] = useState(false);
  const divRef = createRef<HTMLDivElement>();
  const authenticationContext = useContext(AuthenticationContext);
  const [annotator, setAnnotator] = useState<any>(null);
  const [oldUsers, setOldUsers] = useState<BaseUser[] | null>(null);

  const cleanOldAnnotator = () => {
    if (!annotator) {
      return;
    }
    annotator.destroy();
    // remove all the margin annotations
    $(".secondary.margin-annotator-container").empty();
    // cleanup the selected highlights since destroy wasn't removing them
    $(".annotator-hl-uber").each((i, highlight) => {
      $(highlight).replaceWith($(highlight).text());
    });
    setAnnotatorStarted(false);
  };

  const initializeAnnotator = () => {
    if (divRef.current && authenticationContext.token) {
      cleanOldAnnotator();
      const ann = initAnnotator(
        userData,
        submissionId,
        divRef.current,
        authenticationContext.token,
        all,
        users,
        canDelete
      );
      setOldUsers(users || null);
      setAnnotator(ann);
    }
  };

  useEffect(() => {
    if (userData.id && submissionId && users !== oldUsers && (!all || users)) {
      initializeAnnotator();
    }
  }, [
    divRef,
    userData,
    submissionId,
    annotatorStarted,
    authenticationContext,
    users,
  ]);

  return (
    <Row>
      <Col xs={12} md={8} className="pt-4">
        {notes && (
          <div className="submission-notes">
            <i
              dangerouslySetInnerHTML={{
                __html: notes || "",
              }}
            ></i>
          </div>
        )}

        <div ref={divRef}>
          <div
            id="annotated_content"
            className="flex-1 pr-8 annotated_content"
            dangerouslySetInnerHTML={{
              __html: content || "",
            }}
          ></div>
        </div>
      </Col>
      <Col xs={0} md={4} className="margin-annotator-wrapper">
        {/* Margin Viewer container */}
        <div className="w-64 border-l pl-4 margin-annotator-container">
          <div className="secondary margin-annotator-container"></div>
        </div>
      </Col>
    </Row>
  );
}
