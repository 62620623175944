import React from 'react';

import { FormattedMessage } from "react-intl";
import { NavDropdown } from "react-bootstrap";

import { Link } from 'react-router-dom';


const WorkshopDropdown = () => {
    return (
        <NavDropdown
            id="workshop-dropdown"
            title={<FormattedMessage id="header.events" />}
        >
            <NavDropdown.Item
                eventKey="upcoming_events"
                as={Link} to={"/events/"}
            >
                <FormattedMessage id="header.upcoming_events" />
            </NavDropdown.Item>
            <NavDropdown.Item
                eventKey="webinars_archive"
                as={Link} to={"/archive/"}
            >
                <FormattedMessage id="header.webinars_archive" />
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="pro_critiques" as={Link} to={"/pro-critiques/"}>
                <FormattedMessage id="header.pro_critiques" />
            </NavDropdown.Item>
        </NavDropdown>
    );
}

export default WorkshopDropdown;