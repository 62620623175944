import React from 'react';
import { Col, Container, Row } from "react-bootstrap";

import Config from "../../config";
import './WorkWithUs.scss';

const BASE_API_URL = Config.BASE_API_URL;


const WorkWithUs = () => {
    return (
        <Container className="ink-work-with-us">
            <Row>
                <Col xs={12} className="text-center">
                    <h1>Work with us</h1>
                </Col>
            </Row>

            <Row>
                <Col xs={12} className="text-center">
                    <h2>
                        Inked Voices is always looking for talented, friendly people<br className="hidden-xs"></br>
                        who enjoy helping others improve their writing.
                    </h2>
                </Col>
            </Row>

            <Row>
                <Col xs={12} sm={6} md={{ span: 5, offset: 1 }}>
                    <h4>Lectures</h4>
                    <h5>
                        Delivered by authors, speakers, teachers, editors and agents
                    </h5>
                    <p>
                        Present a webinar workshop on craft or writing process.
                        Our web lectures are a mix of lecture and practical exercises,
                        lasting 60-90 minutes. Please share a short proposal
                        or 2-3 ideas you're interested in presenting.
                        We're most interested in what you're passionate and knowledgeable about.
                        <a href="{% url 'resources_index' %}">See previous topics.</a>
                    </p>
                </Col>
                <Col xs={12} sm={6} md={5}>
                    <h4>Workshops</h4>
                    <h5>
                        Run by editors, writing teachers and literary agents
                    </h5>
                    <p>
                        Work with a small group of writers in a multi-month workshop setting,
                        offering critiques and mentoring. Our workshops focus on critiquing only;
                        there is not a formal lecture component, though instruction happens in the monthly calls.
                        <a href="{% url 'pro_groups' %}#multi">See current workshops.</a>
                    </p>
                </Col>

            </Row>

            <Row>
                <Col xs={12} sm={6} md={{ span:5, offset:1 }}>
                    <h4>First Pages Events</h4>
                    <h5>
                        Led by agents and editors with support from Inked Voices
                    </h5>
                    <p>
                        Critique and mentor a small group of writers in an intimate setting,
                        online. <a href="{% url 'pro_groups' %}#first">See events.</a>
                    </p>

                </Col>
                <Col xs={12} sm={6} md={5}>
                    <h4>Make a Suggestion</h4>
                    {/* <!--<h5>
                        Run by editors, writing teachers and literary agents
                    </h5>--> */}
                    <p>
                        If you don't see what you bring to the table,
                        and you feel it would be important to our writers,
                        please connect with us and share your idea.
                    </p>
                </Col>

            </Row>

            <Row>
                <Col xs={12} md={{ span:11, offset:1 }}>
                    <h5>
                        When you contact us, please include the following:
                    </h5>
                    <ul>
                        <li>
                            What opportunity you're interested in
                        </li>
                        <li>
                            Why you're interested
                        </li>
                        <li>
                            Your background, including training, teaching experience and mentoring experience
                        </li>
                        <li>
                            Your LinkedIn profile (optional)
                        </li>
                    </ul>
                    <Row>
                        <p>
                            All of these opportunities are paid. Of course, please feel free to ask any questions about
                            our opportunities.
                        </p>
                    </Row>
                    <Row>
                        <Col xs={12} sm={11} className="contact-button text-center">
                            <a className="btn purple" href={BASE_API_URL + "contact/"} data-toggle="modal" data-target="#">Contact us</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default WorkWithUs;
