import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api-actions";

export const eventSlice = createSlice({
    name: "events",
    initialState: {
        data: [],
        loading: false,
    },
    reducers: {
        eventRequested: (state, action) => {
            state.loading = true;
        },

        eventReceived: (state, action) => {
            state.data = action.payload.results;
            state.loading = false;
        },

        eventRequestFailed: (state, action) => {
            state.loading = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const eventReducer = eventSlice.reducer;

export const loadevents = (token) => (dispatch) => {
    const { eventRequested, eventReceived, eventRequestFailed } = eventSlice.actions;
    const url = "events/";
    return dispatch(
        apiCallBegan({
            url,
            token: token,
            onStart: eventRequested.type,
            onSuccess: eventReceived.type,
            onError: eventRequestFailed.type,
        })
    );
};
