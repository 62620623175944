import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { setEventsFilters } from 'redux/eventsFiltersReducer';

const EventsAvailabilityFilters = (props: any) => {
    const eventsFilters = useSelector((state: any) => state.eventsFilters);
    const dispatch = useDispatch();

    const toggleFreeToMembersFilter = () => {
        dispatch(setEventsFilters(
            {
                type: { ...eventsFilters.type },
                category: { ...eventsFilters.category },
                free: !eventsFilters.free,
                available: eventsFilters.available,
            }
        ));
    }
    const toggleAllowsNonMembersFilter = () => {
        dispatch(setEventsFilters(
            {
                type: { ...eventsFilters.type },
                category: { ...eventsFilters.category },
                free: eventsFilters.free,
                available: !eventsFilters.available,
            }
        ));
    }
    return <>
        <Form>
            <h5>Availability</h5>
            <Form.Group controlId="freeToMembers">
                <Form.Check type="checkbox" label="Free to members" checked={eventsFilters.free} onChange={toggleFreeToMembersFilter} />
            </Form.Group>
            <Form.Group controlId="availableToNonMembers">
                <Form.Check type="checkbox" label="Available to nonmembers" checked={eventsFilters.available} onChange={toggleAllowsNonMembersFilter} />
            </Form.Group>
        </Form>
    </>;
}

export default EventsAvailabilityFilters;