import * as actions from "./internal-actions";


const internal =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.openRepliesAction.type) return next(action);
  };

export default internal;
