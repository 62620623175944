import { Tab, Tabs } from "react-bootstrap";
import GroupDetailCritiqueTab from "./groupDetailCritiqueTab";
import GroupDetailReviewTab from "./groupDetailReviewTab";

export default function GroupDetailTabs({ groupId }: { groupId: number }) {
  return (
    <Tabs defaultValue="critique" className="nav nav-tabs">
      <Tab title="Critique" tabClassName="tab-purple" eventKey="critique">
        <GroupDetailCritiqueTab groupId={groupId} />
      </Tab>
      <Tab title="Review" tabClassName="tab-purple" eventKey="review">
        <GroupDetailReviewTab />
      </Tab>
    </Tabs>
  );
}
