import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "redux/userReducer";
import { eventReducer } from "redux/eventsReducer";
import { openRepliesReducer } from "redux/openRepliesReducer";
import { replyToReducer } from "redux/replyToReducer";
import { postsReducer } from "redux/postsReducer";
import { lastConnectionsReducer } from "redux/lastConnectionsReducer";
import apiMiddleware from "redux/api-middleware";
import internalMiddleware from "redux/internal-middleware";
import { eventsFiltersReducer } from "./eventsFiltersReducer";
import { filterValuesReducer } from "./filterValuesReducer";
import { dashboardReducer } from "./dashboardReducer";

export default configureStore({
  reducer: {
    user: userReducer,
    openReplies: openRepliesReducer,
    eventsFilters: eventsFiltersReducer,
    filterValues: filterValuesReducer,
    replyTo: replyToReducer,
    posts: postsReducer,
    lastConnections: lastConnectionsReducer,
    events: eventReducer,
    dashboard: dashboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiMiddleware).concat(internalMiddleware),
});
