import React, { useState } from "react";
import "./Login.scss";
import Config from "config";
import { Col, Row, Form, Button } from "react-bootstrap";
import sendRequest from "services/dataService";
import { toast } from 'react-toastify';

const Login = () => {
  const loginApiUrl = "api-auth/";

  const [values, setValues] = useState<any>({
    username: undefined,
    password: undefined,
    remember_me: true
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const result = await sendRequest({
        url: loginApiUrl,
        method: "POST",
        token: "",
        body: JSON.stringify({ username: values.username, password: values.password }),
      });
      localStorage.setItem("token", result.token);
      const un = encodeURI(btoa(values.username)).toString();
      const pw = encodeURI(btoa(values.password)).toString();
      window.location.href = Config.BASE_API_URL + "account/login/?x=" + un + '&w=' + pw + '&r=' + values.remember_me;
    } catch {
      toast.error("Unable to log in with provided credentials.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  };

  const onChange = async (e: any) => {
    var v = { ...values };
    v[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setValues(v);
  }

  return <Row>
    <Col className="login" xs={10} sm={6} md={{ span: 5, offset: 3 }}>
      <h1>LOGIN</h1>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control type="email" name="username" placeholder="Email address" value={values.username} onChange={onChange} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control type="password" name="password" placeholder="Password" value={values.password} onChange={onChange} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" className="forgot-pass" name="remember_me" checked={values.remember_me} onChange={onChange} label={<>
            <span>Keep me logged in</span>
            <a href={`${Config.BASE_API_URL}account/password/reset/`}>Forgot your password?</a>
          </>} />
        </Form.Group>
        <Button variant="primary" type="submit" className="purple">
          Log in
        </Button>
      </Form>
      <p className="login_dont_have_account">
        Don't have an account? <a href={`${Config.BASE_API_URL}account/register/`}>Sign up</a>
      </p>
    </Col>
  </Row>;
};

export default Login;
