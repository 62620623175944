import React, { useEffect, useState } from 'react';
import { Table } from "react-bootstrap";
import ReactPlaceholder from 'react-placeholder/lib';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { displayEventDates, getClosedEventMessage, getOpenEventMessage } from '../utils';

const EventsTable = (props: any) => {
    const { types, categories } = props;

    const eventsStatus = useSelector((state: any) => state.events);
    const eventsFilters = useSelector((state: any) => state.eventsFilters);
    const events = eventsStatus.data;
    const [visibleEvents, setVisibleEvents] = useState<Array<any>>([]);
    const pageLoading = eventsStatus.loading;

    useEffect(() => {
        let res: Array<any> = events || [];
        if (types.map((t: any) => (eventsFilters.type && eventsFilters.type[t.key])).filter((v: boolean) => v).length > 0) {
            res = res.filter((e: any) => (eventsFilters.type && eventsFilters.type[e.event_type]))
        }
        if (categories.map((c: any) => (eventsFilters.category && eventsFilters.category[c.key])).filter((v: boolean) => v).length > 0) {
            res = res.filter((e: any) => (eventsFilters.category && (
                e.event_category?.map((cat: any) => eventsFilters.category[cat.id]).some((v: any) => v === true)
            )))
        }
        if (eventsFilters.free) {
            res = res.filter((e: any) => e.free_to_members)
        }
        if (eventsFilters.available) {
            res = res.filter((e: any) => e.non_members_can_join)
        }
        setVisibleEvents(res);
    }, [eventsFilters, events, setVisibleEvents, types, categories])

    const defaultRows = (visibleEvents.length === 0) && (!pageLoading) && <tr><p>No events found</p></tr>;

    const getStatusMessage = (evt: any) => {
        if (evt.status === "CLOSED") {
            return getClosedEventMessage(evt)
        }
        if (evt.status === "OPEN") {
            return getOpenEventMessage(evt);
        }
        if (evt.status === "FULL") {
            return "Full";
        }
        if (evt.status === "STARTED") {
            return "Group started";
        }
        if (evt.status === "FINISHED") {
            return "Group finished";
        }
        if (evt.status === "APPLICATIONS_CLOSED") {
            return "Applications closed";
        }
        return "";
    }

    const getLink = (evt: any) => {
        if (evt.type === "Group") {
            return <a href={evt.link}>{evt.title}</a>;
        }
        return <Link to={`/event/${evt.friendly_type}/${evt.id}`}>{evt.title}</Link>
    }

    const rows = (visibleEvents.length > 0) && visibleEvents.map((evt: any) => {
        const t = types.filter((t: any) => t.key === evt.event_type)[0];
        const status = getStatusMessage(evt);
        const dt = displayEventDates(evt);
        return <tr>
            <td className='event-type'>{t?.name}</td>
            <td>{dt + (evt.recurrence_text ? (' - ' + evt.recurrence_text.split(", until")[0]): "")}</td>
            <td className='event-title'>{getLink(evt)}</td>
            <td>{status}</td>
        </tr>;
    });

    return <Table>
        <thead>
            <tr>
                <th>Type</th>
                <th>Date/Time</th>
                <th>Event Title</th>
                <th>Status</th>
            </tr>
        </thead>
        <ReactPlaceholder type='text' rows={10} ready={!pageLoading} style={{ display: "table-caption" }}>
            <tbody>
                {defaultRows}
                {rows}
            </tbody>
        </ReactPlaceholder>
    </Table>;
}

export default EventsTable;