import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api-actions";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {
      id: 0,
      memberships: [],
      pen_name: "",
      avatar: "",
      unread_messages: 0,
    },
    isAuthenticated: false,
    loading: false,
  },
  reducers: {
    userRequested: (state, action) => {
      state.loading = true;
    },

    userReceived: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.isAuthenticated = true;
    },

    userRequestFailed: (state, action) => {
      state.loading = false;
    },

    // Nuevo reducer para actualizar el avatar
    updateUserAvatar: (state, action) => {
      state.data.avatar = action.payload;
    },
  },
});

// No olvides exportar las acciones generadas
export const { updateUserAvatar } = userSlice.actions;

export const userReducer = userSlice.reducer;

export const loaduser = (token) => (dispatch) => {
  const { userRequested, userReceived, userRequestFailed } = userSlice.actions;
  const url = "forum/users/current/";
  return dispatch(
    apiCallBegan({
      url,
      token,
      onStart: userRequested.type,
      onSuccess: userReceived.type,
      onError: userRequestFailed.type,
    })
  );
};
