import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

interface CharCountProps {
  maxLength: number;
  inputName: string;
  register: any;
  errors: any;
  label: string;
  fieldDescription?: string;
  defaultValue?: string;
}

const CharCountInput: React.FC<CharCountProps> = ({ maxLength, inputName, register, errors, label, fieldDescription = '', defaultValue = '' }) => {
  const [charCount, setCharCount] = useState(maxLength - (defaultValue || '').length);

  useEffect(() => {
    setCharCount(maxLength - (defaultValue || '').length);
  }, [maxLength, defaultValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputLength = event.target.value.length;
    if (inputLength <= maxLength) {
      setCharCount(maxLength - inputLength);
	  }
  };

  return (
    <Row>
      <Col md={{ span: 3, offset: 0 }}>
      <label>{label}</label>
      {fieldDescription && <p className="field-description">{fieldDescription}</p>}
      </Col>
      <Col md={{ span: 9, offset: 0 }}>
        {errors[inputName] && <span>This field is required</span>}
        <textarea
          {...register(inputName)}
          className="col-md-9"
          placeholder="This is a short description of yourself as a writer. Include what you write, your goals or all of the above."
          maxLength={maxLength}
          onChange={handleInputChange}
          defaultValue={defaultValue}
        />
        <i>{charCount} Characters Remaining</i>
        <div className="char-count bio-chars-left"></div>
      </Col>
    </Row>
  );
};

export default CharCountInput;