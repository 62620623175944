import { createGlobalState } from 'react-global-state-hooks';
import { BaseProject } from 'types';

type ProjectListResultsType = {
    started: boolean,
    completed: boolean,
}

export interface CurrentProjectsType extends ProjectListResultsType {
    projects: any[]
}

export const useCurrentProjects = createGlobalState<CurrentProjectsType>({
    started: false,
    completed: false,
    projects: []
});

export interface QuickSubmissionsType extends ProjectListResultsType {
    project: BaseProject | null
}

export const useQuickSubmissions = createGlobalState<QuickSubmissionsType>({
    started: false,
    completed: false,
    project: null
});