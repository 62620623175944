import { createSlice } from "@reduxjs/toolkit";

export const eventsFiltersSlice = createSlice({
    name: "eventsFilters",
    initialState: {
        selectedFilters: {
            type: {},
            category: {},
            free: false,
            available: false
        },
    },
    reducers: {
        setFilters: (state, action) => {
            state.type = action.payload.type;
            state.category = action.payload.category;
            state.free = action.payload.free;
            state.available = action.payload.available;
        },
    },
});

// Action creators are generated for each case reducer function
export const eventsFiltersReducer = eventsFiltersSlice.reducer;

export const setEventsFilters = (filters) => (dispatch) => {
    const { setFilters } = eventsFiltersSlice.actions;
    return dispatch({ type: setFilters.type, payload: filters });
};
