import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./styles/variables.scss";
import App from "./app/App";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import store from "./redux/store";
import englishTranslations from "./translations/en.json";

import { PostHogProvider } from "posthog-js/react";
import config from "config";

const options = {
  api_host: config.PUBLIC_POSTHOG_HOST,
};

const translations = {
  en: englishTranslations,
};

ReactDOM.render(
  <PostHogProvider apiKey={config.PUBLIC_POSTHOG_KEY} options={options}>
    <IntlProvider locale="en" messages={translations["en"]}>
      <Provider store={store}>
        <App />
      </Provider>
    </IntlProvider>
  </PostHogProvider>,
  document.getElementById("root")
);
