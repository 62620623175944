import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { BaseDocument } from "types";

type DocumentDeleteModalProps = {
  show: Boolean
  document: BaseDocument;
  onHide: () => void;
  onChange: () => void;
}


const DocumentDeleteModal = (props: DocumentDeleteModalProps) => {
  const {document, show, onHide, onChange} = props;
    const authenticationContext = useContext(AuthenticationContext);
  
    const onSubmit = async (e: any) => {
      e.preventDefault();
      try {
        await sendRequest({
          url: `api/v1.0/document/${document.id}/`,
          method: "DELETE",
          token: authenticationContext.token || "",
          body: null,
        });
        onHide();
        onChange();
        toast.success("Document deleted successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } catch (error: any) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    };
  
    return (
      <Modal show={show} centered >
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete <b>{props.document.title}</b>?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="primary purple"  onClick={onSubmit}>Delete</Button>
          <Button variant="secondary"  className="secondary grey" onClick={props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
}

export default DocumentDeleteModal;