import { createSlice } from "@reduxjs/toolkit";

export const filterValuesSlice = createSlice({
    name: "filterValues",
    initialState: {
    },
    reducers: {
        setFilters: (state, action) => {
            state[action.payload.key] = action.payload.value;
        },
    },
});

// Action creators are generated for each case reducer function
export const filterValuesReducer = filterValuesSlice.reducer;

export const setFilterValues = (filters) => (dispatch) => {
    const { setFilters } = filterValuesSlice.actions;
    return dispatch({ type: setFilters.type, payload: filters });
};
