import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";


const DocumentInviteModal = (props: any) => {
    const ref = useRef<null | HTMLFormElement>(null);
    const {show, onHide, document} = props;
    const authenticationContext = useContext(AuthenticationContext);
    const [errors, setErrors] = useState({group_name: false, emails: false, body: false, submission_notes: false});
    const [values, setValues] = useState({group_name: "", emails: [""], body: "", submission_notes: ""});
    const [addSubmissionNotes, setAddSubmissionNotes] = useState(false);

    const handleConfirm = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        try {
          const url = `api/v1.0/document/${document?.id}/invite/`;
          const method = "POST";
          await sendRequest({
            url,
            method,
            token: authenticationContext.token || "",
            body: JSON.stringify(values),
          });
          props.onHide();
          toast.success("Invitations sent succesfully!", {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        } catch (error: any) {
          toast.error("Something failed submitting your document. Check the fields for errors.", {
            position: toast.POSITION.BOTTOM_RIGHT
          });
          const errs = JSON.parse(error.message);
          setErrors(errs);
        }
    }


    return <Modal show={show} onHide={onHide}>
        <Form ref={ref}>
            <Modal.Header>
                <Modal.Title>Invite critiquers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Invite people to an ad hoc critique group to review <b>{document.title}</b>.</p>
                <InputGroup hasValidation className="mb-3">
                    <Form.Control 
                        placeholder="Group Name"
                        type="text"
                        required
                        isInvalid={errors.group_name}
                        autoFocus
                        value={values.group_name}
                        onChange={(e) => setValues({...values, group_name: e.target.value})}
                    />
                    <Form.Control.Feedback type={errors.group_name ? "invalid" : "valid"}>
                        Please enter a group name.
                    </Form.Control.Feedback>
                </InputGroup>
                <InputGroup hasValidation className="mb-3">
                    <Form.Control 
                        placeholder="Add Email Addresses"
                        type="text"
                        required
                        isInvalid={errors.emails}
                        autoFocus
                        value={values.emails.join()}
                        onChange={(e) => setValues({...values, emails: e.target.value.split(",")})}
                    />
                    <Form.Control.Feedback type={errors.emails ? "invalid" : "valid"}>
                        Please enter email addresses separated by comma.
                    </Form.Control.Feedback>
                </InputGroup>
                <InputGroup hasValidation className="mb-3">
                    <Form.Control 
                        placeholder="[Inser message here]"
                        as="textarea"
                        rows={6}
                        type="text"
                        required
                        isInvalid={errors.body}
                        autoFocus
                        value={values.body}
                        onChange={(e) => setValues({...values, body: e.target.value})}
                    />
                    <Form.Control.Feedback type={errors.body ? "invalid" : "valid"}>
                        Please enter a message.
                    </Form.Control.Feedback>
                </InputGroup>
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label={<b>Add Submission Notes</b>}
                            id="add_submission_notes"
                            checked={addSubmissionNotes}
                            onClick={() => setAddSubmissionNotes(!addSubmissionNotes)
                            }
                        />
                    </Col>
                </Row>
                {
                    addSubmissionNotes && (<InputGroup style={{marginTop: 10}}>
                        <Form.Control
                            as="textarea"
                            aria-label="Comment"
                            className="ink-forum-item__commentText"
                            value={values.submission_notes}
                            onChange={(e) => setValues({...values, submission_notes: e.target.value})}
                            placeholder="Notes"
                            rows={10}
                        />
                    </InputGroup>)
                }
            </Modal.Body>
            <Modal.Footer style={{justifyContent: "flex-start"}}>
                <Button className="primary purple" onClick={(e) => {handleConfirm(e)}}>
                    Confirm Selection 
                </Button>
                <Button variant="secondary"  className="secondary grey" onClick={onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>;
}

export default DocumentInviteModal;