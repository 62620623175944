import React from 'react';

interface IAuthenticationData {
  token: string | null,
  path: string
}

const authData: IAuthenticationData = {
  token: '',
  path: '/'
}

const AuthenticationContext = React.createContext(authData);

export default AuthenticationContext;
