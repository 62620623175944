import React, { useContext, useState, useEffect } from "react";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { toast } from 'react-toastify';
import AuthenticationContext from "contexts/AuthenticationContext";

import sendRequest from "services/dataService";
import { BaseProject } from "types";

type ProjectModalProps = {
  project?: BaseProject;
  show: boolean;
  onHide: () => void;
  onChange: () => void;
}


const ProjectModal = (props: ProjectModalProps) => {
  const {project, show, onHide, onChange} = props;
  const authenticationContext = useContext(AuthenticationContext);
  const [values, setValues] = useState<any>(project ? {...project} : {title: ""});
  const [errors, setErrors] = useState<any>({title: null});

  const onSuccess = () => {
    onHide();
    onChange();
    toast.success(`Project ${project ? "updated": "created"} successfully.`, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    setValues({title: ""});
    setErrors({title: null});
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const url = "api/v1.0/project/" +  (project ? `${project.id}/`: "");
    const method = project ? "PUT": "POST";
    try {
      await sendRequest({
        url,
        method,
        token: authenticationContext.token || "",
        body: JSON.stringify({"title": values.title}),
      });
      onSuccess();
    } catch (error: any) {
      const errs = JSON.parse(error.message);
      setErrors(errs);
      toast.error("Something failed submitting your document. Check the fields for errors.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  };

  const onValChange = async (e: any) => {
    var v = { ...values };
    v["title"] =  e.target.value;
    setValues(v);
  }

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
            {project ? "Rename" : "Create"} Project
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Project Title"
                autoFocus
                isInvalid={errors.title}
                value={values.title}
                onChange={onValChange}
              />
              <Form.Control.Feedback type={errors.title ? "invalid" : "valid"}>
                Please enter a title.
              </Form.Control.Feedback>
            </InputGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" className="purple" onClick={onSubmit}>Save</Button>
        <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProjectModal;