import React, { useEffect, useRef, useState } from 'react';
import { buildFilterKey, displayMonth } from "pages/events/utils";
import { Button, Col, Row } from "react-bootstrap";
import ReactPlaceholder from "react-placeholder/lib";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ArchiveItem = (props: any) => {
    const data = props.data;

    const [lineClamp, setLineClamp] = useState(13);
    const descriptionRef = useRef(null);
    const titleRef = useRef(null);

    const countLines = (title: any, description: any) => {

        let lineHeight = 20
        
        const paragraphs = description.innerText?.split('\n') || [];

        const height = title.clientHeight;
        let lines = Math.round((250 - height) / lineHeight);

        for (const paragraph of paragraphs) {
            if (paragraph === '') {
                lines = lines - 1
            }  
        }

        return lines + 1;
    }

    useEffect(() => {
        const description = descriptionRef.current;
        const title = titleRef.current;

        if (description && title) {
            const titleLines = countLines(title, description);
            const totalLines = titleLines
            setLineClamp(totalLines);
        }
    }, []);

    return <Col sm={12} md={6} className="archive-item">
        <Row>
            <Col sm={5} className="left-column">
                <ReactPlaceholder type='rect' className="archive-item-img" ready={data}>
                    <div className='archive-item-img-container'>
                        <img className="archive-item-img" alt="archive-item" src={data?.picture || "https://www.inkedvoices.com/static/img/bg-group-profile-picture.png"} />
                    </div>
                </ReactPlaceholder>
                <ReactPlaceholder type='text' rows={1} ready={data}>
                    <div className="month">{displayMonth(data?.live_at)}</div>
                </ReactPlaceholder>
                { 
                    data?.videos?.length > 0 &&
                    <Link to={`/archived/${data.id}`}>
                        <Button className="purple">Access Replay</Button>
                    </Link>
                }
            </Col>
            <Col sm={7}>
                <ReactPlaceholder type='text' rows={1} ready={data}>
                    <Link to={`/archived/${data.id}`}>
                        <h4 ref={titleRef}>{data?.title}</h4>
                    </Link>
                </ReactPlaceholder>
                <ReactPlaceholder type='text' rows={4} ready={data}>
                    <div ref={descriptionRef} className='description-content' style={{ WebkitLineClamp: lineClamp }} dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                </ReactPlaceholder>
            </Col>
        </Row>
    </Col>;
}

const ArchiveItemsList = (props: any) => {
    const filterValues = useSelector((state: any) => state.filterValues);
    const anyTopic = Object.entries(filterValues).filter((val: any) => val[1] && val[0].startsWith("archive-topic")).length > 0;
    const anyTypeOfWriting = Object.entries(filterValues).filter((val: any) => val[1] && val[0].startsWith("archive-type-of-writing")).length > 0;
    const items = props.items?.filter(
        (itm: any) => (!anyTopic) || itm.topic.filter(((topic: any) => filterValues[buildFilterKey("archive-topic", topic.name)])).length > 0
    ).filter(
        (itm: any) => (!anyTypeOfWriting) || itm.types_of_writing.filter((t: any) => filterValues[buildFilterKey("archive-type-of-writing", t.name)]).length > 0
    );
    return <ReactPlaceholder type='text' rows={15} ready={items}>
        {items?.length ?
            <Row className="archive-items-list">
                {items.map((item: any) => <ArchiveItem data={item} />)}
            </Row> :
            <div className="empty">There's no archived events.</div>}
    </ReactPlaceholder>
}

export default ArchiveItemsList;