import { useEffect, useRef, useState } from "react";
import "./SearchBar.scss";

type SearchBarProps = {
  onSearch: (query: string) => void;
};

export default function SearchBar({ onSearch }: SearchBarProps) {
  const [searchText, setSearchText] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const inputValue = ref.current?.value;
      if (inputValue === searchText) {
        onSearch(searchText);
      }
    }, 300);
  }, [searchText]);

  return (
    <form
      className="flex-1 search-bar"
      data-id="26"
      onSubmit={(e) => {
        e.preventDefault();
        onSearch((e.target as HTMLFormElement).search.value);
      }}
    >
      <div className="relative" data-id="27">
        <svg
          data-id="28"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500 dark:text-gray-400"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <path d="m21 21-4.3-4.3"></path>
        </svg>
        <input
          className="flex h-10 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] bg-white"
          placeholder="Search"
          data-id="29"
          type="search"
          ref={ref}
          onChange={(e) => {
            const val = e.target.value;
            setSearchText(val);
          }}
        />
      </div>
    </form>
  );
}
