import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { BaseMessage } from "types";

type MessageDeleteModalProps = {
  messages: BaseMessage[];
  show: Boolean;
  onHide: (deleted: boolean) => void;
};

const MessageDeleteModal = (props: MessageDeleteModalProps) => {
  const { messages, show, onHide } = props;
  const authenticationContext = useContext(AuthenticationContext);
  const s = messages.length === 1 ? "" : "s";

  async function deleteMessage(message: BaseMessage) {
    try {
      await sendRequest({
        url: `api/v1.0/messages/${message.id}/`,
        method: "DELETE",
        token: authenticationContext.token || "",
        body: null,
      });
      return null;
    } catch (error: any) {
      return error.message;
    }
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    for (const message of messages) {
      const error = await deleteMessage(message);
      if (error) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }
    }
    onHide(true);
    toast.success(`Message${s} deleted successfully.`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete the message{s}?</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="primary purple" onClick={onSubmit}>
          Delete
        </Button>
        <Button
          variant="secondary"
          className="secondary grey"
          onClick={() => onHide(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageDeleteModal;
