import { useCallback, useState } from "react";
import sendRequest from "services/dataService";

interface IState {
  isLoading: boolean;
  isSuccess: boolean;
  isFailed: boolean;
  data: any;
  error: any;
}

const useFetch = () => {
  const state: IState = {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    data: null,
    error: null,
  };

  const [fetchState, setFetchState] = useState(state);

  const fetchData = useCallback(async ({ url, method, body, token }) => {
    try {
      setFetchState({
        ...state,
        isLoading: true,
      });

      const result = await sendRequest({ url, method, body, token });
      setFetchState({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: result,
      });

      return result;
    } catch (error) {
      setFetchState({
        ...state,
        isLoading: false,
        isSuccess: true,
        error,
      });

      throw error;
    }
  }, []);

  return [fetchState, fetchData] as const;
};

export default useFetch;
