import React, { useState } from "react";
import { BaseNote } from "types";
import NoteModal from "../components/modals/NoteCreateEdit";
import NoteDeleteModal from "../components/modals/NoteDelete";
import "./Notes.scss";
import { Button } from "react-bootstrap";

type NotesComponentProps = {
  notes: BaseNote[];
};

const Notes = (props: NotesComponentProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(-1);
  const [openDeleteModal, setOpenDeleteModal] = useState(-1);
  const { notes } = props;

  const openModal = (noteId: number) => {
    setOpenDeleteModal(noteId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setOpenDeleteModal(-1);
    setIsModalOpen(false);
  };

  const openNoteModal = (noteId: number) => {
    setOpenEditModal(noteId);
    setShowEditModal(true);
  };

  const closeNoteModal = () => {
    setOpenEditModal(-1);
    setShowEditModal(false);
  };

  const formatDate = (dateStr: string) => {
    const date_options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    } as const;
    const dt = new Date(dateStr);
    return dt.toLocaleDateString("en-US", date_options);
  };

  return (
    <>
      <h3 id="notes-title">
        Notes{" "}
        <a
          href="#"
          role="button"
          onClick={() => {
            setShowNoteModal(true);
          }}
        >
          <img
            src="https://www.inkedvoices.com/static/img/add-note.png"
            alt="Add Note"
          />
        </a>
      </h3>
      {notes?.length == 0 && <div className="note">You have no notes</div>}

      {notes?.length > 0 &&
        notes.map((note: any) => (
          <div className="note" key={note.id}>
            <a
              href="#"
              className="ico delete"
              onClick={() => openModal(note.id)}
            >
              <img
                src="https://www.inkedvoices.com/static/img/trash.png"
                alt="trash can"
              />
              <NoteDeleteModal
                show={isModalOpen && openDeleteModal === note.id}
                onHide={() => closeModal()}
                note={note}
              />
            </a>
            <a
              href="#"
              className="ico edit"
              onClick={() => openNoteModal(note.id)}
            >
              <img
                src="https://www.inkedvoices.com/static/img/pencil.png"
                alt="settings"
              />
              <NoteModal
                show={showEditModal && openEditModal === note.id}
                onHide={() => closeNoteModal()}
                note={note}
              />
            </a>
            <p className="sub-title p-small">
              <b>{note.title}</b>
            </p>
            <p className="sm-text note-date">{formatDate(note.created_at)}</p>
            <div
              className="sm-text ellipsis-text"
              style={{
                maxHeight: "50px",
                overflowWrap: "break-word",
                overflowY: "hidden",
              }}
            >
              {note.contents}
            </div>
          </div>
        ))}
      <NoteModal show={showNoteModal} onHide={() => setShowNoteModal(false)} />
    </>
  );
};

export default Notes;
