import RoundedGrayBox from "components/roundedGrayBox/RoundedGrayBox";
import { displayDateTime, isEventWithApplications, isResourceEvent } from "pages/events/utils";

const EventDetailHowToRegisterBox = (props: any) => {
    const event = props.event;

    const getApplyByDateMessage = (evt: any) => {
        const msg = <p>Application window: {displayDateTime(event?.open_period_start_datetime)} - {displayDateTime(event?.open_period_end_datetime)}</p>
        return msg;
    }

    const title = isResourceEvent(event) ? "How you’ll join" : (
        isEventWithApplications(event) ? "How to Apply" :
            "How to Register"
    );

    return <RoundedGrayBox>
        <h6>{title}</h6>
        {!isResourceEvent(event) &&
            <p>
                {event?.price && <div><b>Members:</b> ${event?.price}</div>}
                {event?.non_members_price && <div><b>Nonmembers:</b> ${event?.non_members_price}</div>}
            </p>
        }
        {
            isEventWithApplications(event) && <>
                <b>
                    {getApplyByDateMessage(event)}
                </b>
                {
                    event?.application_text && (
                        <p dangerouslySetInnerHTML={{ __html: event?.application_text }} />
                    )
                }
            </>
        }
        {
            event?.how_to_join && (
                <p dangerouslySetInnerHTML={{ __html: event?.how_to_join }} />
            )
        }
        {
            event?.diverse_writer_custom_text && <>
                <h6>Scholarship</h6>
                <p>{event?.diverse_writer_custom_text}
                    {event && (!event.diverse_writer_application_is_open) && <i>
                        {" Applications for this scholarship have closed."}
                    </i>}
                    {event?.diverse_writer_application_is_open && <i>
                        <a href={event?.diverse_writer_application_link}>{" Apply before " + displayDateTime(event?.diverse_writer_application_end_datetime)}.</a>
                    </i>}
                </p>
            </>
        }
    </RoundedGrayBox>;
}

export default EventDetailHowToRegisterBox;