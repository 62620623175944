import Config from "config";

const baseHeaders = {
  "Content-type": "application/json",
};

interface SendRequestProps {
  // tslint:disable-next-line:no-any
  url: string;
  method: string;
  body: any;
  token: string;
}

const sendRequest = async (data: SendRequestProps) => {
  const headers = data.token
    ? { ...baseHeaders, Authorization: "Token " + data.token }
    : { ...baseHeaders };
  const url = data.url.startsWith("http")
    ? data.url
    : Config.BASE_API_URL + data.url;

  const response = await fetch(url, {
    method: data.method,
    headers,
    body: data.body,
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }
  try {
    const json = await response.json();
    return json;
  } catch (error: any) {
    return {};
  }
};

export default sendRequest;
