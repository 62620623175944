import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { BaseDocument, DocumentGroup } from "types";

type DocumentSubmitModalProps = {
  show: Boolean;
  document: BaseDocument;
  onHide: () => void;
  onChange: () => void;
};

const DocumentSubmitModal = (props: DocumentSubmitModalProps) => {
  const ref = useRef<null | HTMLFormElement>(null);
  const { show, onHide, document, onChange } = props;
  const authenticationContext = useContext(AuthenticationContext);

  const [selected, setSelected] = useState<number | null>(null);
  const [addSubmissionNotes, setAddSubmissionNotes] = useState(false);
  const [submissionNotes, setSubmissionNotes] = useState("");

  const handleConfirm = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!selected) {
      toast.error("Please select a group.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    try {
      const url = "api/v1.0/submission/";
      const method = "POST";
      await sendRequest({
        url,
        method,
        token: authenticationContext.token || "",
        body: JSON.stringify({
          document: document.id,
          membership: selected,
          notes: submissionNotes,
        }),
      });
      onHide();
      onChange();
      toast.success("Document submitted successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      toast.error("Something failed submitting your document.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleChangeSubmissionNotes = (event: any) => {
    const target = event.target as HTMLInputElement;
    setSubmissionNotes(target.value);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form ref={ref}>
        <Modal.Header>
          <Modal.Title>Submit To Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Choose which group you'd like to review &nbsp;
            <b>{document?.title}</b>. &nbsp;
            <em className="sm-text">({document?.word_count} words)</em>
          </p>
          <table className="no-editors">
            {document?.groups_available?.length === 0 && (
              <tr>
                <td>
                  "No groups are available for submitting this document. A
                  document may be submitted one time to each of your groups, and
                  you must have enough credits to submit (for groups requiring
                  credits)."
                </td>
              </tr>
            )}
            {document?.groups_available?.map((group: DocumentGroup) => (
              <Row>
                <Col sm={7}>
                  <Form.Check
                    type="radio"
                    label={group.name}
                    id={group.membership_id.toString()}
                    onClick={() => setSelected(group.membership_id)}
                    checked={selected === group.membership_id}
                    disabled={
                      group.credits_available < group.credits_required ||
                      (group.max_words > 0 &&
                        document?.word_count > group.max_words)
                    }
                  />
                </Col>
                <Col
                  className={`sm-text membership-balance ${
                    group.credits_available < group.credits_required && "error"
                  }`}
                  sm={3}
                >
                  {`${group.credits_available} credits available`}
                  <br />
                  {`( ${group.credits_required} required ).`}
                </Col>
                <Col
                  className={`sm-text membership-balance ${
                    group.max_words > 0 &&
                    document?.word_count > group.max_words &&
                    "error"
                  }`}
                  sm={2}
                >
                  <i>
                    {group.max_words > 0 && `Limit ${group.max_words} words.`}
                  </i>
                </Col>
              </Row>
            ))}
          </table>
          <Row>
            <Col>
              <Form.Check
                type="checkbox"
                label={<b>Add Submission Notes</b>}
                id="add_submission_notes"
                checked={addSubmissionNotes}
                onClick={() => setAddSubmissionNotes(!addSubmissionNotes)}
              />
            </Col>
          </Row>
          {addSubmissionNotes && (
            <FormGroup style={{ marginTop: 10 }}>
              <FormControl
                as="textarea"
                aria-label="Comment"
                className="ink-forum-item__commentText"
                value={submissionNotes}
                onChange={handleChangeSubmissionNotes}
                placeholder="Notes"
              />
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-start" }}>
          <Button
            className="primary purple"
            onClick={(e) => {
              handleConfirm(e);
            }}
          >
            Confirm Selection
          </Button>
          <Button
            variant="secondary"
            className="secondary grey"
            onClick={onHide}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DocumentSubmitModal;
