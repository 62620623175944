import { createSlice } from "@reduxjs/toolkit";

export const openRepliesSlice = createSlice({
  name: "openReplies",
  initialState: {
    open: {},
  },
  reducers: {
    setReplies: (state, action) => {
      state.open[action.payload.id] = action.payload.value;
    },
  },
});

// Action creators are generated for each case reducer function
export const openRepliesReducer = openRepliesSlice.reducer;

export const setOpenReplies = (id, value) => (dispatch) => {
  const { setReplies } = openRepliesSlice.actions;
  return dispatch({ type: setReplies.type, payload: { id, value } });
};
