import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import "./DropdownMultiselect.scss";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";

type Option = {
  label: string;
  key: number | string;
};

type DropdownMultiselectProps = {
  options: Option[] | undefined;
  selected: (number | string)[];
  onChange: (selected: (number | string)[]) => void;
};

export default function DropdownMultiselect({
  options,
  selected,
  onChange,
}: DropdownMultiselectProps) {
  const [open, setOpen] = useState(false);
  const selectedLabels =
    options?.filter((o) => selected.includes(o.key)).map((o) => o.label) || [];
  const title =
    selected.length == options?.length
      ? "All"
      : selectedLabels.length > 0
      ? selectedLabels.join(", ")
      : "None";

  return (
    <div className="dropdown-multiselect-wrapper">
      <Button
        className="dropdown-multiselect-button"
        onClick={() => setOpen(!open)}
      >
        {title}{" "}
        {open ? <CaretUpFill width={10} /> : <CaretDownFill width={10} />}
      </Button>
      {open && (
        <div className="dropdown-multiselect-options">
          <ul>
            {options?.map((o) => {
              const isSelected = selected.includes(o.key);
              const onClick = () => {
                const newSelected = isSelected
                  ? selected.filter((s) => s !== o.key)
                  : [...selected, o.key];
                onChange(newSelected);
              };
              return (
                <li key={o.key} onClick={onClick}>
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => {}}
                  />
                  <span>{o.label}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
