import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import './DetailPage.scss';

const DetailPage = (props: any) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return <Container className={"detail-page " + props.className}>
        {props.children}
    </Container>
}

export default DetailPage;