import React, { useContext, useState, useEffect } from "react";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { toast } from 'react-toastify';
import AuthenticationContext from "contexts/AuthenticationContext";

import sendRequest from "services/dataService";
import { BaseNote } from "types";
import { loadDashboard } from "redux/dashboardReducer";
import { useDispatch } from "react-redux";

type NoteModalProps = {
  note?: BaseNote;
  show: Boolean;
  onHide: () => void;
}


const NoteModal = (props: NoteModalProps) => {
  const authenticationContext = useContext(AuthenticationContext);
  const {note, show, onHide} = props;
  const [values, setValues] = useState<any>(note ? {...note} : {title: "", contents: ""});
  const [errors, setErrors] = useState<any>({title: null, contents: null});
  const dispatch = useDispatch();

  const onSuccess = () => {
    onHide();
    dispatch(loadDashboard(authenticationContext.token || ""));
    toast.success(`Note ${note ? "updated": "created"} successfully.`, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    setErrors({title: null, contents: null});
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const url = "api/v1.0/note/" +  (note ? `${note.id}/`: "");
    const method = note ? "PUT": "POST";
    try {
      await sendRequest({
        url,
        method,
        token: authenticationContext.token || "",
        body: JSON.stringify({"title": values.title, "contents": values.contents}),
      });
      onHide();
      onSuccess();
    } catch (error: any) {
      const errs = JSON.parse(error.message);
      setErrors(errs);
      toast.error("Something failed submitting your document. Check the fields for errors.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  };

  const onValChange = async (e: any, field: string) => {
    var v = { ...values };
    v[field] =  e.target.value;
    setValues(v);
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
            {note ? "Edit" : "Create New"} Note
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Title"
                autoFocus
                isInvalid={errors.title}
                value={values.title}
                onChange={(e: any) => onValChange(e, "title")}
              />
              <Form.Control.Feedback type={errors.title ? "invalid" : "valid"}>
                Please enter a title.
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Contents"
                    autoFocus
                    isInvalid={errors.contents}
                    value={values.contents}
                    onChange={(e: any) => onValChange(e, "contents")}
                    as="textarea"
                    rows={5} 
                    maxLength={800}
                    style={{width: "100%"}}
                />
                <Form.Control.Feedback type={errors.contents ? "invalid" : "valid"} style={{width: "100%"}}>
                    Please enter content for the note.
                </Form.Control.Feedback>
              <div>
                {values.contents.length}/800
              </div>
            </InputGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" className="purple" onClick={onSubmit}>Save</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NoteModal;