import "./GenreSelector.scss";
import Form from 'react-bootstrap/Form';

const GenreSelector = (props: any) => {
    const { genres, level, onChange } = props;
    const actualLevel = level || 0;
    const toggleCheckedIndex = (index: number) => {
        const cloneAllSelected = (genre: any) => {
            return { ...genre, selected: true, children: (genre.children || []).map((child: any) => cloneAllSelected(child)) }
        }
        const cloneAllUnselected = (genre: any) => {
            return { ...genre, selected: false, children: (genre.children || []).map((child: any) => cloneAllUnselected(child)) }
        }
        const newVal = !(genres[index].selected || false);
        const changedGenre = newVal ? cloneAllSelected(genres[index]) : cloneAllUnselected(genres[index])
        onChange(
            genres.map(
                (v: any, idx: number) => (
                    idx == index ?
                        changedGenre :
                        genres[idx]
                )
            )
        )
    }
    const childChanged = (index: number, newValues: any) => {
        const allSelected = newValues.every((g: any) => g.selected);
        onChange(genres.map((v: any, idx: number) => (
            idx == index ?
                { ...genres[idx], children: newValues, selected: allSelected } :
                genres[idx])
        ));
    }
    return <>
        {genres && <div>
            {genres?.map((genre: any, index: number) => <>
                <div className={"genre level-" + actualLevel}>
                    <Form.Check
                        label={genre.name}
                        name={genre.name}
                        type="checkbox"
                        checked={genre.selected || false}
                        onChange={() => toggleCheckedIndex(index)}
                    />
                </div>
                <GenreSelector
                    genres={genre.children || []}
                    level={actualLevel + 1}
                    onChange={(newValues: any) => childChanged(index, newValues)}
                />
            </>)}
        </div>}
    </>;
}

export default GenreSelector;